import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

function ProjectDropdown({ project, setProject }) {
  const [
    activeProject,
    setActiveProject,
    allProjects,
    setAllProjects,
    weakUsername,
    setWeakUsername,
  ] = useOutletContext();
  // TODO: The empty dropdown should redirect to "create a project"

  let navigate = useNavigate();

  const changeActive = (event) => {
    event.preventDefault();

    // get the value and set the project
    var newValue = event.target.value;

    if (newValue !== "createnew") {
      // if it's an existing project, set the project state
      var newActive = { project: newValue };
      setProject(newActive);
    } else {
      // Redirect user to create a new project

      setTimeout(function () {
        navigate("/projects"); // user has been set, now bring them back to the homepage
      }, 200);
    }
  };

  var projectsArray;

  try {
    // This might not exist, so it needs to be checked carefully
    projectsArray = allProjects.projects;
    // REFA: this should now be an array of objects

    // This array needs to lose the "default" project, otherwise it gets repeated
    projectsArray = projectsArray.filter(
      (eachProject) => !eachProject.projectid.toString().startsWith("default")
    );
  } catch {
    projectsArray = [];
  }
  return projectsArray.length > 0 ? (
    <select
      name="select-project"
      id="select-project"
      onChange={changeActive}
      value={project.project}
    >
      <option value={"default-"+weakUsername}>Select project...</option>
      {projectsArray.map(function (object, i) {
        return (
          <option value={object.projectid} key={i}>
            {object.projectname}
          </option>
        );
      })}
      <option value="createnew">Create new project</option>
    </select>
  ) : (
    <select name="select-project" id="select-project">
      <option value={"default-"+weakUsername}>No saved projects...</option>
      <option value="createnew">Create new project</option>
    </select>
  );
}

export default ProjectDropdown;
