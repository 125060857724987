import React from "react";
import { Link } from "react-router-dom";

import ProjectDropdown from "./project-dropdown";
import { loadInputs } from "./load-inputs";

export function LoadSavedGear({
  reqRecordType,
  project,
  setProject,
  notProjectSpecific,
  loadCallback,
  buttonText,
  userLevel,
}) {
  return (
    <div className="loadBox">
      {reqRecordType === "single" && (
        <div className="card-heading" style={{ marginBottom: ".5em" }}>
          Load Saved Design
        </div>
      )}
      <div className="flex">
        {userLevel === 0 ? (
          <div style={{ fontSize: ".9em", color: "#555" }}>
            <b>Customers on a paid plan can load and save gear designs</b>
            <Link to="/change-plan" style={{ marginLeft: "20px" }}>
              Upgrade Plan
            </Link>
          </div>
        ) : (
          <>
            <ProjectDropdown project={project} setProject={setProject} />

            <GearDropdown
              reqRecordType={reqRecordType}
              project={project}
              notProjectSpecific={notProjectSpecific}
              loadCallback={loadCallback}
              buttonText={buttonText}
            />
          </>
        )}
      </div>
    </div>
  );
}

export function GearDropdown({
  reqRecordType,
  project,
  notProjectSpecific,
  loadCallback,
  buttonText,
}) {
  // get the list of all of the user's gear for this project, for this calculator
  // if notProjectSpecific is true, return all records of the required type (not just those associated with this project)
  // TODO: make notProjectSpecific an easily toggleable selection
  // saved is a Trigger the calculator, as part of the state to decide whether the load list needs to be updated

  const [loadMessage, setLoadMessage] = React.useState();

  const allLocalStorage = { ...localStorage }; // get all local storage
  var userData;
  try {
    userData = allLocalStorage.userdata; // get userdata from localStorage [should be an array of objects]
    userData = JSON.parse(userData); // Parse here (so the userdata becomes an array of objects, not a string)
  } catch {
    localStorage.setItem("userdata", JSON.stringify([]));
    userData = [];
  }

  var allSavedGears = []; // resultant gears for this calculator

  userData.forEach((element, index) => {
    try {
      if (
        element["recordtype"] === reqRecordType &&
        (element["project"] === project.project || notProjectSpecific)
      ) {
        // gear is acceptable for this calculator
        var gearToAdd = [
          element["gearid"],
          element["gear"],
          element["inputstring"],
        ]; // record ID, gear name, input value(for later?)
        allSavedGears.push(gearToAdd);
      }
    } catch {
      // couldn't parse
      console.log("Failed to parse saved gear:", element);
    }
  });

  allSavedGears.sort();

  // Need different names for input boxes depending on record type (so they don't collide when multiple are on the page)
  const inputDropdownName =
    reqRecordType === "hob" ? "input-loadhobname" : "input-loadgearname";

  function loadInputsHandler(event) {
    // Handle loading to either calculate or inform user (within the Component)
    // Get the gear record from the dropdown selection
    var gearRecordID = document.getElementById(inputDropdownName).value;

    var didLoadInput = loadInputs(event, gearRecordID);
    if (didLoadInput) {
      loadCallback(event);
      setLoadMessage(null);
    } else {
      setLoadMessage("No record found");
    }
  }

  return (
    <>
      <div className="load-input">
        {allSavedGears.length > 0 ? (
          <select name={inputDropdownName} id={inputDropdownName}>
            <option value="default">Select...</option>
            {allSavedGears.map(function (object) {
              return (
                <option value={object[0]} key={object[0]}>
                  {object[1]}
                </option>
              );
            })}
          </select>
        ) : (
          <select
            disabled={true}
            name={inputDropdownName}
            id={inputDropdownName}
          >
            <option value="default">No saved units</option>
          </select>
        )}
        <button
          className="saveButton"
          id="button-load"
          onClick={loadInputsHandler}
        >
          {buttonText ? buttonText : "Load Gear"}
        </button>
      </div>
      {loadMessage && <div className="loading-message">{loadMessage}</div>}
    </>
  );
}
