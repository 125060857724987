// Import Bootstrap style elements
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function PublicHeader({ noHeaderImg }) {
  return (
    <Row>
      <Navbar
        expand="lg"
        className="d-flex nav-bg flex-wrap justify-content-between align-items-center"
        style={{ marginBottom: "20px" }}
      >
        <Nav.Item className="me-auto">
          <Nav.Link href="/">
            <Image
              src="/GCS-name2.svg"
              style={{ width: "200px", marginTop: "7px" }}
            />
          </Nav.Link>
        </Nav.Item>
        <Navbar.Toggle aria-controls="outside-navbar-nav" />
        <Navbar.Collapse
          id="outside-navbar-nav"
          className="justify-content-end"
        >
          <Nav.Item>
            <Nav.Link href="/">home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/faq">faq</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/pricing">pricing</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/join">sign up</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/login">login</Nav.Link>
          </Nav.Item>
        </Navbar.Collapse>
      </Navbar>
      {!noHeaderImg && (
        <Card
          className="text-white"
          style={{ minHeight: "250px", marginBottom: "20px" }}
        >
          <div>
            <Card.Img
              src="/main-images/hero-2.jpg"
              alt="Card image"
              className="banner-image"
            />
            <Card.ImgOverlay>
              <Card className="hero-overlay">
                <Card.Title className="hero-heading">gears that work</Card.Title>
                <Card.Text className="hero-text">
                  Access gear technology
                </Card.Text>

                <Card.Text>
                  <a
                    href="/pricing"
                    className="btn btn-primary"
                    role="button"
                    style={{ fontWeight: "600", marginTop: "20px" }}
                  >
                    Sign Up
                  </a>
                </Card.Text>
              </Card>
            </Card.ImgOverlay>
          </div>
        </Card>
      )}
    </Row>
  );
}
