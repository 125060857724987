import React from "react";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorCGDB() {
  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Change Gears for Machinery</div>
        <p>
          This calculator will output all possible change gear combinations, for
          a given tooth count and machine constant, using only the range of
          change gears provided (default: 20-80).
        </p>
        <p>
          The target ratio will be (Machine Constant / # Teeth), and the
          calculator will output exact matches only.
        </p>
        <p>
          Common fractions (1:1, 1:2, 1:3, 2:3) are used to simplify outputs and
          can be satisfied with any gear pair that matches that ratio. For
          instance, 1:2 could 20:40, 21:42, 30:60, etc.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} xl={8}>
            <form className="calculator">
              <iframe
                title="OVC CGDB"
                src="https://oilviscositychart.com/cgears/index-plain-gcs.php"
                width="600px"
                height="1300px"
                style={{ border: "none" }}
              ></iframe>
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <Image src="/calc-images/cgdb-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <h3>How to Find your Machine Constant</h3>
        <p>
          The machine constant for a hobbing machine can be found by installing
          all four change gears with the same number of teeth, and recording how
          many times the hob rotates relative to the gear blank. With the
          machine off, you can usually rotate it by hand to do this for smaller
          machines. For larger machines that must be energized to make this
          happen, use any modern camera and some indicators to mark the rotation
          of the hob and the table in the same frame. Count it by watching your
          video. That is, with A, B, C, and D all the same, as the workpiece
          rotates one time, how many times does the hob rotate? This relative
          rotation count will determine the Machine Constant for your machine.
        </p>
        <h3>Range of Acceptable Gears</h3>
        <p>
          Not all gears will fit inside your machine. Large gears may not
          physically fit in the cabinet, or require too high of a center
          distance. High gear ratios between individuals gears (e.g. 125:21)
          also make a proper mesh more challenging. Our calculator preferences
          common ratios (1:1, 1:2, 2:3) and gears that are close in size to give
          you the most options that will fit your machine. We did not constrain
          any output data, but we prioritize answers with the smallest physical
          gear size.
        </p>
      </Card>
    </>
  );
}
