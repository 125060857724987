import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DecimalPlacesSelect,
  DPModuleInput,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorLewis({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-module").value = "";
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-I").value = "";
    document.getElementById("input-face").value = "";
    document.getElementById("input-Se").value = "";
    document.getElementById("input-Sy").value = "";

    document.getElementById("input-pangdrop").value = "default";
    document.getElementById("input-sacdrop").value = "default";
    document.getElementById("input-materialdrop").value = "default";

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function loadCallback(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    updateSAC();
    updateGeometry();
    calculate(event);
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  ////////
  var allMaterials = [
    ["A538", "A-538-A, BHN (405)", 1517, 1482, 1034],
    ["A538", "A-538-B, BHN (460)", 1862, 1793, 1344],
    ["A538", "A-538-C, BHN (480)", 1999, 1931, 1482],
    ["H11", "H-11, BHN (660)", 2586, 2034, 2344],
    ["RQC100", "RQC-100, BHN (290)", 931, 883, 600],
    ["1005-1009", "1005-1009, BHN (90)", 359, 269, 241],
    ["1005-1009", "1005-1009, BHN (125)", 469, 448, 283],
    ["1005-1009", "1005-1009, BHN (125)", 414, 400, 248],
    ["1005-1009", "1005-1009, BHN (90)", 345, 262, 228],
    ["1015", "1015, BHN (80)", 414, 228, 241],
    ["1020", "1020, BHN (108)", 441, 262, 241],
    ["1040", "1040, BHN (225)", 621, 345, 386],
    ["1045", "1045, BHN (225)", 724, 634, 414],
    ["1045", "1045, BHN (410)", 1448, 1365, 827],
    ["1045", "1045, BHN (390)", 14344, 1276, 758],
    ["1045", "1045, BHN (450)", 1586, 1517, 965],
    ["1045", "1045, BHN (500)", 1827, 1689, 1276],
    ["1045", "1045, BHN (595)", 2241, 1862, 1724],
    ["1080", "1080, BHN (326)", 1117, 634, null],
    ["1080", "1080, BHN (375)", 1303, 1145, null],
    ["1080", "1080, BHN (415)", 1420, 1241, null],
    ["1080", "1080, BHN (505)", 1827, 1620, null],
    ["1080", "1080, BHN (555)", 2130, 1882, null],
    ["1144", "1144, BHN (265)", 931, 717, 552],
    ["1144", "1144, BHN (305)", 1034, 1020, 565],
    ["1541", "1541F, BHN (290)", 951, 889, 655],
    ["1541", "1541F, BHN (260)", 889, 786, 586],
    ["30304", "30304, BHN (160)", 745, 255, 717],
    ["30304", "30304, BHN (327)", 951, 745, 876],
    ["30310", "30310, BHN (145)", 641, 221, 345],
    ["4130", "4130, BHN (258)", 896, 779, 565],
    ["4130", "4130, BHN (365)", 1427, 1358, 827],
    ["4140", "4140, BHN (310)", 1076, 965, 621],
    ["4142", "4142, BHN (310)", 1062, 1048, 745],
    ["4142", "4142, BHN (335)", 1248, 1234, 1248],
    ["4142", "4142, BHN (380)", 1413, 1379, 827],
    ["4142", "4142, BHN (400)", 1551, 1448, 896],
    ["4142", "4142, BHN (450)", 1758, 1586, 1069],
    ["4142", "4142, BHN (475)", 2034, 1896, 1103],
    ["4142", "4142, BHN (450)", 1931, 1862, 1069],
    ["4142", "4142, BHN (475)", 1931, 1724, 1344],
    ["4142", "4142, BHN (670)", 2448, 1620, 2206],
    ["4142", "4142, BHN (560)", 2241, 1689, 1724],
    ["4340", "4340, BHN (243)", 827, 634, 455],
    ["4340", "4340, BHN (409)", 1469, 1372, 827],
    ["4340", "4340, BHN (350)", 1241, 1172, 758],
    ["5160", "5160, BHN (430)", 1669, 1531, 1000],
    ["52100", "52100, BHN (518)", 2013, 1924, 1324],
    ["9262", "9262, BHN (260)", 924, 455, 524],
    ["9262", "9262, BHN (280)", 1000, 786, 648],
    ["9262", "9262, BHN (410)", 1565, 1379, 1048],
    ["950", "950C, BHN (159)", 565, 317, 345],
    ["950", "950C, BHN (150)", 565, 324, 310],
    ["950", "950X, BHN (150)", 441, 345, 338],
    ["950", "950X, BHN (156)", 531, 331, 386],
    ["980", "980X, BHN (225)", 696, 565, 558],

    ["Aluminum", "1100 Al, BHN (26)", 110, 97, 62],
    ["Aluminum", "2014-T6, BHN (155)", 510, 462, 414],
    ["Aluminum", "2024-T351", 469, 379, 427],
    ["Aluminum", "2023-T4", 476, 303, 441],
    ["Aluminum", "5456-H311, BHN (95)", 400, 234, 359],
    ["Aluminum", "7075-T6", 579, 469, 524],
  ];
  var modWord = "Module";

  function updateGeometry() {
    var numTeethPinion = parseFloat(
      document.getElementById("input-numteeth").value
    );
    var pangle = parseFloat(document.getElementById("input-pangdrop").value);

    const lewis145 = [
      [-99, 0], // placeholder
      [10, 0.176],
      [11, 0.192],
      [12, 0.21],
      [13, 0.223],
      [14, 0.236],
      [15, 0.245],
      [16, 0.255],
      [17, 0.264],
      [18, 0.27],
      [19, 0.277],
      [20, 0.283],
      [22, 0.292],
      [24, 0.302],
      [26, 0.308],
      [28, 0.314],
      [30, 0.318],
      [32, 0.322],
      [34, 0.325],
      [36, 0.329],
      [38, 0.332],
      [40, 0.336],
      [45, 0.34],
      [50, 0.346],
      [55, 0.352],
      [60, 0.355],
      [65, 0.358],
      [70, 0.36],
      [75, 0.361],
      [80, 0.363],
      [90, 0.366],
      [100, 0.368],
      [150, 0.375],
      [200, 0.378],
      [300, 0.382],
    ];

    const lewis200 = [
      [-99, 0], // placeholder
      [10, 0.201],
      [11, 0.226],
      [12, 0.245],
      [13, 0.264],
      [14, 0.276],
      [15, 0.289],
      [16, 0.295],
      [17, 0.302],
      [18, 0.308],
      [19, 0.314],
      [20, 0.32],
      [22, 0.33],
      [24, 0.337],
      [26, 0.344],
      [28, 0.352],
      [30, 0.358],
      [32, 0.364],
      [34, 0.37],
      [36, 0.377],
      [38, 0.383],
      [40, 0.389],
      [45, 0.399],
      [50, 0.408],
      [55, 0.415],
      [60, 0.421],
      [65, 0.425],
      [70, 0.429],
      [75, 0.433],
      [80, 0.436],
      [90, 0.442],
      [100, 0.446],
      [150, 0.458],
      [200, 0.463],
      [300, 0.471],
    ];

    if (numTeethPinion && pangle) {
      let lewis = null;
      if (numTeethPinion === 0) {
        if (pangle === 14.5) lewis = 0.39;
        if (pangle === 20) lewis = 0.484;
        document.getElementById("input-I").value = lewis;
      } else {
        // determine lewis form factor
        if (pangle === 14.5) {
          let closest = 999;
          let result = [0, 0]; // Placeholder until value can be calculated
          for (let i = 0; i < lewis145.length; i++) {
            if (
              numTeethPinion >= lewis145[i][0] &&
              numTeethPinion - lewis145[i][0] < closest
            ) {
              closest = numTeethPinion - lewis145[i][0];
              result = lewis145[i];
            }
          }
          document.getElementById("input-I").value = result[1];
        }
        if (pangle === 20) {
          let closest = 999;
          let result;
          for (let i = 0; i < lewis200.length; i++) {
            if (
              numTeethPinion >= lewis200[i][0] &&
              numTeethPinion - lewis200[i][0] < closest
            ) {
              closest = numTeethPinion - lewis200[i][0];
              result = lewis200[i];
            }
          }
          document.getElementById("input-I").value = result[1];
        }
      }
    } else {
      console.log("Do not calculate geometry yet");
    }
  }

  function removeOptions(selectElement) {
    var i,
      L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  function updateSAC() {
    // We've just selected the Material Class, now we need to populate the optios for the actual material
    var getSac = document.getElementById("input-sacdrop").value;
    var materialDrop, newMaterials;
    if (!(getSac === "default")) {
      // Clear all previous materials
      materialDrop = document.getElementById("input-materialdrop");
      removeOptions(materialDrop);
      // and populate new material list
      newMaterials = allMaterials.filter((element) => {
        if (element[0] === getSac) {
          return true;
        } else {
          return false;
        }
      });
      let defaultOption = document.createElement("option");
      defaultOption.text = "Select material...";
      defaultOption.value = "default";
      materialDrop.add(defaultOption);

      for (let i = 0; i < newMaterials.length; i++) {
        let option = document.createElement("option");
        option.text = newMaterials[i][1];
        option.value = newMaterials[i][3] + ":" + newMaterials[i][4];
        materialDrop.add(option);
      }
      //document.getElementById('input-Sac').value = getSac;
    }
  }

  function updateMaterial() {
    var materialValues = document.getElementById("input-materialdrop").value;
    let [yieldStress, enduranceStress] = materialValues.split(":");
    document.getElementById("input-Sy").value = yieldStress;
    document.getElementById("input-Se").value = enduranceStress;
  }

  function calculate(event) {
    event.preventDefault();

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var gearModule = parseFloat(document.getElementById("input-module").value);
    var F = parseFloat(document.getElementById("input-face").value);
    //d = parseFloat(document.getElementById('input-d').value);
    var I = parseFloat(document.getElementById("input-I").value); // Lewis form factor
    var yieldStress = parseFloat(document.getElementById("input-Sy").value);

    var precision = parseFloat(document.getElementById("precision").value);

    logQA({
      page: "GCSlewis",
      numteeth: numTeeth,
      module: gearModule,
      param6: F,
      param7: modWord,
    });

    var errorMessage = "Error:\n";
    var okToCalc = true;

    if (isNaN(numTeeth) || !(numTeeth > 9)) {
      okToCalc = false;
      errorMessage += "Tooth count must be greater than 9\n";
    }

    if (isNaN(gearModule) || !(gearModule > 0)) {
      okToCalc = false;
      errorMessage += "Module/DP must be greater than 0\n";
    }
    if (isNaN(F) || !(F > 0)) {
      okToCalc = false;
      errorMessage += "Face width must be greater than 0\n";
    }
    if (isNaN(yieldStress) || !(yieldStress > 0)) {
      okToCalc = false;
      errorMessage += "Yield stress must be greater than 0\n";
    }

    if (isNaN(I) || !(I > 0)) {
      okToCalc = false;
      errorMessage += "Lewis Form Factor must be greater than 0\n";
    }

    if (isNaN(precision) || !(precision >= 0)) {
      okToCalc = false;
      errorMessage += "Precision must be 0 or greater\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }

    return false;
  }
  function calculateMOW() {
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    // Based on "Gear Handbook" - Dudley, Section 13-1

    // get all units in metric (mm,module)
    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var gearModule = parseFloat(document.getElementById("input-module").value);
    var F = parseFloat(document.getElementById("input-face").value);
    //d = parseFloat(document.getElementById('input-d').value);
    var I = parseFloat(document.getElementById("input-I").value); // Lewis form factor
    var yieldStress = parseFloat(document.getElementById("input-Sy").value);
    var enduranceStress = parseFloat(document.getElementById("input-Se").value);

    var precision = parseFloat(document.getElementById("precision").value);
    let moduleInput = gearModule;
    let faceWidthGearInput = F;

    let unitWord = "mm";
    let moduleWord = "Module";

    if (radioValue === "in") {
      gearModule = 25.4 / gearModule;
      F = F * 25.4;
      unitWord = "in";
      moduleWord = "Diametral Pitch";
    }

    F = F / 1000; // face width in meters

    // Results in Newtons
    // console.log(
    //   "Values F,",
    //   F,
    //   " I: ",
    //   I,
    //   " yieldStress: ",
    //   yieldStress,
    //   " Module: ",
    //   gearModule
    // );
    let moderateUse = (F * I * yieldStress * 1000000) / (1000 / gearModule);
    let enduranceUse =
      (F * I * enduranceStress * 1000000) / (1000 / gearModule);

    // Requested to add the project name and gear name to the output
    // Only project ID is passed via state.

    var displayProjectName = getProjectNameFromID(project.project);
    // if the user is on "Free" tier, the gearname box won't appear and it will crash
    // default to blank if the input is not shown
    var displayGearName;
    if(document.getElementById("input-gearname")){
      displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";
    }
    else {
      displayGearName = ""; 
    }

    var textToWrite = "Inputs";
    textToWrite += "\nNumber of Teeth:\t" + numTeeth;
    textToWrite += "\n" + moduleWord + ":\t" + moduleInput;
    textToWrite += "\nFace Width:\t" + faceWidthGearInput + "\t" + unitWord;
    textToWrite += "\nYield Stress:\t" + yieldStress + "\tMpa";
    textToWrite += "\nEndurance Stress:\t" + enduranceStress + "\tMPa";

    textToWrite += "\n\nOutputs";
    textToWrite += "\nLewis Form Factor:\t" + I.toFixed(precision);
    textToWrite +=
      "\nModerate Use Limit:\t" +
      (moderateUse / 4.448).toFixed(precision) +
      "\tlb-f\t" +
      moderateUse.toFixed(precision) +
      "\tN";
    textToWrite +=
      "\nEndurance Use Limit:\t" +
      (enduranceUse / 4.448).toFixed(precision) +
      "\tlb-f\t" +
      enduranceUse.toFixed(precision) +
      "\tN";
    textToWrite +=
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date());

    textToWrite += `\nProject:\t${displayProjectName}`;
    textToWrite += `\nGear:\t${displayGearName}\nGCS0\n\n`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);

    return false;
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Gear Strength (Lewis Analysis)</div>
        <p>
          The Lewis Analysis of gear strength is one of the oldest (1893), most
          taught, and familiar methods.
        </p>
        <p>
          The tooth is approximated into a “cantilever beam” and then a Lewis
          Form Factor is an empirical correction factor that approximates the
          difference between a beam and an involute gear tooth.
        </p>
        <p>
          This simplifies the calculation by lumping the complex tooth geometry
          into a single parameter that only depends on the gear's tooth count
          and pressure angle. The results are torque for moderate or endurance
          use limits.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={loadCallback}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <DPModuleInput onChange={updateGeometry} />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-face">
                  Face Width of Gear
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm for Module, <br />
                      inch for DP
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-face"
                  onChange={updateGeometry}
                />
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-numteeth">Number of Teeth</label>
                  <input
                    className="inputbox"
                    type="number"
                    id="input-numteeth"
                    size="25"
                    onChange={updateGeometry}
                  />
                </div>
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-pangdrop">Pressure Angle</label>
                  <select
                    className="inputbox"
                    name="input-pangdrop"
                    id="input-pangdrop"
                    onChange={updateGeometry}
                  >
                    <option value="default">Pressure angle...</option>
                    <option value="14.5">14.5 degrees</option>
                    <option value="20">20 degrees</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Lewis Form Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-I"
                    size="25"
                    placeholder="Needs tooth count and pressure angle"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 5 + "px" }}>
                  <label htmlFor="input-sacdrop">Material class</label>
                  <select
                    className="inputbox"
                    name="input-sacdrop"
                    id="input-sacdrop"
                    onChange={updateSAC}
                  >
                    <option value="default">Select class...</option>
                    <option value="Aluminum">Aluminum</option>
                    <option value="A538">A538</option>
                    <option value="H11">H11</option>
                    <option value="RQC100">RQC100</option>
                    <option value="1005-1009">1005-1009</option>
                    <option value="1015">1015</option>
                    <option value="1020">1020</option>
                    <option value="1040">1040</option>
                    <option value="1045">1045</option>
                    <option value="1080">1080</option>
                    <option value="1144">1144</option>
                    <option value="1541">1541</option>
                    <option value="30304">30304</option>
                    <option value="30310">30310</option>
                    <option value="4130">4130</option>
                    <option value="4140">4140</option>
                    <option value="4142">4142</option>
                    <option value="4340">4340</option>
                    <option value="5160">5160</option>
                    <option value="52100">52100</option>
                    <option value="9262">9262</option>
                    <option value="950">950</option>
                    <option value="980">980</option>
                  </select>
                </div>
                <div style={{ marginBottom: 5 + "px" }}>
                  <label htmlFor="input-materialdrop">
                    Material
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">
                        BHN is surface hardness
                      </span>
                    </div>
                  </label>
                  <select
                    className="inputbox"
                    name="input-materialdrop"
                    id="input-materialdrop"
                    onChange={updateMaterial}
                  >
                    <option value="default">Select class first...</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Yield stress (MPa)</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Sy"
                    size="25"
                    placeholder="Needs material selection"
                  />
                </div>
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Endurance stress (MPa)</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Se"
                    size="25"
                    placeholder="Needs material selection"
                  />
                </div>
              </div>

              <DecimalPlacesSelect defaultValue={2} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5}>
            <Image src="/calc-images/lewis-3.png" fluid /> <br />
            <Image src="/calc-images/lewis-4.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          The Lewis Form Factor approach to gear strength follows the same logic
          as a standard cantilevered beam. A force is applied near the tip of
          the gear tooth which causes the tooth to deflect. The maximum
          allowable force is determined by the geometry of the gear and either
          the yield stress of the material, or its endurance limit. The yield
          stress is the point at which a material plastically deforms and no
          longer "bounces back" to its original shape. The endurance limit is
          typically lower, but represents the greatest stress the material can
          repeatedly (typically over ten million times) experience before it
          fails via cyclic fatigue failure.
        </p>
        <h4>Geometry and the Lewis Form Factor</h4>
        <p>
          Gears are complex shapes, which in return require complex
          calculations. However, in the world of involute spur gears, most gears
          look similar. Since the fundamental shape is derived from the involute
          profile, the profile is the same regardless of whether a gear has a
          diametral pitch of 24 or 48, or a module or 0.5 or 3. That is, as long
          as the pressure angle and tooth count remain the same.
        </p>
        <p>
          With that knowledge, Wilfred Lewis set out to find simplifying
          constants to make gear strength calculations easier. Back in 1893
          Lewis determined the appropriate constants for 14.5º and 20º pressure
          angle gears from 10 to over 300 teeth. As one might expect the
          constants vary much more for low tooth count gears.
        </p>
        <br />
        <Image src="/calc-images/lewis-1.jpg" fluid />
        <h4>Historical and Modern Basis for Calculation</h4>
        <p>
          This calculator conforms to the bending equations found in Wilfred
          Lewis's "Investigation of the Strength of Gear Teeth." Proceedings of
          the Engineers Club, Philadelphia, 1893. Material properties are based
          on data from the Society of Automotive Engineers', Technical Report on
          Fatigue Properties, SAE J1099, 2018.
        </p>
        <h4>Lewis Form Factor Table</h4>
        <p>
          Below is a table of the Lewis Form Factor constants for 14.5º and 20º
          pressure angle gears, from 10-300 teeth.
        </p>
        <Image src="/calc-images/lewis-2.jpg" style={{ maxWidth: "300px" }} />
      </Card>
    </>
  );
}
