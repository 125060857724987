import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorFandS({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");
  const [saveMessageHob, setSaveMessageHob] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-module").value = "";
    document.getElementById("input-hardness").value = "";
    document.getElementById("input-machinability").value = "";
    document.getElementById("input-basespeed").value = "";
    document.getElementById("input-hobdia").value = "";
    document.getElementById("input-hobkfactor").value = "";
    document.getElementById("input-coatingkfactor").value = "";

    document.getElementById("input-hobcoatingdrop").value = "default";
    document.getElementById("input-hobmatldrop").value = "default";
    document.getElementById("input-matldrop").value = "default";

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function loadCallback(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    updateBase();
    calculate(event);
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  async function saveInputsHandlerHob(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'hob' for hobs
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "hob",
      project.project,
      true
    );
    setSaveMessageHob(saveInputResponse);
  }

  ////////
  var modWord = "Module";

  function updateBase() {
    var gearModule = parseFloat(document.getElementById("input-module").value);
    var gearMachinability = parseFloat(
      document.getElementById("input-machinability").value
    );

    // Surface Meters per Minute is calculated based on Module and Machinability
    // Format: Speed [SMM] = A + B*[Module] + C*[Module]^2
    // Equation coefficients are given by module
    var eqCoeffs = [
      [21.186105, 0.26158982, 0.010308871], // module 1
      [22.028978, -0.011544599, 0.012941738], // module 2
      [21.134339, -0.028151194, 0.012238435], // module 3
      [20.766412, -0.066077952, 0.012004956], // module 4
      [19.878595, -0.089784662, 0.011552074], // module 5
      [19.312974, -0.12179332, 0.011076106], // module 6
      [18.357279, -0.13776306, 0.010466206], // module 7
      [17.775462, -0.17017217, 0.010087802], // module 8
      [16.701913, -0.18015323, 0.009426318], // module 9
      [16.317731, -0.22099524, 0.009082388], // module 10
    ];

    if (gearModule && gearMachinability) {
      if (gearMachinability <= 2) gearMachinability = gearMachinability * 100; // In case someone entered it as a decimal

      var radioValue;
      if (document.getElementsByName("units")[1].checked) {
        radioValue = "in";
        gearModule = 25.4 / gearModule;
      } else {
        radioValue = "mm";
      }

      if (gearModule > 10 || gearModule < 1) {
        document.getElementById("input-basespeed").value = "Error";
        var errorMessage = "Module must be between 1 and 10.";
        setTextAreaText(errorMessage);
        resizeTextAnswer(errorMessage);
      } else {
        // Round module down, calculate result.
        // If it's not equal, round up, and interpolate

        var lowerGearModule = Math.floor(gearModule);
        var upperGearModule = Math.ceil(gearModule);

        var lowerIndex = lowerGearModule - 1; // module 1.2 -> module 1 -> index 0
        var upperIndex = lowerGearModule; // module 1.2 -> module 1 -> index 1
        var lowerGearSpeed =
          eqCoeffs[lowerIndex][0] +
          eqCoeffs[lowerIndex][1] * gearMachinability +
          eqCoeffs[lowerIndex][2] * gearMachinability ** 2;

        if (Math.abs(lowerGearModule - gearModule) < 0.0001) {
          // we're close enough, don't need to interpolate
          document.getElementById("input-basespeed").value =
            lowerGearSpeed.toFixed(2);
        } else {
          // Calculate the upper bound, and then interpolate
          var upperGearSpeed =
            eqCoeffs[upperIndex][0] +
            eqCoeffs[upperIndex][1] * gearMachinability +
            eqCoeffs[upperIndex][2] * gearMachinability ** 2;
          var baseResult =
            lowerGearSpeed +
            (gearModule - lowerGearModule) * (upperGearSpeed - lowerGearSpeed);
          document.getElementById("input-basespeed").value =
            baseResult.toFixed(2);
          updateHobCoating();
        }
      }
    } else {
      console.log("Do not calculate geometry yet");
    }
  }

  function updateHobMaterial() {
    // get value from dropdown and convert to number.
    // value needs to be text to match F&S model 2
    var hobMaterialValue = document.getElementById("input-hobmatldrop").value;
    var hobMaterialNumber;
    var hobMaterialCoeffs = [
      ["default", 1],
      ["M2", 0.616],
      ["M4", 0.77],
      ["M35", 1],
      ["M4+5 (Rex 54)", 1.08],
      ["T-15", 1.08],
      ["Rex 45", 1.15],
      ["Rex 76", 1.23],
      ["Rex 121", 1.58],
      ["Carbide", 1.92],
    ];

    for (let i = 0; i < hobMaterialCoeffs.length; i++) {
      if (hobMaterialCoeffs[i][0] === hobMaterialValue) {
        hobMaterialNumber = hobMaterialCoeffs[i][1];
        break;
      }
    }

    if (hobMaterialValue === "default") hobMaterialValue = 1;
    document.getElementById("input-hobkfactor").value = hobMaterialNumber;
  }

  function updateGearMaterial() {
    // get value from dropdown
    var gearMaterialValue = document.getElementById("input-matldrop").value;
    var gearHardness = document.getElementById("input-hardness").value;
    if (gearMaterialValue === "default") {
      document.getElementById("input-machinability").value = "Select Material";
      return;
    }

    var matlCoeffs = [
      // Min, Max, x^0, x^1, ... x^8
      [
        125.07875, 259.55199, 198004.51, -8792.0648, 168.88094, -1.8324327,
        0.012283918, -0.000052094652, 0.00000013649559, -0.00000000020205411,
        1.2941254e-13,
      ],
      [
        170.00742, 349.80443, -3208.8674, 74.185117, -0.71399716, 0.0037106932,
        -0.000010892014, 0.00000001696313, -0.000000000010883954, 0, 0,
      ],
      [
        170.00742, 349.80443, -3208.8674, 74.185117, -0.71399716, 0.0037106932,
        -0.000010892014, 0.00000001696313, -0.000000000010883954, 0, 0,
      ],
      [189.80243, 329.47698, 97.315427, -0.20635545, 0, 0, 0, 0, 0, 0, 0],
      [189.80243, 329.47698, 97.315427, -0.20635545, 0, 0, 0, 0, 0, 0, 0],
      [
        179.68749, 389.69638, 33376.647, -861.41341, 9.3635967, -0.055524003,
        0.00019424251, -0.00000040150709, 0.00000000045467961, -2.1787278e-13,
        0,
      ],
      [
        159.7407, 340.20613, 4642.778, -117.99502, 1.2328641, -0.0066919082,
        0.000019961056, -0.000000031143471, 0.000000000019919785, 0, 0,
      ],
      [
        189.41753, 319.94616, -529.46462, 8.7526064, -0.048715979,
        0.00012281392, -0.00000012134101, 0, 0, 0, 0,
      ],
      [
        179.97688, 389.80828, 3707.2146, -82.906892, 0.76045036, -0.0036136307,
        0.0000094116006, -0.000000012797978, 7.1282312e-12, 0, 0,
      ],
      [
        179.97688, 389.80828, 3707.2146, -82.906892, 0.76045036, -0.0036136307,
        0.0000094116006, -0.000000012797978, 7.1282312e-12, 0, 0,
      ],
      [
        230.34619, 339.485, 1595.4977, -19.823345, 0.095748639, -0.00020794918,
        0.00000017025664, 0, 0, 0, 0,
      ],
      [199.90871, 339.85849, 86.63607, -0.18437766, 0, 0, 0, 0, 0, 0, 0],
    ];

    var matlIndex = (gearMaterialValue = gearMaterialValue - 1); // graph 1 is index 0, etc.

    if (
      gearHardness < matlCoeffs[matlIndex][0] ||
      gearHardness > matlCoeffs[matlIndex][1]
    ) {
      document.getElementById("input-machinability").value = "Error";
      const errorMessage = `
Error
Material hardness is outside of range for material class.
Max range is ${matlCoeffs[matlIndex][0].toFixed(0)} to ${matlCoeffs[
        matlIndex
      ][1].toFixed(0)} BHN.`;
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
      return;
    }

    var coeff = matlCoeffs[matlIndex];
    var resultMachinability =
      coeff[2] +
      coeff[3] * gearHardness ** 1 +
      coeff[4] * gearHardness ** 2 +
      coeff[5] * gearHardness ** 3 +
      coeff[6] * gearHardness ** 4 +
      coeff[7] * gearHardness ** 5 +
      coeff[8] * gearHardness ** 6 +
      coeff[9] * gearHardness ** 7 +
      coeff[10] * gearHardness ** 8;
    document.getElementById("input-machinability").value =
      resultMachinability.toFixed(2);
    updateBase();
    updateHobCoating();
  }

  function updateHobCoating() {
    // get value from dropdown
    var hobMaterialCoating = document.getElementById("input-hobcoatingdrop").value;
    var gearMachinability = parseFloat(
      document.getElementById("input-machinability").value
    );

    var gearMaterialClass = document.getElementById("input-matldrop").value;
    if (gearMaterialClass === 12) {
      // Coating does not have an effect for ductile iron
      document.getElementById("input-coatingkfactor").value = 1.0;
    } else {
      if (gearMachinability <= 2) gearMachinability = gearMachinability * 100; // In case someone entered it as a decimal

      var result = 1;
      if (hobMaterialCoating === "1" && gearMachinability) {
        result = 0.00688 * gearMachinability + 0.919;
      }
      document.getElementById("input-coatingkfactor").value = result.toFixed(2);
    }
  }

  function calculate(event) {
    event.preventDefault();

    var gearModule = parseFloat(document.getElementById("input-module").value);
    // var precision = parseFloat(document.getElementById('precision').value);
    var gearMachinability = parseFloat(
      document.getElementById("input-machinability").value
    );
    var hobKfactor = parseFloat(
      document.getElementById("input-hobkfactor").value
    );
    var coatingKfactor = parseFloat(
      document.getElementById("input-coatingkfactor").value
    );

    logQA({ page: "GCSfands", module: gearModule, param7: modWord });

    var errorMessage = "Error:\n";
    var okToCalc = true;

    var checkGearModule = gearModule;
    var checkModuleIsModule = true;
    if (document.getElementsByName("units")[1].checked) {
      checkModuleIsModule = false;
      checkGearModule = 25.4 / gearModule;
    }

    if (isNaN(gearModule) || gearModule <= 0) {
      okToCalc = false;
      errorMessage += "Module/DP must be positive\n";
    }

    if (checkModuleIsModule && checkGearModule > 10) {
      // Set limit from 14 to 10 to match Model 1
      okToCalc = false;
      errorMessage += "Module must be less than 10\n";
    }
    if (checkModuleIsModule && checkGearModule < 1) {
      // Set limit from 0.5 to 1 to match Model 1
      okToCalc = false;
      errorMessage += "Module must be at least 1\n";
    }
    if (!checkModuleIsModule && checkGearModule > 10) {
      okToCalc = false;
      errorMessage += "DP must be greater than 2.54\n";
    }
    if (!checkModuleIsModule && checkGearModule < 1) {
      okToCalc = false;
      errorMessage += "DP must be less than 25.4\n";
    }

    if (isNaN(gearMachinability) || gearMachinability <= 0) {
      okToCalc = false;
      errorMessage += "Machinability must be positive\n";
    }

    if (isNaN(hobKfactor) || hobKfactor <= 0) {
      okToCalc = false;
      errorMessage += "Hob K factor must be positive\n";
    }

    if (isNaN(coatingKfactor) || coatingKfactor <= 0) {
      okToCalc = false;
      errorMessage += "Coating K factor must be positive\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }

    return false;
  }
  function calculateMOW() {
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    // get all units in metric (mm,module)

    var gearModule = parseFloat(document.getElementById("input-module").value);
    var gearMachinability = parseFloat(
      document.getElementById("input-machinability").value
    );
    var baseSpeed = parseFloat(
      document.getElementById("input-basespeed").value
    );
    var hobDiameter = parseFloat(document.getElementById("input-hobdia").value);
    var hobKfactor = parseFloat(
      document.getElementById("input-hobkfactor").value
    );
    var coatingKfactor = parseFloat(
      document.getElementById("input-coatingkfactor").value
    );

    var modelAdjust = parseFloat(document.getElementById("modeldrop").value);
    if (isNaN(modelAdjust) || !modelAdjust) {
      // Add the three (arbitrary) model adjustments
      modelAdjust = 1;
    }
    var modelText;
    if (modelAdjust === 1) modelText = "Model A";
    else if (modelAdjust === 0.875) modelText = "Model B";
    else if (modelAdjust === 0.75) modelText = "Model C";
    else modelText = "undefined";

    var materialClass = document.getElementById("input-matldrop").value;
    if (materialClass === 12) {
      coatingKfactor = 1; // Coating factor does not apply to ductile iron
    }

    var hobDiameterInput = hobDiameter;

    var finalSurfaceSpeed =
      baseSpeed * hobKfactor * coatingKfactor * modelAdjust;

    // var precision = parseFloat(document.getElementById('precision').value);
    let moduleInput = gearModule;

    let unitWord = "mm";
    let moduleWord = "Module";

    if (radioValue === "in") {
      gearModule = 25.4 / gearModule;
      hobDiameter = hobDiameter * 25.4;
      unitWord = "in";
      moduleWord = "Diametral Pitch";
    }

    var textToWrite = "Inputs";
    textToWrite += "\n" + moduleWord + ":\t" + moduleInput;
    textToWrite +=
      "\nHob Material:\t" + document.getElementById("input-hobmatldrop").value;
    textToWrite += "\nHob Diameter:\t" + hobDiameterInput + "\t" + unitWord;
    textToWrite += "\nHob Material K-Factor:\t" + hobKfactor;
    textToWrite += "\nHob Coating K-Factor: \t" + coatingKfactor;
    textToWrite += "\nMachinability:\t" + Math.floor(gearMachinability) + "\t%";
    textToWrite += "\nSpeed Model:\t" + modelText;

    textToWrite += "\n\nOutputs";
    textToWrite +=
      "\nSurface Meters/Min (SMM):\t" +
      finalSurfaceSpeed.toFixed(1) +
      "\tm/min";
    textToWrite +=
      "\nSurface Feet/Min (SFM):\t" +
      (finalSurfaceSpeed * 3.28).toFixed(1) +
      "\tft/min";
    textToWrite +=
      "\nRevolutions per Minute (RPM):\t" +
      Math.floor(
        (finalSurfaceSpeed * 3.28 * 12) / (Math.PI * (hobDiameter / 25.4))
      ) +
      "\trpm";
    textToWrite +=
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);

    return false;
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">High Speed Manufacturing (Model I)</div>
        <p>
          Modern High Speed Steel (HSS) hobs have special mixtures including
          trace elements of Carbon, Chrome, Tungsten, Molybdenum, Vanadium and
          Cobalt to optimize performance.
        </p>
        <p>
          Cobalt helps with red hardness, without Cobalt there is more wear
          resistance. Carbides are extremely hard and provide the highest
          cutting speeds.
        </p>
        <p>
          This model determines a starting point for the first cut/roughing
          speeds for the gear hobbing process.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={loadCallback}
              userLevel={userLevel}
            />
            <LoadSavedGear
              reqRecordType="hob"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={loadCallback}
              buttonText="Load Hob"
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="modeldrop">Speed Model</label>
                  <select className="inputbox" name="modeldrop" id="modeldrop">
                    <option value="default">Model...</option>
                    <option value="1">Model A</option>
                    <option value="0.875">Model B</option>
                    <option value="0.75">Model C</option>
                  </select>
                </div>

                <DPModuleInput onChange={updateBase} />

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-hardness">
                    Material Hardness (BHN)
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">
                        BHN is Brinell Hardness Number
                      </span>
                    </div>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-hardness"
                    size="25"
                    placeholder="Hardness (BHN)"
                    onChange={updateGearMaterial}
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-matldrop">Material Class</label>
                  <select
                    className="inputbox"
                    name="input-matldrop"
                    id="input-matldrop"
                    onChange={updateGearMaterial}
                  >
                    <option value="default">Gear material...</option>
                    <option value="1">
                      Carbon Steels (10xx, 11xx, 12xx, 15xx)
                    </option>
                    <option value="2">Low alloy chrome steels</option>
                    <option value="3">Nickel Steels</option>
                    <option value="4">Chrome-nickel Steels</option>
                    <option value="5">
                      High Alloy Chr-N Steel (31xx, 33xx, 303xx Stainless)
                    </option>
                    <option value="6">
                      Chr-Ni-Moly (43xx, 47xx, 86xx, 87xx, 93xx, 98xx)
                    </option>
                    <option value="7">Ni-Moly Steels (46xx, 48xx)</option>
                    <option value="8">Chrome-Moly Steels (41xx)</option>
                    <option value="9">
                      Chrome Steels (50xx, 51xx, 511xx, 521xx, 515xx)
                    </option>
                    <option value="10">Chrome-Vanadium Steels (61xx)</option>
                    <option value="11">Silicon-Manganese Steels (92xx)</option>
                    <option value="12">Ductile Iron</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-machinability">
                    Material Machinability
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">Enter as % (e.g. 70)</span>
                    </div>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-machinability"
                    size="25"
                    placeholder="Needs material and hardness"
                    onChange={updateBase}
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Base Speed (SMM)</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-basespeed"
                    size="25"
                    placeholder="Needs module and machinability"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-numteeth">Hob Diameter</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-hobdia"
                    size="25"
                    placeholder="Hob Diameter"
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-hobmatldrop">Hob Material</label>
                  <select
                    className="inputbox"
                    name="input-hobmatldrop"
                    id="input-hobmatldrop"
                    onChange={updateHobMaterial}
                  >
                    <option value="default">Hob material...</option>
                    <option value="M2">M2</option>
                    <option value="M4">M4</option>
                    <option value="M35">M35</option>
                    <option value="M4+5 (Rex 54)">M4+5 (Rex 54)</option>
                    <option value="T-15">T-15</option>
                    <option value="Rex 45">Rex 45</option>
                    <option value="Rex 76">Rex 76</option>
                    <option value="Rex 121">Rex 121</option>
                    <option value="Carbide">Carbide</option>
                  </select>
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label htmlFor="input-hobcoatingdrop">Hob Coating</label>
                  <select
                    className="inputbox"
                    name="input-hobcoatingdrop"
                    id="input-hobcoatingdrop"
                    onChange={updateHobCoating}
                  >
                    <option value="default">Hob coating...</option>
                    <option value="1">TiN Coating</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Hob K-Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-hobkfactor"
                    size="25"
                    placeholder="Needs hob material"
                  />
                </div>

                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Coating K-Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-coatingkfactor"
                    size="25"
                    placeholder="Needs hob coating"
                  />
                </div>
              </div>

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
              {userLevel > 0 && (
                <>
                  <SaveButtonBlock
                    project={project}
                    setProject={setProject}
                    saveInputsHandler={saveInputsHandler}
                    saveMessage={saveMessage}
                  />

                  <SaveButtonBlock
                    project={project}
                    setProject={setProject}
                    saveInputsHandler={saveInputsHandlerHob}
                    inputText={"Hob Name"}
                    inputId={"input-hobname"}
                    buttonText={"Save Hob"}
                    saveMessage={saveMessageHob}
                  />
                </>
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={5}>
            <Image src="/calc-images/fands-1.jpg" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          If your machine specifications do not allow a fast hob speed, reduce
          speed to 75-80% of your max machine specifications for roughing.
        </p>
        <p>
          This model is a guide for roughing speeds and feeds. Hobs and machine
          capabilities have a lot to do with your individual results. The user
          should keep within the safe operating limits of their machinery when
          hobbing, and use hobs that are sharp and in good condition.
        </p>
        Speed Calculation in existing model
        <ul>
          <li>
            A Model results are for new rigid CNC machines and for maximum
            material removal in ideal conditions. This model may result shorter
            tool life
          </li>
          <li>
            B Model results are for used but rigid CNC machines, or older
            rebuilt and higher quality machines
          </li>
          <li>
            C Model results are for pre-75 machines with lower RPM capabilities
            and both age and rigidity derate operational speeds.
          </li>
        </ul>
        The user should experiment with this and match it to their machine.
        <p>Values for Speeds are good down to 1 module.</p>
      </Card>
    </>
  );
}
