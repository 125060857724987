import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { DecimalPlacesSelect, ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorLeadThreads({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-method").value = "tpi";
    document.getElementById("input-dia").value = "";
    document.getElementById("input-pitch").value = "";
    document.getElementById("input-starts").value = "1";
    document.getElementById("input-lead").value = "";
    document.getElementById("input-pitch").disabled = false;
    document.getElementById("input-starts").disabled = false;
    document.getElementById("input-lead").disabled = true;

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function methodChange() {
    if (document.getElementById("input-method").value === "tpi") {
      document.getElementById("input-dia").placeholder = "Diameter (in)";
      document.getElementById("input-pitch").placeholder = "Threads per inch";
      document.getElementById("input-pitch").disabled = false;
      document.getElementById("input-starts").disabled = false;
      document.getElementById("input-lead").disabled = true;
    } else if (document.getElementById("input-method").value === "pitch") {
      document.getElementById("input-dia").placeholder = "Diameter (mm)";
      document.getElementById("input-pitch").placeholder = "Pitch (mm)";
      document.getElementById("input-pitch").disabled = false;
      document.getElementById("input-starts").disabled = false;
      document.getElementById("input-lead").disabled = true;
    } else if (document.getElementById("input-method").value === "leadin") {
      document.getElementById("input-dia").placeholder = "Diameter (in)";
      document.getElementById("input-pitch").placeholder = "Pitch";
      document.getElementById("input-lead").placeholder = "Lead (in)";
      document.getElementById("input-pitch").disabled = true;
      document.getElementById("input-starts").disabled = true;
      document.getElementById("input-lead").disabled = false;
    } else if (document.getElementById("input-method").value === "leadmm") {
      document.getElementById("input-dia").placeholder = "Diameter (mm)";
      document.getElementById("input-pitch").placeholder = "Pitch";
      document.getElementById("input-lead").placeholder = "Lead (mm)";
      document.getElementById("input-pitch").disabled = true;
      document.getElementById("input-starts").disabled = true;
      document.getElementById("input-lead").disabled = false;
    }
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var validTest = true;

    var precision = parseInt(document.getElementById("precision").value);
    var method = document.getElementById("input-method").value;
    var pitch = parseFloat(document.getElementById("input-pitch").value);
    var lead = parseFloat(document.getElementById("input-lead").value);
    var starts = parseFloat(document.getElementById("input-starts").value);
    var diameter = parseFloat(document.getElementById("input-dia").value);

    logQA({
      page: "GCSleadangleT",
      param5: method,
      param6: pitch,
      param7: lead,
      param8: starts,
      param9: diameter,
    });

    var errorMessage;
    if (
      (method === "pitch" || method === "tpi") &&
      (pitch <= 0 || isNaN(pitch) || starts <= 0 || isNaN(starts))
    ) {
      validTest = false;
      errorMessage = "Pitch and starts must be positive numbers";
    }

    if (
      (method === "leadin" || method === "leadmm") &&
      (lead <= 0 || isNaN(lead))
    ) {
      validTest = false;
      errorMessage = "Lead must be a positive number";
    }

    if (
      !(
        method === "leadin" ||
        method === "leadmm" ||
        method === "tpi" ||
        method === "pitch"
      )
    ) {
      validTest = false;
      errorMessage = "Invalid method of calculation";
    }

    if (isNaN(precision)) {
      validTest = false;
      errorMessage = "Number of decimal places must be an integer";
    }

    if (diameter <= 0 || isNaN(diameter)) {
      validTest = false;
      errorMessage = "Diameter must be a positive value";
    }

    if (validTest) {
      if (method === "tpi") {
        let tanA = starts / (Math.PI * diameter * pitch);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "in",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
      if (method === "pitch") {
        let tanA = (starts * pitch) / (Math.PI * diameter);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "mm",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
      if (method === "leadin") {
        let tanA = lead / (Math.PI * diameter);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "in",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
      if (method === "leadmm") {
        let tanA = lead / (Math.PI * diameter);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "mm",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
    } else {
      setTextAreaText("Error\n\n" + errorMessage);
      resizeTextAnswer("Error\n\n" + errorMessage);
    }
  }
  function writeAnswer(
    method,
    diameterUnit,
    pitch,
    lead,
    starts,
    diameter,
    leadAngle,
    precision
  ) {
    let leadAngleDeg = (leadAngle * 180) / Math.PI;
    let degrees = Math.floor(leadAngleDeg);
    let minutes = 60 * (leadAngleDeg - degrees);
    let minutesF = Math.floor(minutes);
    let seconds = Math.floor(60 * (minutes - minutesF));

    var textToWrite = `Inputs\n`;
    if (method === "tpi") {
      textToWrite =
        textToWrite +
        `Threads per Inch:\t${pitch}\ttpi\nThread Starts:\t${starts}`;
    }
    if (method === "pitch") {
      textToWrite =
        textToWrite + `Pitch:\t${pitch}\tmm\nThread Starts:\t${starts}`;
    }
    if (method === "leadin") {
      textToWrite = textToWrite + `Lead:\t${lead}\tin`;
    }
    if (method === "leadmm") {
      textToWrite = textToWrite + `Lead:\t${lead}\tmm`;
    }
    textToWrite = textToWrite + `\nDiameter:\t${diameter}\t${diameterUnit}`;

    textToWrite =
      textToWrite +
      `\n\nOutputs\nLead Angle:\t${leadAngleDeg.toFixed(
        precision
      )}\tdeg\nLead Angle:\t${degrees}\u00B0${minutesF}'${seconds}"`;

    //textToWrite = "Inputs\n" + inputText + ":\t" + inputValue.toFixed(precision) + "\t" + inputUnits + "\n\nOutputs\n" + "Module:\t" + oModule.toFixed(precision) + "\tmm\nDiametral Pitch:\t" +oDP.toFixed(precision) + "\t1/in\nCircular Pitch: \t" + oCP.toFixed(precision) + "\tin" + "\nThreads per Inch: \t" + oTPI.toFixed(precision) + "\t1/in" ;
    textToWrite =
      textToWrite +
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Lead Angle (Threads)</div>
        <p>
          <span className="term-emphasis">Lead Angle</span> is the angle between
          the helix and its plane of rotation. In a screw, this is the angle the
          threads make relative to its rotation.
        </p>
        <p>
          As the thread pitch increases (finer threads, with closer spacing)
          each thread is closer to being vertical, and so the angle is less and
          less. Conversely, as threads get coarser each thread covers more
          length along the screw and so the angle increases.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-method">Method</label>
                <select
                  className="inputbox"
                  name="input-method"
                  id="input-method"
                  onChange={methodChange}
                >
                  <option value="tpi">Threads per Inch</option>
                  <option value="pitch">Pitch (mm)</option>
                  <option value="leadin">Lead (inch)</option>
                  <option value="leadmm">Lead (mm)</option>
                </select>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-dia">Effective Diameter</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-dia"
                  placeholder="Diameter (in)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pitch">
                  Pitch
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Module is in millimeters, DP is in 1/inch
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pitch"
                  placeholder="Threads per inch"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-starts"># of Thread Starts</label>
                <select
                  className="inputbox"
                  name="input-starts"
                  id="input-starts"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-lead">Lead</label>
                <input
                  className="inputbox"
                  disabled={true}
                  type="number"
                  id="input-lead"
                  placeholder="Lead"
                />
              </div>

              <DecimalPlacesSelect defaultValue={3} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/leadthread-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          The <span className="term-emphasis">lead angle</span> for a screw or
          bolt is based on the thread pitch, diameter, and number of starts. The
          pitch (how coarse or fine the screw is) is measured either by the
          number of threads per inch, thread pitch, or lead. While English
          screws typically use the number of threads per inch, metric screws use
          pitch, which is the distance between two threads. For a single start
          thread, lead is equal to the pitch.
        </p>
        <p>
          Screws can also have multiple starts, which allow them to screw in
          faster, that is to cover more distance per revolution, while retaining
          the original thread pitch. This can be accounted for by either
          entering the number of thread starts, or directly calculating the
          Lead. Lead is the linear travel the nut makes per one screw
          revolution. For multiple start screws, the lead is the pitch
          multiplied by the number of starts.
        </p>
      </Card>
    </>
  );
}
