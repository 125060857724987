// special import to redirect after login
import { useNavigate, useLocation } from "react-router-dom";

import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { withRouter } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Redirect } from "react-router-dom";

// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { Elements } from "@stripe/react-stripe-js";

var STRIPE_PUBLISHABLE_KEY;
if (process.env.REACT_APP_MODE === "production") {
  STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_PROD_STRIPE_PUBLISHABLE_KEY;
} else {
  STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_DEV_STRIPE_PUBLISHABLE_KEY;
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function PaymentPage(props) {
  return (
    <Elements stripe={stripePromise}>
      <MyComponent {...props} />
    </Elements>
  );
}

function MyComponent({ location }) {
  // Get the lookup key for the price from the previous page redirect.

  const { state } = useLocation(); // we passed in state information from /register via useNavigate

  let navigate = useNavigate();

  const clientSecret = state.clientSecret; //useState(location.state.clientSecret);
  const subscriptionId = state.subscriptionId; // useState(location.state.subscriptionId);

  const [name, setName] = useState("");
  const [messages, _setMessages] = useState("");
  const [paymentIntent, setPaymentIntent] = useState();

  // Style options for Stripe CardElement
  const cardElementOptions = {
    style: {
      base: {
        color: "#666",
        fontSize: "18px",
      },
      invalid: {
        color: "#fa755a",
        fontSize: "18px",
      },
    },
  };

  // Format the promo code message
  var promoApplied = false;
  var promoMessage = "<li>";

  var promoCodeData;
  try {
    promoCodeData = JSON.parse(state.promoCode);
  } catch {
    promoCodeData = { coupon: { id: false } };
  }

  if (promoCodeData["coupon"]["id"]) {
    // There is a promo code applied
    promoApplied = true;
    promoMessage = "Discount applied: ";
    if (promoCodeData["coupon"]["amount_off"]) {
      promoMessage +=
        "$" + (promoCodeData["coupon"]["amount_off"] / 100).toFixed(2) + " off";
    } else {
      promoMessage +=
        promoCodeData["coupon"]["percent_off"].toFixed(0) + "% off";
    }
    if (promoCodeData["coupon"]["duration"] === "forever") {
      promoMessage += " each payment";
    } else if (promoCodeData["coupon"]["duration"] === "once") {
      promoMessage += " for the first payment";
    } else {
      promoMessage +=
        " for the first " +
        promoCodeData["coupon"]["duration_in_months"] +
        " months";
    }
  }

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "";
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name,
          },
        },
      }
    );

    if (error) {
      // show error and collect new card details.
      setMessage(error.message);
      return;
    }

    if (paymentIntent && paymentIntent.status === "succeeded") {
      // Inside the handleSubmit, need to make our async call
      // only on success

      var refreshToken, tempUserInfo;
      try {
        // Check if there is an existing record, and if so, pull in its data
        tempUserInfo = localStorage.getItem("currentUser");
        tempUserInfo = JSON.parse(tempUserInfo);
        refreshToken = tempUserInfo.refreshToken;
      } catch {
        refreshToken = "";
      }

      // Send subscription info over, it will pull out product info and customer email
      // Price is translated to a Tier (Cognito Group) and user gets added
      const response = await fetch("/subscription-success", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          subscriptionId: subscriptionId,
          refreshtoken: refreshToken,
        }),
      });

      const responseBody = await response.json();
      // Handle the response

      tempUserInfo["idToken"] = responseBody.newtoken.IdToken;
      tempUserInfo["accessToken"] = responseBody.newtoken.AccessToken;
      localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
    }
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    // TODO: After adding, redirect to account management (for now, welcome page later)
    setTimeout(function () {
      navigate("/account");
    }, 100);
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card className="project-card">
              <div className="project-name">Review your Order</div>
              <p>We're excited to have you join GearCalculators.com!</p>
              <p>Your credit card will be charged:</p>

              <ul>
                <li>
                  ${(state.totalAmount / 100).toFixed(2)} per{" "}
                  {state.totalFrequency}
                </li>
                {promoApplied ? <li>{promoMessage}</li> : ""}
              </ul>
              <p>
                Now, and at any point on GearCalculators, if you run into
                trouble please email contact@evolventdesign.com
              </p>
              <p>
                If you'd like to change the plan or billing frequency, please{" "}
                <a href="/change-plan">click here</a>
              </p>
              <hr />

              <form onSubmit={handleSubmit}>
                <label style={{ marginBottom: 6 + "px" }}>
                  Full name (on card):
                  <br />
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label>
                  Credit Card, processed by Stripe
                  <br />
                </label>
                <Col xs={6} sm={6}>
                  <CardElement options={cardElementOptions} />

                  <button className="btn-primary">Submit</button>
                  <br />
                  <br />
                  <Image src="./stripe.png" height={"40px"}></Image>
                </Col>
                <Col xs={12} sm={6}></Col>
                <div>{messages}</div>
              </form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

//export default withRouter(Subscribe);
