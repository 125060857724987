import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
    getProjectNameFromID,
    resizeTextAnswer,
    formatDate,
    logQA,
  } from "./helpers";
import { ButtonBlockTextArea, SaveButtonBlock } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalcEntryMachine({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)

  function clearInputs(event) {

    let machineParams = [
        'manufacturer',
        'model',
        'mfgdate',
        'serial',
        'maxmodule',
        'minmodule',
        'maxdiaspur',
        'maxfacespur',
        'mintooth',
        'maxhobspeed',
        'minhobspeed',
        'maxfeedlong',
        'minfeedlong',
        'maxdiahob',
        'cgears',
        'cgearsfeed',
        'cgearshelical',
        'mcindex',
        'mcfeed',
        'mchelical',
      ];
    for(let i=0;i<machineParams.length;i++){
    document.getElementById("input-"+machineParams[i]).value = "";
    }
    event.preventDefault();
    return false;
  }

  function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'machine' for one set of change gears
    // Loading the dropdown should match this record type

    // Get project name
    saveInputs(event, "machine", project.project, false);
  }

function calculate(event){
    event.preventDefault();
    var displayProjectName = getProjectNameFromID(project.project);
    var displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";

    var textToWrite =
`Manufacturer:\t${document.getElementById('input-manufacturer').value}
Model:\t${document.getElementById('input-model').value}
Manufacture Date:\t${document.getElementById('input-mfgdate').value}
Serial Number:\t${document.getElementById('input-serial').value}
Max Module:\t${document.getElementById('input-maxmodule').value}
Min Module:\t${document.getElementById('input-minmodule').value}
Max Diameter (Spur):\t${document.getElementById('input-maxdiaspur').value}\tmm
Max Face Width (Spur):\t${document.getElementById('input-maxfacespur').value}\tmm
Min Tooth Count:\t${document.getElementById('input-mintooth').value}
Max Hob Speed:\t${document.getElementById('input-maxhobspeed').value}\trpm
Min Hob Speed:\t${document.getElementById('input-minhobspeed').value}\trpm
Max Feed (Longitudinal):\t${document.getElementById('input-maxfeedlong').value}\tmm/min
Min Feed (Longitudinal):\t${document.getElementById('input-minfeedlong').value}\tmm/min
Max Diameter Hob:\t${document.getElementById('input-maxdiahob').value}\tmm
Change Gears (Index):\t${document.getElementById('input-cgears').value}
Change Gears (Feed):\t${document.getElementById('input-cgearsfeed').value}
Change Gears (Helical):\t${document.getElementById('input-cgearshelical').value}
Machine Constant (Index):\t${document.getElementById('input-mcindex').value}
Machine Constant (Feed):\t${document.getElementById('input-mcfeed').value}
Machine Constant (Helical):\t${document.getElementById('input-mchelical').value}`;

    textToWrite +=
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date());

    textToWrite += `\nProject:\t${displayProjectName}`;
    textToWrite += `\nMachine:\t${displayGearName}\nGCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
    return;
}

  return (
    <>
      <Card className="project-card">
        <div className="project-name">
          Enter Machine Info
        </div>
        <p>
          Store information about the machines in your shop
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="machine"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              buttonText="Load Machine"
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-manufacturer">Manufacturer</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-manufacturer"
                  size="25"
                  placeholder="Manufacturer"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-model">Model</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-model"
                  size="25"
                  placeholder="Model"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-mfgdate">Manufacture Date</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-mfgdate"
                  size="25"
                  placeholder="Manufacture Date"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-serial">Serial Number</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-serial"
                  size="25"
                  placeholder="Serial Number"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-maxmodule">Max Module</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-maxmodule"
                  size="25"
                  placeholder="Max Module"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-minmodule">Min Module</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-minmodule"
                  size="25"
                  placeholder="Min Module"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-maxdiaspur">Max Diameter (spur gear)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-maxdiaspur"
                  size="25"
                  placeholder="Max Diameter Spur (mm)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-maxfacespur">Max Face Width (spur gear)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-maxfacespur"
                  size="25"
                  placeholder="Max Face Width Spur (mm)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-mintooth">Min Tooth Count</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-mintooth"
                  size="25"
                  placeholder="Min Tooth Count"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-maxhobspeed">Max Hob Speed</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-maxhobspeed"
                  size="25"
                  placeholder="Max Hob Speed (rpm)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-minhobspeed">Min Hob Speed</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-minhobspeed"
                  size="25"
                  placeholder="Min Hob Speed (rpm)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-maxfeedlong">Max Feed (longitudinal)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-maxfeedlong"
                  size="25"
                  placeholder="Max Feed (mm/min)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-minfeedlong">Min Feed (longitudinal)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-minfeedlong"
                  size="25"
                  placeholder="Min Feed (mm/min)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-maxdiahob">Max Hob Diameter</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-maxdiahob"
                  size="25"
                  placeholder="Max Hob Diameter (mm)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Index Change Gears
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Comma-separated list of change gears
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-cgears"
                  size="25"
                  placeholder="Index Change Gear List"
                />
                <br />
                <a
                  className="inputbox"
                  href="https://oilviscositychart.com/cgears/radio-input.php"
                >
                  Make a list
                </a>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Feed Change Gears
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Comma-separated list of change gears
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-cgearsfeed"
                  size="25"
                  placeholder="Feed Change Gear List"
                />
                <br />
                <a
                  className="inputbox"
                  href="https://oilviscositychart.com/cgears/radio-input.php"
                >
                  Make a list
                </a>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Helical Change Gears
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Comma-separated list of change gears
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-cgearshelical"
                  size="25"
                  placeholder="Helical Change Gear List"
                />
                <br />
                <a
                  className="inputbox"
                  href="https://oilviscositychart.com/cgears/radio-input.php"
                >
                  Make a list
                </a>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-mcindex">Machine Constant (Index)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-mcindex"
                  size="25"
                  placeholder="Machine Constant (Index)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-mcfeed">Machine Constant (Feed)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-mcfeed"
                  size="25"
                  placeholder="Machine Constant (Feed)"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-mchelical">Machine Constant (Helical)</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-mchelical"
                  size="25"
                  placeholder="Machine Constant (Helical)"
                />
              </div>

              <div>Click once and wait, may take up to 60 seconds!</div>
              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/cgdb-1.png" fluid /> <br />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>

        
      </Card>
    </>
  );
}
