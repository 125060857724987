import React, { useState } from "react";

import { Card } from "react-bootstrap";

import styles from "./login.module.css";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(true);
  const [message, setMessage] = useState("");

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    // Send the form
    setMessage("");
    if (oldPassword === "") {
      setMessage("Current password is required.");
      setReady(false);
      return;
    }
    if (newPassword.length < 8) {
      setMessage("Password must be at least 8 characters.");
      setReady(false);
      return;
    }
    if (newPassword !== newPasswordConfirm) {
      setMessage("New passwords must match");
      setReady(false);
      return;
    }

    // Passed the checks, so we can send the data to the backend

    var accessToken, username;
    try {
      accessToken = localStorage.getItem("currentUser")
        ? JSON.parse(localStorage.getItem("currentUser")).accessToken
        : "";
      username = localStorage.getItem("currentUser")
        ? JSON.parse(localStorage.getItem("currentUser")).username
        : "";
    } catch {
      accessToken = "";
      username = "";
    }

    const pwdChangeInfo = {
      username: username,
      accesstoken: accessToken,
      oldpassword: oldPassword,
      newpassword: newPassword,
    };

    const response = await fetch("/changepassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(pwdChangeInfo),
    });
    const pwdResponse = await response.json();

    // if ok, or not, do things
    if (pwdResponse.changesuccess) {
      // Success!
      setMessage(pwdResponse.changemsg);
    } else {
      setMessage(pwdResponse.changemsg);
    }
  };

  return (
    <Card className="project-card">
      <div className="project-name gray">Change Password</div>
      <div id="subscriptions">
        <form>
          <div className={styles.loginForm}>
            <div className={styles.loginFormItem}>
              <label htmlFor="password">Current Password</label>
              <input
                type="password"
                id="password"
                value={oldPassword}
                onChange={(e) => {
                  setMessage("");
                  setOldPassword(e.target.value);
                }}
                disabled={loading}
              />
            </div>
            <div className={styles.loginFormItem}>
              <label htmlFor="newpassword">New Password</label>
              <input
                type="password"
                id="newpassword"
                value={newPassword}
                onChange={(e) => {
                  setMessage("");
                  setNewPassword(e.target.value);
                }}
                disabled={loading}
              />
            </div>
            <div className={styles.loginFormItem}>
              <label htmlFor="newpasswordconfirm">Confirm New Password</label>
              <input
                type="password"
                id="newpasswordconfirm"
                value={newPasswordConfirm}
                onChange={(e) => {
                  setNewPasswordConfirm(e.target.value);
                  setMessage("");
                }}
                disabled={loading}
              />
            </div>
          </div>
          <button onClick={handlePasswordChange} className="btn-primary">
            Change Password
          </button>
          <div>{message ? message : ""}</div>
        </form>
      </div>
    </Card>
  );
}
