// import EmailJS requirements
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Footer from "./footer";
import PublicHeader from "./header";

export default function PublicContactPage() {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [submitMessage, setSubmitMessage] = React.useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    document.getElementById("contact-submit").value = "Sending...";

    emailjs
      .sendForm(
        "service_pkwrzw8",
        "template_s41oc85",
        form.current,
        "RU4JGMRXKEWyuaKKk"
      )
      .then(
        (result) => {
          setHasSubmitted(true);
          document.getElementById("contact-submit").value = "Sent!";
          setSubmitMessage("Sent! Thanks for your feedback!");
        },
        (error) => {
          setHasSubmitted(true);
          setSubmitMessage(
            "An error has occurred. Please retry, or send via email."
          );
        }
      );
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />
          <Row>
            <h2 className="card-heading">contact us</h2>
            <p>
              We'd love to hear from you! Whether you're having trouble, found a
              bug, have a suggestion for our next calculator or improvement, or
              just want to say hi, please reach out below!
            </p>
            <p>
              If you don't like forms, or want to send attachments, please
              email: <b>contact@evolventdesign.com</b>
            </p>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col>
              <Card className="border-0" style={{ alignItems: "center" }}>
                <form ref={form} onSubmit={sendEmail}>
                  <label className="card-heading">name</label>
                  <input
                    className="contact-form-width"
                    type="text"
                    name="user_name"
                  />

                  <label className="card-heading">email</label>
                  <input
                    className="contact-form-width"
                    type="email"
                    name="user_email"
                  />

                  <label className="card-heading">phone</label>
                  <input
                    className="contact-form-width"
                    type="text"
                    name="user_phone"
                  />

                  <label className="card-heading">message</label>
                  <textarea
                    className="contact-form-width contact-form-area"
                    rows="5"
                    name="message"
                  />
                  <br />
                  <input
                    id="contact-submit"
                    className="contact-submit"
                    type="submit"
                    value="Send Message"
                  />
                </form>
                {hasSubmitted ? <p>{submitMessage}</p> : <p></p>}
              </Card>
            </Col>
          </Row>

          <Footer></Footer>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}
