import React from "react";

// Import Bootstrap style elements
import Card from "react-bootstrap/Card";

export default function CalculatorSTL({ pageLevel, userLevel }) {
  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">STL Generator</div>
        <p>
          This calculator exports .STL, .DXF and .SVG files that are
          dimensionally correct, units are millimeters.
        </p>
        <p>
          <span className="term-emphasis">.STL</span> is a 3D file, often used
          for 3D printing.
          <br />
          <span className="term-emphasis">.DXF</span> is a 2D file, commonly
          used for drafting and designing, and may be helpful for waterjets,
          laser cutters, and other tools.
          <br />
          <span className="term-emphasis">.SVG</span> is a 2D vector graphics
          format, and commonly used for drawings, designs, and illustrations.
        </p>
        <p>
          Based on tooth count (n), Gear 1 can be an{" "}
          <span className="term-emphasis">External Spur Gear (n&gt;0)</span>,{" "}
          <span className="term-emphasis">Internal Spur Gear (n&lt;0)</span>, or{" "}
          <span className="term-emphasis">Rack Gear (n=0)</span>.
        </p>
        <p>
          Generated gears may have "undercut" teeth for low tooth counts, which
          allows them to run without binding, but may weaken tooth strength.
        </p>
      </Card>

      <Card style={{ padding: "20px" }}>
        <iframe
          title="OVC STL"
          src="https://oilviscositychart.com/gearstl-gcs/gearstl/index-gcs.php"
          width="100%"
          height="1200px"
          style={{
            border: "none",
            minWidth: "fit-content",
            minHeight: "fit-content",
            pointerEvents: pageLevel > userLevel ? "none" : "all",
          }}
        ></iframe>
      </Card>
    </>
  );
}
