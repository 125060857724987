// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Footer from "./footer";
import PublicHeader from "./header";

export default function PublicNewsPage() {
  const blogData = [
    {
      id: 1,
      date: "October 7, 2023",
      title: "This is a title that keeps going and going and going",
      excerpt:
        "The quick red fox jumps over the lazy brown dog. And other continued excerpts",
      link: "https://gearcalculators.com/news/1",
      featured: true,
    },
    {
      id: 1,
      date: "October 6, 2023",
      title: "This is a title",
      excerpt:
        "The quick red fox jumps over the lazy brown dog. And other continued excerpts",
      link: "https://gearcalculators.com/news/1",
      featured: false,
    },
    {
      id: 1,
      date: "October 6, 2023",
      title: "This is a title",
      excerpt:
        "The quick red fox jumps over the lazy brown dog. And other continued excerpts",
      link: "https://gearcalculators.com/news/1",
      featured: false,
    },
    {
      id: 1,
      date: "October 6, 2023",
      title: "This is a title",
      excerpt:
        "The quick red fox jumps over the lazy brown dog. And other continued excerpts",
      link: "https://gearcalculators.com/news/1",
      featured: false,
    },
    {
      id: 1,
      date: "October 6, 2023",
      title: "This is a title",
      excerpt:
        "The quick red fox jumps over the lazy brown dog. And other continued excerpts",
      link: "https://gearcalculators.com/news/1",
      featured: false,
    },
  ];

  // To keep the table nice and clean, we'll add empty rows until it's divisible by 2 and 3
  var emptyBlog = {
    id: 0,
    date: "",
    title: "",
    excerpt: "",
    link: "",
    featured: false,
  };
  var bufferRows = 6 - (blogData.length % 6);
  for (let i = 0; i < bufferRows; i++) {
    blogData.push(emptyBlog);
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />
          <Row>
            <h2 className="card-heading">featured</h2>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col>
              <Card className="border-0">
                {
                  /* Output a row for each question and answer, with anchor link*/
                  blogData.map((blogpost, index) => {
                    return blogpost.featured ? (
                      <Row>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={4}
                          className="gx-0"
                        >
                          <Card className="blog-post-featured">
                            {blogpost.date}
                            <Card.Title>
                              <a
                                className="blog-link-featured"
                                href={blogpost.link}
                              >
                                {blogpost.title}
                              </a>
                            </Card.Title>
                            <Card.Body>
                              <button type="button" className="btn btn-light">
                                Read More
                              </button>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={8}
                          className="gx-0"
                        >
                          <Card className="blog-post-featured">
                            {blogpost.excerpt}
                          </Card>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    );
                  })
                }
              </Card>
            </Col>
          </Row>
          <Row>
            <Row>
              <h2 className="card-heading">news and updates</h2>
            </Row>
          </Row>
          <Row>
            {
              /* Output a row for each question and answer, with anchor link*/
              blogData.map((blogpost, index) => {
                return (
                  <Col xs={6} sm={6} md={6} lg={6} xl={4} className="gx-0">
                    <Card className="blog-post">
                      {blogpost.date}
                      <Card.Title>
                        <a className="blog-link" href={blogpost.link}>
                          {blogpost.title}
                        </a>
                      </Card.Title>
                    </Card>
                  </Col>
                );
              })
            }
          </Row>
          <Footer></Footer>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}
