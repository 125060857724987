import { Card, Col, Row, Container } from "react-bootstrap";

export default function DefaultPage() {
  return (
    <Container className="inside-container">
      <Row>
        <Col>
          <h2 style={{ marginBottom: ".8em" }}>Welcome back</h2>

          <Card className="project-card">
            <div className="project-name">Announcements</div>

            <p className="announcement-sub">Ongoing development</p>
            <p>
              Thank you for your patience as we continue to develop
              gearcalculators.com! We aim to continually improve the
              capabilities, functionality, and experience as you use the website
              and its calculators. We hope to minimize downtime or
              inaccessibility, and if you ever run into troubles, please send an
              email over to{" "}
              <a href="mailto:contact@evolventdesign.com">
                contact@evolventdesign.com
              </a>{" "}
              so we can look into it!
            </p>
          </Card>

          <Card className="project-card">
            <div className="project-name">News & Updates</div>

            <p className="announcement-sub">Free-Hobbing Calculator Added</p>
            <p>
              A{" "}
              <a className="announcement-link" href="/calc/free-hobbing">
                Free-Hobbing Calculator
              </a>{" "}
              has been added to help you cut worm gears
              using standard screw taps
            </p>
            <p className="announcement-date">February 5, 2023</p>
            <hr />
            <p className="announcement-sub">
              Gear Racks in .DXF and .STL Generators
            </p>
            <p>
              The{" "}
              <a className="announcement-link" href="/calc/dxf">
                .DXF Generator
              </a>{" "}
              and{" "}
              <a className="announcement-link" href="/calc/stl">
                .STL Generator
              </a>{" "}
              now support making gear racks
            </p>
            <p className="announcement-date">December 6, 2023</p>
            <hr />
            <p className="announcement-sub">Hardness Conversion Added</p>
            <p>
              The{" "}
              <a className="announcement-link" href="/calc/hardness">
                Hardness Conversion
              </a>{" "}
              calculator is now available under 'Shop References'
            </p>
            <p className="announcement-date">November 3, 2023</p>
            <hr />
            <p className="announcement-sub"> Gear Dimension Calculator</p>
            <p>
              The{" "}
              <a className="announcement-link" href="/calc/dimensions">
                Gear Dimension Calculator
              </a>{" "}
              will now flag whether your gear has a prime number of teeth
            </p>
            <p className="announcement-date">October 11, 2023</p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
