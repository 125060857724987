import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DecimalPlacesSelect,
  DPModuleInput,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorSpan({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-span").value = "";
    document.getElementById("input-shift").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Save with proejct name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    /*var numTeeth = parseFloat(document.getElementById('#numTeeth').value);
  var module = parseFloat(document.getElementById('#module').value);
  var pressureAngle = parseFloat(document.getElementById('#pressureAngle').value);
  var pinDiameter = parseFloat(document.getElementById('#pinDiameter').value);
  */

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    var teethToSpan = parseFloat(document.getElementById("input-span").value);
    var Xemax = document.getElementById("input-shift").value;

    logQA({
      page: "GCSspan",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      param5: Xemax,
      param6: teethToSpan,
      param7: radioValue,
    });

    if (isNaN(Xemax) || Xemax === "") {
      Xemax = 0;
    }

    var errorMessage = "";
    var okToCalc = true;

    if (!(Xemax >= -0.6 && Xemax <= 0.6)) {
      okToCalc = false;
      errorMessage += "Profile Shift must be between -0.6 and 0.6\n";
    }
    if (!(numTeeth >= 15 && numTeeth <= 250)) {
      okToCalc = false;
      errorMessage += "Number of teeth must be between 15 and 250\n";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage += "Number of teeth must be an integer\n";
    }
    if (!Number.isInteger(teethToSpan)) {
      okToCalc = false;
      errorMessage += "Number of teeth to span must be an integer\n";
    }
    if (!(module >= 0.1 && module <= 256)) {
      okToCalc = false;
      errorMessage += "Module/DP must be between 0.1 and 256\n";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage += "Pressure Angle must be between 1 and 45 degrees\n";
    }
    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }

    return false;
  }
  function calculateMOW() {
    var radioValue, modWord;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
      modWord = "Diametral Pitch";
    } else {
      radioValue = "mm";
      modWord = "Module";
    }
    var z = parseFloat(document.getElementById("input-numteeth").value);
    var m_n = parseFloat(document.getElementById("input-module").value);
    var m_nInput = m_n; // preserve the initially entered value
    var alpha_n_Degree = parseFloat(
      document.getElementById("input-pangle").value
    );
    var alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    // var unitWord = "mm";
    var Xemax = document.getElementById("input-shift").value;
    var Xemin = document.getElementById("input-shift").value;
    var teethToSpan = parseFloat(document.getElementById("input-span").value);
    var precision = document.getElementById("precision").value;

    var beta = 0; // placeholder for now

    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      //D_d = D_d * 25.4;
      // unitWord = "in";
    }
    ///BASIC GEAR DIMENSIONS
    // var P_nd = 25.4/m_n; // # normal diametral pitch (1/in)
    var pitchDia = m_n * z; // pitch diameter = module * num teeth

    var beta_B = Math.asin(Math.sin(beta) * Math.cos(alpha_n)); //# helix angle (= 0 for spur gears)
    var alpha_t = Math.atan(Math.tan(alpha_n) / Math.cos(beta)); //# transverse pressure angle at reference diameter

    var d = (Math.abs(z) * m_n) / Math.cos(beta); //# reference diameter
    var d_b = d * Math.cos(alpha_t); //# base diameter

    var S_nmax = m_n * (2 * Xemax * Math.tan(alpha_n) + Math.PI / 2); //# nominal normal circular tooth thickness at reference diameter, max
    var S_nmin = m_n * (2 * Xemin * Math.tan(alpha_n) + Math.PI / 2); //# nominal normal circular tooth thickness at reference diameter, minimum
    /// END BASIC GEAR DIMENSIONS

    /// ASSUMPTIONS
    var d_La = (Math.abs(z) + 2) * m_n * 0.99; //#tip involute limit diameter # ASSUME 99% of the tooth length is involute profile
    var d_Lf = d_b * 1.01; //# root involute limit diameter # ASSUME the involute profile starts at 1% greater than base diameter
    var f_alphaS = 0.125; // ASSUME the modification coefficient for span profile limits is 0.125 (midway between typical values of 0 and 0.25)
    ///  END ASSUMPTIONS

    // FOR PIN ONlY
    //D_dmax = z*m_n*Math.cos(alpha_n)/Math.cos(beta_B)/Math.cos(beta_B)*(Math.tan(alpha_KTmax)-Math.tan(Math.acos(d_b/d_La))) //# Max Pin Size (in mm)

    //4.7.8 Normal Pitch at the reference diameter
    var p_n = Math.PI * m_n;
    // 4.7.9 Normal Base Pitch
    var p_bn = p_n * Math.cos(alpha_n);

    // 8.4.2 Normal Base Tooth Thickness and Normal Base Pitch
    var s_bnmax =
      ((Math.cos(alpha_t) * Math.cos(beta_B)) / Math.cos(beta)) *
      (S_nmax + m_n * z * involute(alpha_t));
    var s_bnmin =
      ((Math.cos(alpha_t) * Math.cos(beta_B)) / Math.cos(beta)) *
      (S_nmin + m_n * z * involute(alpha_t));

    // 8.4.3.1 Check the Maximum and Minimum number of Teeth Spanned
    var k_max_a =
      1 +
      (Math.pow(Math.pow(d_La - f_alphaS * m_n, 2) - Math.pow(d_b, 2), 0.5) /
        Math.cos(beta_B) -
        s_bnmax) /
        p_bn;
    k_max_a = Math.ceil(k_max_a);

    var k_min_f =
      1 +
      (Math.pow(Math.pow(d_Lf - f_alphaS * m_n, 2) - Math.pow(d_b, 2), 0.5) /
        Math.cos(beta_B) -
        s_bnmin) /
        p_bn;
    k_min_f = Math.floor(k_min_f);
    k_min_f = Math.max(k_min_f, 1);

    // Note: this is an additional requirement for helical gears (k_max_b)

    //using number of teeth k
    var k = teethToSpan;
    var W_kmax = (k - 1) * p_bn + s_bnmax; // Span measurement limit over k measured teeth, max, mm

    // could include "Angle of Rock" measurements if required

    // 8.7 Tooth Thickness from span measurement

    ///////////////// SPAN
    //# Minimum pin size
    /*
//# Checking the minimum based on the root involute limit diameter

eta_bmax = (Math.PI-S_nmin/m_n-z*involute(alpha_t))/Math.abs(z) //# base space width half angle, max

alpha_KTLf = Math.tan(Math.acos(d_b/d_Lf))+z/Math.abs(z)*eta_bmax

DdLf = z*m_n*Math.cos(alpha_n)/Math.cos(beta_B)/Math.cos(beta_B)*(Math.tan(alpha_KTLf)-Math.tan(Math.acos(d_b/d_Lf)))
//# Checking the minimum based on pin being tangent at the tip diameter (that the pin protrudes for measurement)
//#for external gears
console.log("about to get DDA");
D_da = iterD_da() //#tip diameter of the gear, maximum
console.log(D_da);
//# Checking the minimum based on avoiding contact with the root
D_df = iterD_df()

//# Determining the actual minimum usable diameter
D_dmin = Math.max(DdLf,D_da,D_df)

// CALCULATE DIA FOR PITCH INTERSECTION
d_y = z*m_n;
eta_bavg = (Math.PI-(S_nmax+S_nmin)/(2*m_n)-z*involute(alpha_t))/Math.abs(z);
alpha_ktdy = Math.tan(Math.acos(d_b/d_y))+z*eta_bavg/Math.abs(z);

D_ddy = z*m_n*Math.cos(alpha_n)/Math.cos(beta_B)/Math.cos(beta_B)*(Math.tan(alpha_ktdy)-Math.tan(Math.acos(d_b/d_y)));
  
  */
    //# Make sure that the selected number of teeth to span is in range!
    var validPin, textToWrite;
    if (k <= k_max_a && k >= k_min_f && k >= 1) {
      validPin = true;
    } else {
      teethToSpan = "Out of range";
      validPin = false;
    }

    if (radioValue === "in") {
      //D_d = D_d/25.4;
    }

    var displayProjectName = getProjectNameFromID(project.project);
    // if the user is on "Free" tier, the gearname box won't appear and it will crash
    // default to blank if the input is not shown
    var displayGearName;
    if(document.getElementById("input-gearname")){
      displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";
    }
    else {
      displayGearName = ""; 
    }

    if (validPin === true) {
      textToWrite = `Inputs
Number of Teeth:\t${z}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Teeth to Span:\t${teethToSpan}
Profile Shift Coefficient:\t${Xemin}

Outputs
Span Measurement:\t${W_kmax.toFixed(precision)}\tmm\t${(W_kmax / 25.4).toFixed(
        precision
      )}\tin
Minimum Teeth to Span:\t${k_min_f}
Maximum Teeth to Span:\t${k_max_a}
Recommended Teeth to Span:\t${Math.round((k_min_f + k_max_a) / 2)}
\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0
`;
    } else {
      textToWrite = `Inputs
Number of Teeth:\t${z}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Teeth to Span:\t${teethToSpan}
Profile Shift Coefficient:\t${Xemin}

Outputs
Teeth to Span is out of range
Minimum Teeth to Span:\t${k_min_f}
Maximum Teeth to Span:\t${k_max_a}
Recommended Teeth to Span:\t${Math.round((k_min_f + k_max_a) / 2)}

\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0
`;
    }
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);

    return false;
  }

  function involute(x) {
    //expects x in radians
    return Math.tan(x) - x;
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Span</div>
        <p>
          Calculate size measurements for gears based on measuring across a
          fixed number of teeth (spanning these teeth).
        </p>
        <p>
          As gears grow larger, the span measurement may be easier for an
          operator, and doesn't require giant calipers.
        </p>
        <p>
          Input your gear parameters to find the acceptable range for the number
          of teeth to measure over, choose your number, then calculate the
          correct measurement to check out your gear.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth<span className="required">*</span>
                </label>
                <input className="inputbox" type="number" id="input-numteeth" />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pange">
                  Pressure Angle (degrees)
                  <span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values:
                      <br /> 20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-pangle" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shift">
                  Profile Shift Coefficient
                  <div className="calctooltip">
                    [?]<span className="tooltiptext">Unitless</span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-shift"
                  placeholder="Mfg Profile Shift"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-span">
                  Number of Teeth to Span<span className="required">*</span>
                </label>
                <input className="inputbox" type="number" id="input-span" />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/span-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>Results conform to ANSI/AGMA 2002-D19 published in December 2019</p>
        <p>
          Gear span measurement is easiest to perform with a caliper or
          micrometer that can "reach" into the spaces between the gear teeth.
          Make sure your tool comes in radially so the two points of contact
          with the gear teeth are on the same imaginary circle. The key is to
          ensure you aren't deep on one tooth and near the edge on the second.
        </p>
      </Card>
    </>
  );
}
