import React from "react";

import { resizeTextAnswer, logQA } from "./helpers";
import { DecimalPlacesSelect, ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorWormCarbide({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-units").value = "module";
    document.getElementById("input-diameter").value = "";
    document.getElementById("input-diameter").placeholder = "Diameter (mm)";
    document.getElementById("input-pitch").value = "";
    document.getElementById("input-pitch").placeholder = "Module (mm)";
    document.getElementById("input-pangle").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function unitChange() {
    if (document.getElementById("input-units").value === "module") {
      document.getElementById("input-pitch").placeholder = "Module (mm)";
      document.getElementById("input-diameter").placeholder = "Diameter (mm)";
    } else if (document.getElementById("input-units").value === "dp") {
      document.getElementById("input-pitch").placeholder =
        "Diametral Pitch (1/inch)";
      document.getElementById("input-diameter").placeholder = "Diameter (inch)";
    } else if (document.getElementById("input-units").value === "cp") {
      document.getElementById("input-pitch").placeholder =
        "Circular Pitch (inch)";
      document.getElementById("input-diameter").placeholder = "Diameter (inch)";
    }
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var validTest = true;

    var precision = parseInt(document.getElementById("precision").value);
    var units = document.getElementById("input-units").value;
    var pitch = parseFloat(document.getElementById("input-pitch").value);
    var pangle = parseFloat(document.getElementById("input-pangle").value);
    var diameter = parseFloat(document.getElementById("input-diameter").value);

    logQA({
      page: "GCSwormcarbide",
      param5: units,
      param6: pitch,
      param8: pangle,
      param9: diameter,
    });

    var errorMessage;
    if (pitch <= 0 || isNaN(pitch)) {
      validTest = false;
      errorMessage = "Pitch must be positive numbers";
    }

    if (isNaN(precision)) {
      validTest = false;
      errorMessage = "Number of decimal places must be an integer";
    }

    if (pangle <= 0 || isNaN(pangle)) {
      validTest = false;
      errorMessage = "Pressure angle must be positive numbers";
    }

    var pitchInput = pitch;
    var diameterInput = diameter;
    var diameterUnit;
    var pitchWord;

    // NOTE: Calculator was provided in Diametral Pitch (DP), will convert to that for simplicity.
    if (validTest) {
      if (units === "module") {
        pitchWord = "Module";
        diameterUnit = "mm";
        pitch = 25.4 / pitch;
        diameter = diameter / 25.4; // calculator is based on inches!
      }
      if (units === "dp") {
        pitchWord = "Diametral Pitch";
        diameterUnit = "in";
      }
      if (units === "cp") {
        pitchWord = "Circular Pitch";
        diameterUnit = "in";
        pitch = Math.PI / pitch;
      }
    } else {
      setTextAreaText("Error\n" + errorMessage);
      resizeTextAnswer("Error\n" + errorMessage);

      return;
    }

    let tpi = pitch / Math.PI;
    let axialPitch = 1 / tpi;
    let a_e = axialPitch / Math.PI;
    let b_e = (a_e * 6) / 5;
    let C = b_e / Math.cos((pangle * Math.PI) / 180);
    let A = Math.sqrt(C * C - b_e * b_e);
    let tip_flat = axialPitch / 2 - 2 * A;

    // Calculate Lead Angle
    let leadAngle = Math.atan(1 / (diameter * pitch));
    let leadAngleDeg = (leadAngle * 180) / Math.PI;
    let degrees = Math.floor(leadAngleDeg);
    let minutes = 60 * (leadAngleDeg - degrees);
    let minutesF = Math.floor(minutes);
    let seconds = Math.floor(60 * (minutes - minutesF));

    var textToWrite = `Inputs
${pitchWord}:\t${pitchInput}
Pressure Angle: ${pangle} degrees
Effective Diameter:\t${diameterInput}\t${diameterUnit}

Outputs
Tip Flat:\t${(25.4 * tip_flat).toFixed(precision)}\tmm\t${tip_flat.toFixed(
      precision
    )}\tin
a_e+b_e:\t${(25.4 * (a_e + b_e)).toFixed(precision)}\tmm\t${(a_e + b_e).toFixed(
      precision
    )}\tin
Worm Lead Angle:\t${leadAngleDeg.toFixed(precision)}\tdeg
Worm Lead Angle:\t${degrees}\u00B0${minutesF}'${seconds}"

GCS0`;

    //textToWrite = "Inputs\n" + inputText + ":\t" + inputValue.toFixed(precision) + "\t" + inputUnits + "\n\nOutputs\n" + "Module:\t" + oModule.toFixed(precision) + "\tmm\nDiametral Pitch:\t" +oDP.toFixed(precision) + "\t1/in\nCircular Pitch: \t" + oCP.toFixed(precision) + "\tin" + "\nThreads per Inch: \t" + oTPI.toFixed(precision) + "\t1/in" ;
    //textToWrite = textToWrite + "\n\n\u00A9 " + new Date().getFullYear() + " Evolvent Design,\t" + formatDate(new Date()) + "\nGCS0";
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }
  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Turning Worms on a Lathe</div>
        <p>
          Calculate the geometry of a carbide cutting tool to cut a worm gear
          using a lathe.
        </p>
        <p>
          The shape is a trapezoid: the angles match the pressure angle, and the
          tip flat is a function of the pitch and effective diameter of the worm
          gear.
        </p>
        <p>
          Larger diameters will give you smaller lead angles while small
          diameters will give you larger lead angles. We recommend keeping the
          lead angle less than 6 degrees to maintain strength in the cutting
          tool.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-units">Units</label>
                <select
                  className="inputbox"
                  name="input-units"
                  id="input-units"
                  onChange={unitChange}
                >
                  <option value="module">Module (mm)</option>
                  <option value="dp">Diametral Pitch</option>
                  <option value="cp">Circular Pitch</option>
                </select>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pitch">
                  Pitch
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Module is in millimeters, DP&CP are in 1/inch
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-pitch"
                  size="35"
                  placeholder="Module (mm)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pangle">Pressure Angle</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-pangle"
                  size="35"
                  placeholder="Pressure Angle (degrees)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-diameter">
                  Effective Diameter
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Module is in millimeters, DP&CP are in inch
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-diameter"
                  size="35"
                  placeholder="Effective diameter (mm)"
                />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/wormcarbide-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          The shape of the resulting worm and how close it approximates the
          involute is interestingly quite good for smaller diameter worms using
          higher ratios greater than 36. Variations start to become apparent
          when the ratios are lower and the diameter of the worm is above 3
          inches.{" "}
        </p>
        <p>
          <Image src="/calc-images/wormcarbide-2.png" fluid />
          <br />
        </p>
        <p>
          Manual and CNC operators alike need watch out in terms of their method
          to incrementally plunge into the material. Manual operators can align
          their toolpost to the pressure angle 14.5 or 20 usually instead of 30
          degrees for threads and make adjustments and plunges appropriately.
          CNC operators will need to plunge directly as no known Fanuc canned
          threading program can be adjusted in terms of lead angle; they are all
          stuck at 30 degrees.
        </p>
      </Card>
    </>
  );
}
