import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
  SaveButtonBlock,
  ScrollAnchorTop,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorDimHelix({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  // Common calculator helper functions
  var radioValue;
  var unitWord;
  var ODIDWord = "OD";

  function isPrime(number) {
    var maxTest = parseInt(Math.sqrt(number) + 1);
    var primeNumber = true;
    for (let i = 2; i < maxTest; i++) {
      if (number % i === 0) {
        primeNumber = false;
        break;
      }
    }
    return primeNumber;
  }

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-shift").value = "";
    document.getElementById("input-helical").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project name
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  // Change the placeholder depending on the user's selection
  var z;
  var m_n;
  var alpha_n_Degree;
  var alpha_n; //= parseFloat(alpha_n_Degree)*Math.PI/180;
  var precision;
  var shift;
  var beta_m;
  var beta_m_Degree;

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    precision = document.getElementById("precision").value;
    var beta_m_Degree = document.getElementById("input-helical").value;

    logQA({
      page: "GCSdimspur",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      param7: unitWord,
    });

    var errorMessage = "Error";
    var okToCalc = true;

    // if (
    //   document.getElementsByName("input-type")[0].checked ===
    //   document.getElementsByName("input-type")[1].checked
    // ) {
    //   //Both Internal and External are the same (checked or unchecked)
    //   okToCalc = false;
    //   errorMessage = "Gear must be either Internal or External";
    // }

    if (!(numTeeth >= 6 && numTeeth <= 1000)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of teeth must be between 6 and 1000";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage = "Number of teeth must be an integer";
    }
    if (!(module >= 0.1 && module <= 300)) {
      okToCalc = false;
      errorMessage = errorMessage + "\nModule/DP must be between 0.1 and 300";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nPressure Angle must be between 1 and 45 degrees";
    }
    if (!(beta_m_Degree >= 0 && beta_m_Degree <= 90)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nHelix Angle must be between 0 and 90 degrees";
    }
    if (!(precision >= 0 && precision <= 8)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of Decimal Places must be between 0 and 8";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer();
    }
  }


  function involute(x) {
    //expects x in radians
    return Math.tan(x) - x;
  }

  function calculateMOW() {
    z = parseFloat(document.getElementById("input-numteeth").value);
    m_n = parseFloat(document.getElementById("input-module").value);
    alpha_n_Degree = parseFloat(document.getElementById("input-pangle").value);
    beta_m_Degree = parseFloat(document.getElementById("input-helical").value);
    alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    beta_m = (parseFloat(beta_m_Degree) * Math.PI) / 180;
    precision = document.getElementById("precision").value;
    shift = parseFloat(document.getElementById("input-shift").value);


    if(isNaN(shift)){
      shift = 0;
    }
    if(isNaN(beta_m) || !beta_m){
      beta_m = 0;
    }

    
    let shiftInput = shift;

    // Rough fix to compile in React
    let gearType;
    let modWord;
    let baseCircle = 0;
    let tipDiameter;
    let textToWrite;

    let primeTeeth = isPrime(z);

    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }


    // Standard only provides for external gears
    gearType = "External";
    ODIDWord = "OD";
    // if (document.getElementsByName("input-type")[1].checked) {
    //   //check if the gear is internal
    //   gearType = "Internal";
    //   ODIDWord = "ID";
    // } else {
    //   gearType = "External";
    //   ODIDWord = "OD";
    // }

    var m_nInput = m_n;
    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      unitWord = "inches";
      modWord = "Diametral Pitch";
    } else {
      unitWord = "millimeters";
      modWord = "Module";
    }

    // Gear Dimensions (per AGMA 1103-H07)
    // Calculations done in mm

    // GCP recreation (for spur):
    // Input: Module, pressure angle, number of teeth, and 2.25 depth system
    // Calculate then revise addendum then reset whole depth

    //let m_t = m_n; // For spur gears, normal module = transverse module
    let alpha_0 = Math.atan(Math.tan(alpha_n)/Math.cos(beta_m)); // profile angle, transverse


    let profileShift = shift * m_n;
    // Tooth proportions
    let addendum = m_n;
    let dedendum = 1.2*m_n + 0.05;
    let workingDepth = 2 * m_n;
    let wholeDepth = 2.2*m_n + 0.05;
    let clearance = 0.2*m_n + 0.05; // standard
    let filletRadius = clearance / (1-Math.sin(alpha_n));
    let toothThickness = Math.PI * m_n /2; // at standard pitch diameter; does not account for any thinning for backlash
    // Formulas
    let circularPitch = Math.PI * m_n; // normal
    let pitchDiameter = z * m_n / Math.cos(beta_m); // standard
    let outsideDiameter = (z*m_n)/Math.cos(beta_m) + 2 * m_n;
    let rootDiameter = (z * m_n)/Math.cos(beta_m) - 2.4 * m_n - 0.100;

    let enlargementRec = m_n*(1.05 - 0.5*z*Math.sin(alpha_0)*(Math.sin(alpha_0)-Math.cos(alpha_0)*Math.tan(Math.PI*5/180))); // mm
    let enlargementCoeff = enlargementRec / m_n; // proposed based on 5 degree criteria
    let toothThicknessMod = 2*shift*Math.tan(alpha_0);
    let addendumMod = shift;

    let alpha_t = Math.atan(Math.tan(alpha_n)/Math.cos(beta_m));
    let enlargementCriteria = Math.ceil(2.1*Math.cos(beta_m)/(Math.sin(alpha_t)*(Math.sin(alpha_t)-Math.cos(alpha_t)*Math.tan(5*Math.PI/180))));
    

    // Make adjustments depending on enlargement
    let outsideDiameterEnl = outsideDiameter + m_n*2*addendumMod;
    let addendumEnl = m_n*(1+addendumMod);
    let normalToothThicknessEnl = m_n*(Math.PI/2 + toothThicknessMod);
    let transToothThicknessEnl = normalToothThicknessEnl / Math.cos(beta_m);
    let rootDiameterEnl = rootDiameter + 2 * m_n * shift; //m_n*(z-2.4+2*shift) - .1;

    let alpha_e = Math.acos(z*m_n*Math.cos(alpha_0)/outsideDiameterEnl);
    let topLandEnl = outsideDiameterEnl*(normalToothThicknessEnl/m_n/z+involute(alpha_0)-involute(alpha_e));

    var undercutThreshold = 2 / Math.sin(alpha_0) / Math.sin(alpha_0);
    var undercutRisk = "No";
    if (z <= undercutThreshold) {
      undercutRisk = "Yes";
    }

    var displayProjectName = getProjectNameFromID(project.project);
    var displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";

    textToWrite = `Inputs
Number of Teeth:\t${z}
${modWord}:\t${m_nInput}
Pressure Angle:\t${alpha_n_Degree}\tdegrees
Helical Angle :\t${beta_m_Degree}\tdegrees
Profile Shift Coefficient:\t${shift}

Outputs
Entered PSC:  \t${shiftInput.toFixed(precision)}
Proposed PSC: \t${enlargementCoeff > 0 ? enlargementCoeff.toFixed(precision) : "N/A"}
Profile Shift:\t${profileShift.toFixed(precision)}\tmm\t${(
      profileShift / 25.4
    ).toFixed(precision)}\tin

Outside Diameter:\t${outsideDiameterEnl.toFixed(precision)}\tmm\t${(
      outsideDiameterEnl / 25.4
    ).toFixed(precision)}\tin
Pitch Diameter:  \t${pitchDiameter.toFixed(precision)}\tmm\t${(
      pitchDiameter / 25.4
    ).toFixed(precision)}\tin
Root Diameter:   \t${rootDiameterEnl.toFixed(precision)}\tmm\t${(
      rootDiameterEnl / 25.4
    ).toFixed(precision)}\tin

Addendum:            \t${addendumEnl.toFixed(precision)}\tmm\t${(
      addendumEnl / 25.4
    ).toFixed(precision)}\tin
Dedendum:            \t${dedendum.toFixed(precision)}\tmm\t${(
      dedendum / 25.4
    ).toFixed(precision)}\tin
Working Depth:       \t${workingDepth.toFixed(precision)}\tmm\t${(
      workingDepth / 25.4
    ).toFixed(precision)}\tin
Whole Depth:         \t${wholeDepth.toFixed(precision)}\tmm\t${(
      wholeDepth / 25.4
    ).toFixed(precision)}\tin
Circular Pitch:      \t${circularPitch.toFixed(precision)}\tmm\t${(
      circularPitch / 25.4
    ).toFixed(precision)}\tin
Tooth Thickness (n): \t${normalToothThicknessEnl.toFixed(precision)}\tmm\t${(
      normalToothThicknessEnl / 25.4
    ).toFixed(precision)}\tin
Tooth Thickness (t): \t${transToothThicknessEnl.toFixed(precision)}\tmm\t${(
      transToothThicknessEnl / 25.4
    ).toFixed(precision)}\tin
Top Land:            \t${topLandEnl.toFixed(precision)}\tmm\t${(
      topLandEnl / 25.4
    ).toFixed(precision)}\tin

Small Top Land:    \t${topLandEnl < 0.275*m_n ? "Yes" :"No"}
Enlargement (min): \t${enlargementCriteria}
Undercut:          \t${undercutRisk}
Prime:             \t${primeTeeth ? "Yes" : "No"}
\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Fine Dimensions (Helix)</div>
        <div>
          Calculate the geometry of fine-pitch spur gears per <i>AGMA 1103-H07</i>, including the ability to add profile shifts.
          Positive shifts are typically added to pinions to increase strength, and equal, but negative shifts are added to gears in order to maintain the same center distance
          <br /><br />

          <p>Positive profile shifts are recommended:</p><ul>
            <li>between 11-50 teeth at 14.5º pressure angle</li>
            <li>between 9-23 teeth at 20º pressure angle</li>
            <li>between 8-15 teeth at 25º pressure angle</li>
          </ul>
          <p>
            <i>AGMA 1103-H07</i> is applicable from 0.2 to 1.25 normal module, or 120 to 20 DP.
          </p>
          <p>
            Flags notify when your gear has a prime number of teeth, or risk of
            undercut in the standard gear tooth profile.
          </p>
        
        </div>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />
              <div hidden="true">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label className="unitslabel">Type</label>
                  <div>
                    <input
                      className="radioButton"
                      type="radio"
                      id="input-type-ext"
                      value="ext"
                      name="input-type"
                      defaultChecked
                    />
                    <label className="radioLabel" htmlFor="input-type-ext">
                      External
                    </label>
                  </div>
                  <div>
                    <input
                      className="radioButton"
                      type="radio"
                      id="input-type-int"
                      value="int"
                      name="input-type"
                    />
                    <label className="radioLabel" htmlFor="input-type-int">
                      Internal
                    </label>
                  </div>
                </div>
                </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="25"
                />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pange">
                  Pressure Angle, normal (degrees)<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pangle"
                  size="25"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-helical">
                  Helical Angle (degrees)
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">Default (Spur): 0</span>
                  </div>
                </label>
                <input
                  defaultValue={0}
                  className="inputbox"
                  type="number"
                  id="input-helical"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shift">
                  Profile Shift Coefficient
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Unitless
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-shift"
                  size="25"
                />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/dimensions-spur.png" fluid />
            <br />
            <p>A positive profile shift increases the outer diameter and tooth thickness of this pinion</p>
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          <span className="term-emphasis">Common modules</span> are: 1.25, 1.0, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2
        </p>
        <p>
          <span className="term-emphasis">Base Circle</span> is not a physical
          diameter on the gear, but it is the theoretical diameter from which
          the true involute emanates.
        </p>
        <p>
          <span className="term-emphasis">Enlargement (min)</span> indicates the minimum number of teeth where an enlargement 
          (profile shift) factor is no longer recommended by <i>AGMA 1103-H07</i>
        </p>

        <p>
          <span className="term-emphasis">Small Top Land </span> indicates whether the top land is smaller 
          than the amount (0.275*module) recommended by <i>AGMA 1103-H07</i>
        </p>
        <p>
          <span className="term-emphasis">Undercut</span> flag indicates whether
          an undercut (when the involute profile cuts into the root of the gear
          tooth) is present in the standard (unshifted) gear. This typically occurs on low tooth count gears, but
          is also a function of pressure angle. An undercut can greatly reduce
          the strength of a gear, and should generally be avoided. Additional
          design modifications can also help minimize undercuts.
        </p>
        <p>
          <span className="term-emphasis">Prime</span> flag indicates whether
          the tooth count is a prime number.
        </p>
      </Card>
    </>
  );
}
