import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "./stripeApp.css";

import { useNavigate } from "react-router-dom";
import styles from "./purchase-sub.module.css";
import ComparePlansTable from "./outside/plan-table";

export default function SubscribePage() {
  const [prices, setPrices] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchPrices = async () => {
      const { prices } = await fetch("/getprices").then((r) => r.json());
      setPrices(prices);
    };
    fetchPrices();
  }, []);

  const createSubscription = async (priceId) => {
    // once selected, send the priceId of the selection, and user information to
    // a) create a customer in Stripe and b) create the subscription for the priceId

    // Get the username and idToken. Both will be sent and username will be matched against verified JWT on the server
    var tempUserInfo, tempUsername, idToken, refreshToken, accessToken;
    try {
      // Check if there is an existing record, and if so, pull in its data
      tempUserInfo = localStorage.getItem("currentUser");
      tempUserInfo = JSON.parse(tempUserInfo);
      tempUsername = tempUserInfo.username;
      idToken = tempUserInfo.idToken;
      refreshToken = tempUserInfo.refreshToken;
      accessToken = tempUserInfo.accessToken;
    } catch {
      tempUsername = "suspectuser";
      idToken = "";
      refreshToken = "";
      accessToken = "";
    }

    const { subscriptionId, clientSecret, totalAmount, totalFrequency } =
      await fetch("/create-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: priceId,
          username: tempUsername,
          idtoken: idToken,
          refreshtoken: refreshToken,
          accesstoken: accessToken,
        }),
      }).then((r) => r.json());

    setSubscriptionData({
      subscriptionId,
      clientSecret,
      totalAmount,
      totalFrequency,
    });
  };

  if (subscriptionData) {
    // Subscription was created! Now we can redirect the user to have them pay for it
    // a  la confirm
    setTimeout(function () {
      navigate("/payment", { state: subscriptionData });
    }, 100);
  }

  return (
    <Card className="project-card">
      <div className="project-name">
        Upgrade Plan
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
      </div>

      <div className={styles.list}>
        {prices.map((price) => {
          return (
            <div key={price.id} className={styles.listItem}>
              <h4>{price.product.name}</h4>

              <div>
                ${price.unit_amount / 100}
                {price.type === "recurring"
                  ? " / " + price.recurring.interval
                  : "fixed"}
              </div>

              <button
                onClick={() => createSubscription(price.id)}
                className="btn-sm"
              >
                Select
              </button>
            </div>
          );
        })}
      </div>
      <hr style={{ marginBottom: "40px" }} />
      <ComparePlansTable />
    </Card>
  );
}
