import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import {
  UnitsRadio,
  DecimalPlacesSelect,
  DPModuleInput,
  ButtonBlockTextArea,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorGearStrength({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-rpm").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-numteethgear").value = "";
    document.getElementById("input-I").value = "";
    document.getElementById("input-Sac").value = "";
    document.getElementById("input-Cv").value = "";
    document.getElementById("input-face").value = "";
    document.getElementById("input-Cm").value = "";
    document.getElementById("input-Co").value = "";
    document.getElementById("input-Cp").value = "";
    document.getElementById("input-CL").value = "";
    document.getElementById("input-CR").value = "";
    document.getElementById("input-Cs").value = "";
    document.getElementById("input-Cf").value = "";
    document.getElementById("input-CH").value = "";
    document.getElementById("input-CT").value = "";

    document.getElementById("input-pangdrop").value = "default";
    document.getElementById("input-sacdrop").value = "default";
    document.getElementById("dynamicdrop").value = "default";
    document.getElementById("distdrop").value = "default";
    document.getElementById("powersource").value = "default";
    document.getElementById("loadshock").value = "default";
    document.getElementById("pmatdrop").value = "default";
    document.getElementById("gmatdrop").value = "default";
    document.getElementById("lifecycles").value = "default";
    document.getElementById("safetydrop").value = "default";

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function updateAll() {
    updateOverload();
    updateGeometry();
    updateElastic();
    updateSAC();
    updateLife();
    updateSafety();
    updateDynamic();
    updateDist();
    return false;
  }

  function updateOverload() {
    let currentPower = document.getElementById("powersource").value;
    let currentLoad = document.getElementById("loadshock").value;

    let output = 0;
    switch (currentPower) {
      case "uniform":
        switch (currentLoad) {
          case "uniform":
            output = 1;
            break;
          case "moderate":
            output = 1.25;
            break;
          case "heavy":
            output = 1.75;
            break;
          default:
            output = 0;
            break;
        }
        break;
      case "light":
        switch (currentLoad) {
          case "uniform":
            output = 1.25;
            break;
          case "moderate":
            output = 1.5;
            break;
          case "heavy":
            output = 2.0;
            break;
          default:
            output = 0;
            break;
        }
        break;
      case "medium":
        switch (currentLoad) {
          case "uniform":
            output = 1.5;
            break;
          case "moderate":
            output = 1.75;
            break;
          case "heavy":
            output = 2.25;
            break;
          default:
            output = 0;
            break;
        }
        break;
      default:
        output = 1;
        break;
    }

    if (output > 0) {
      document.getElementById("input-Co").value = output;
    }
  }

  function updateGeometry() {
    var numTeethGear = parseFloat(
      document.getElementById("input-numteethgear").value
    );
    var numTeethPinion = parseFloat(
      document.getElementById("input-numteeth").value
    );
    var pangle = parseFloat(document.getElementById("input-pangdrop").value);

    if (numTeethGear && numTeethPinion && pangle) {
      var gearRatio =
        Math.max(numTeethGear, numTeethPinion) /
        Math.min(numTeethGear, numTeethPinion);
      // Imported the charts from section 13-24 in "Gear Handbook" - Dudley
      // Data is the Geometry Factor, by Gear Ratio in 0.5 increments (depends on pressure angle)
      const geom145 = [
        0.06, 0.07, 0.08, 0.088, 0.09, 0.093, 0.095, 0.097, 0.1, 0.101, 0.102,
        0.103, 0.104, 0.105, 0.106, 0.107, 0.108, 0.109, 0.11,
      ];
      const geom20 = [
        0.08, 0.1, 0.11, 0.116, 0.12, 0.124, 0.128, 0.132, 0.135, 0.138, 0.139,
        0.14, 0.141, 0.142, 0.143, 0.144, 0.145, 0.146, 0.147,
      ];

      // Need to find the appropriate index. GR 1.0 = 0, GR 10 = 18
      var index = Math.floor(gearRatio * 2 - 2);
      var result;
      if (index > 18) {
        index = 18;
      }

      if (pangle === 14.5) {
        result = geom145[index];
      } else {
        result = geom20[index];
      }
      document.getElementById("input-I").value = result;
    }
  }

  function updateElastic() {
    var matPinion = document.getElementById("pmatdrop").value;
    var matGear = document.getElementById("gmatdrop").value;
    var output = 0;
    if (matPinion === "default" || matGear === "default") {
      document.getElementById("input-Cp").value =
        "Input both gear/pinion material classes";
    } else {
      switch (matPinion) {
        case "steel":
          switch (matGear) {
            case "steel":
              output = 2300;
              break;
            case "castiron":
              output = 2000;
              break;
            case "albronze":
              output = 1950;
              break;
            case "snbronze":
              output = 1900;
              break;
            default:
              output = 0;
              break;
          }
          break;
        case "castiron":
          switch (matGear) {
            case "steel":
              output = 2000;
              break;
            case "castiron":
              output = 1800;
              break;
            case "albronze":
              output = 1800;
              break;
            case "snbronze":
              output = 1750;
              break;
            default:
              output = 0;
              break;
          }
          break;
        case "albronze":
          switch (matGear) {
            case "steel":
              output = 1950;
              break;
            case "castiron":
              output = 1800;
              break;
            case "albronze":
              output = 1750;
              break;
            case "snbronze":
              output = 1700;
              break;
            default:
              output = 0;
              break;
          }
          break;
        case "snbronze":
          switch (matGear) {
            case "steel":
              output = 1900;
              break;
            case "castiron":
              output = 1750;
              break;
            case "albronze":
              output = 1700;
              break;
            case "snbronze":
              output = 1650;
              break;
            default:
              output = 0;
              break;
          }
          break;
        default:
          output = 0;
      }
      if (output > 0) {
        document.getElementById("input-Cp").value = output;
      }
    }
  }

  function updateSAC() {
    var getSac = document.getElementById("input-sacdrop").value;

    if (!(getSac === "default")) {
      document.getElementById("input-Sac").value = getSac;
    }
  }

  function updateLife() {
    var getLife = document.getElementById("lifecycles").value;

    if (!(getLife === "default")) {
      document.getElementById("input-CL").value = getLife;
    }
  }
  function updateSafety() {
    var getSafety = document.getElementById("safetydrop").value;

    if (!(getSafety === "default")) {
      document.getElementById("input-CR").value = getSafety;
    }
  }

  function updateDynamic() {
    // Update Dynamic Factor

    // Get curve

    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var curveNum = document.getElementById("dynamicdrop").value;

    var gearModule = parseFloat(document.getElementById("input-module").value);
    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);

    let output = 0;

    if (radioValue === "in") {
      gearModule = 25.4 / gearModule;
    }

    var pitchDiameter = (gearModule * numTeeth) / 25.4; // inches
    var pitchLineVelocity = (pitchDiameter * Math.PI) / 12; // feet per minute

    switch (curveNum) {
      case "curve1":
        output = 1;
        break;
      case "curve3":
        output = 78 / (78 + Math.sqrt(pitchLineVelocity));
        break;
      case "curve4":
        output = 50 / (50 + Math.sqrt(pitchLineVelocity));
        break;
      case "curve5":
        output = (0.8 * 50) / (50 + Math.sqrt(pitchLineVelocity));
        break;
      default:
        output = 0;
    }

    if (output > 0) {
      document.getElementById("input-Cv").value = output.toFixed(3);
    }
  }

  function updateDist() {
    // Update Load Distribution Factor
    // Get support condition
    var supportCond = document.getElementById("distdrop").value;

    var Ftemp = parseFloat(document.getElementById("input-face").value);

    let output = 0;
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    if (radioValue === "in") {
      Ftemp = Ftemp * 25.4;
    }

    if (isNaN(Ftemp) || Ftemp === undefined || Ftemp === 0) {
      document.getElementById("input-Cm").value = "Face width required";
      return false;
    }
    Ftemp = Ftemp / 25.4; // convert face width to inches

    switch (supportCond) {
      case "rigid":
        if (Ftemp <= 2) {
          output = 1.3;
        }
        if (Ftemp > 2 && Ftemp <= 6) {
          output = 1.4;
        }
        if (Ftemp > 6 && Ftemp <= 9) {
          output = 1.5;
        }
        if (Ftemp > 9) {
          output = 1.8;
        }
        break;
      case "lessrigid":
        if (Ftemp <= 2) {
          output = 1.6;
        }
        if (Ftemp > 2 && Ftemp <= 6) {
          output = 1.7;
        }
        if (Ftemp > 6 && Ftemp <= 9) {
          output = 1.8;
        }
        if (Ftemp > 9) {
          output = 2.2;
        }
        break;
      case "notfull":
        output = 2.2;
        break;
      default:
        output = 0;
    }

    if (output > 0) {
      document.getElementById("input-Cm").value = output.toFixed(1);
    }
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var RPM = parseFloat(document.getElementById("input-rpm").value);
    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var numTeethGear = parseFloat(
      document.getElementById("input-numteethgear").value
    );
    var gearModule = parseFloat(document.getElementById("input-module").value);
    var F = parseFloat(document.getElementById("input-face").value);
    var Sac = parseFloat(document.getElementById("input-Sac").value);
    //d = parseFloat(document.getElementById('input-d').value);
    var I = parseFloat(document.getElementById("input-I").value);

    var Cv = parseFloat(document.getElementById("input-Cv").value);
    var Cs = parseFloat(document.getElementById("input-Cs").value);

    var Cm, Cf, Co, Cp, CL, CH, CT, CR;

    if (isNaN(Cs)) {
      Cs = 1;
    } // Fill in default if not provided
    Cm = parseFloat(document.getElementById("input-Cm").value);
    Cf = parseFloat(document.getElementById("input-Cf").value);
    if (isNaN(Cf)) {
      Cf = 1;
    } // Fill in default if not provided
    Co = parseFloat(document.getElementById("input-Co").value);
    Cp = parseFloat(document.getElementById("input-Cp").value);
    CL = parseFloat(document.getElementById("input-CL").value);
    CH = parseFloat(document.getElementById("input-CH").value);
    if (isNaN(CH)) {
      CH = 1;
    } // Fill in default if not provided
    CT = parseFloat(document.getElementById("input-CT").value);
    if (isNaN(CT)) {
      CT = 1;
    } // Fill in default if not provided
    CR = parseFloat(document.getElementById("input-CR").value);
    var precision = parseFloat(document.getElementById("precision").value);

    logQA({
      page: "GCSstrength",
      numteeth: numTeeth,
      module: gearModule,
      param5: RPM,
      param6: F,
      param7: radioValue,
    });

    var errorMessage = "Error:\n";
    var okToCalc = true;

    if (isNaN(RPM) || !(RPM > 0)) {
      okToCalc = false;
      errorMessage += "Pinion RPM must be greater than 0\n";
    }
    if (isNaN(numTeeth) || !(numTeeth > 0)) {
      okToCalc = false;
      errorMessage += "Pinion tooth count must be greater than 0\n";
    }
    if (isNaN(numTeethGear) || !(numTeethGear > 0)) {
      okToCalc = false;
      errorMessage += "Gear tooth count must be greater than 0\n";
    }
    if (isNaN(gearModule) || !(gearModule > 0)) {
      okToCalc = false;
      errorMessage += "Module/DP must be greater than 0\n";
    }
    if (isNaN(F) || !(F > 0)) {
      okToCalc = false;
      errorMessage += "Face width must be greater than 0\n";
    }
    if (isNaN(Sac) || !(Sac > 0)) {
      okToCalc = false;
      errorMessage += "Allowable stress must be greater than 0\n";
    }
    if (isNaN(I) || !(I > 0)) {
      okToCalc = false;
      errorMessage += "Geometry factor must be greater than 0\n";
    }
    if (isNaN(Cv) || !(Cv > 0)) {
      okToCalc = false;
      errorMessage += "Dynamic factor must be greater than 0\n";
    }
    if (isNaN(Cs) || !(Cs > 0)) {
      okToCalc = false;
      errorMessage += "Size factor must be greater than 0\n";
    }
    if (isNaN(Cm) || !(Cm > 0)) {
      okToCalc = false;
      errorMessage += "Load-distribution factor must be greater than 0\n";
    }
    if (isNaN(Cf) || !(Cf > 0)) {
      okToCalc = false;
      errorMessage += "Surface-condition factor must be greater than 0\n";
    }
    if (isNaN(Co) || !(Co > 0)) {
      okToCalc = false;
      errorMessage += "Overload factor must be greater than 0\n";
    }
    if (isNaN(Cp) || !(Cp > 0)) {
      okToCalc = false;
      errorMessage += "Elastic coefficient must be greater than 0\n";
    }
    if (isNaN(CL) || !(CL > 0)) {
      okToCalc = false;
      errorMessage += "Life factor must be greater than 0\n";
    }
    if (isNaN(CH) || !(CH > 0)) {
      okToCalc = false;
      errorMessage += "Hardness factor must be greater than 0\n";
    }
    if (isNaN(CT) || !(CT > 0)) {
      okToCalc = false;
      errorMessage += "Temperature factor must be greater than 0\n";
    }
    if (isNaN(CR) || !(CR > 0)) {
      okToCalc = false;
      errorMessage += "Safety factor must be greater than 0\n";
    }
    if (isNaN(precision) || !(precision >= 0)) {
      okToCalc = false;
      errorMessage += "Precision must be 0 or greater\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }

    return false;
  }
  function calculateMOW() {
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    // Based on "Gear Handbook" - Dudley, Section 13-1

    // get all units in metric (mm,module)
    var RPM = parseFloat(document.getElementById("input-rpm").value);
    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var numTeethGear = parseFloat(
      document.getElementById("input-numteethgear").value
    );
    var gearModule = parseFloat(document.getElementById("input-module").value);
    var F = parseFloat(document.getElementById("input-face").value);
    var Sac = parseFloat(document.getElementById("input-Sac").value);
    //d = parseFloat(document.getElementById('input-d').value);
    var I = parseFloat(document.getElementById("input-I").value);

    var Cv = parseFloat(document.getElementById("input-Cv").value);
    var Cs = parseFloat(document.getElementById("input-Cs").value);

    var Cm, Cf, Co, Cp, CL, CH, CT, CR;

    if (isNaN(Cs)) {
      Cs = 1;
    } // Fill in default if not provided
    Cm = parseFloat(document.getElementById("input-Cm").value);
    Cf = parseFloat(document.getElementById("input-Cf").value);
    if (isNaN(Cf)) {
      Cf = 1;
    } // Fill in default if not provided
    Co = parseFloat(document.getElementById("input-Co").value);
    Cp = parseFloat(document.getElementById("input-Cp").value);
    CL = parseFloat(document.getElementById("input-CL").value);
    CH = parseFloat(document.getElementById("input-CH").value);
    if (isNaN(CH)) {
      CH = 1;
    } // Fill in default if not provided
    CT = parseFloat(document.getElementById("input-CT").value);
    if (isNaN(CT)) {
      CT = 1;
    } // Fill in default if not provided
    CR = parseFloat(document.getElementById("input-CR").value);
    var precision = parseFloat(document.getElementById("precision").value);

    let unitWord = "mm";
    let moduleWord = "Module";
    var moduleInput = gearModule;
    var faceWidthGearInput = F;

    if (radioValue === "in") {
      gearModule = 25.4 / gearModule;
      F = F * 25.4;
      unitWord = "in";
      moduleWord = "Diametral Pitch";
    }

    F = F / 25.4; //inches
    var pitchDiameter = (gearModule * numTeeth) / 25.4; // inches
    var pitchLineVelocity = (pitchDiameter * Math.PI) / 12; // feet per minute

    let outputPower =
      ((((RPM * F) / 126000) * I * Cv) / Cs / Cm / Cf / Co) *
      ((Sac * pitchDiameter * CL * CH) / Cp / CT / CR) ** 2;

    var textToWrite = "Inputs";
    textToWrite += "\nPinion RPM:\t" + RPM;
    textToWrite += "\nNumber of Teeth (Pinion):\t" + numTeeth;
    textToWrite += "\nNumber of Teeth (Gear):\t" + numTeethGear;
    textToWrite += "\n" + moduleWord + ":\t" + moduleInput;
    textToWrite += "\nFace Width:\t" + faceWidthGearInput + "\t" + unitWord;
    textToWrite += "\nAllowable Stress:\t" + Sac;
    textToWrite += "\nGeometry Factor:\t" + I;
    textToWrite += "\nDynamic Factor:\t" + Cv;
    textToWrite += "\nSize Factor:\t" + Cs;
    textToWrite += "\nLoad-Distribution Factor:\t" + Cm;
    textToWrite += "\nSurface-Condition Factor:\t" + Cf;
    textToWrite += "\nOverload Factor:\t" + Co;
    textToWrite += "\nElastic Coefficient:\t" + Cp;
    textToWrite += "\nLife Factor:\t" + CL;
    textToWrite += "\nHardness Factor:\t" + CH;
    textToWrite += "\nTemperature Factor:\t" + CT;
    textToWrite += "\nSafety Factor:\t" + CR;
    textToWrite += "\n\nOutputs";
    textToWrite +=
      "\nHorsepower:\t" +
      outputPower.toFixed(precision) +
      "\thp\t" +
      (outputPower / 1.341).toFixed(precision) +
      "\tkW";
    textToWrite +=
      "\nPitch Diameter:\t" +
      pitchDiameter.toFixed(precision) +
      "\tin\t" +
      (pitchDiameter * 25.4).toFixed(precision) +
      "\tmm";
    textToWrite +=
      "\nPitch Line Velocity:\t" +
      pitchLineVelocity.toFixed(precision) +
      "\tfpm\t" +
      (pitchLineVelocity / 3.281).toFixed(precision) +
      "\tm/min";
    textToWrite +=
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";

    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);

    return false;
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Gear Strength (Dudley)</div>
        <p>
          The Dudley gear strength calculator uses gear geometry, material, RPM,
          manufacturing methods and load conditions to provide guidance on gear
          tooth strength.
        </p>

        <p>
          The output is a maximum horsepower rating you can transmit through a
          gear while maintaining a life factor. Developed in 1962, more factors
          are used in the final determination of the result to improve on
          results by Lewis. While there are more advanced strength models, the
          combination of Dudley and Lewis are informative for any gear strength
          analysis problem.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio updateAll={updateAll} />

              <div style={{ marginBottom: 10 + "px" }}>
                <label for="input-numteeth">Pinion RPM</label>
                <input className="inputbox" type="number" id="input-rpm" />
              </div>

              <DPModuleInput onChange={updateDynamic} />

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label for="input-numteeth">Number of Teeth (Pinion)</label>
                  <input
                    className="inputbox"
                    type="number"
                    id="input-numteeth"
                    size="25"
                    onChange={() => {
                      updateGeometry();
                      updateDynamic();
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10 + "px" }}>
                  <label for="input-numteeth">Number of Teeth (Gear)</label>
                  <input
                    className="inputbox"
                    type="number"
                    id="input-numteethgear"
                    size="25"
                    onChange={updateGeometry}
                  />
                </div>
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="input-pangdrop">Pressure Angle</label>
                  <select
                    className="inputbox"
                    name="input-pangdrop"
                    id="input-pangdrop"
                    onChange={updateGeometry}
                  >
                    <option value="default">Pressure angle...</option>
                    <option value="14.5">14.5 degrees</option>
                    <option value="20">20 degrees</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Geometry Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-I"
                    size="25"
                    placeholder="Needs gear ratio and pressure angle"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="input-sacdrop">
                    Material
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">
                        BHN is surface hardness
                      </span>
                    </div>
                  </label>
                  <select
                    className="inputbox"
                    name="input-sacdrop"
                    id="input-sacdrop"
                    onChange={updateSAC}
                  >
                    <option value="default">Material...</option>
                    <option value="95000">Steel, BHN &gt; 180</option>
                    <option value="115000">Steel, BHN &gt; 240</option>
                    <option value="135000">Steel, BHN &gt; 300</option>
                    <option value="160000">Steel, BHN &gt; 360</option>
                    <option value="190000">Steel, BHN &gt; 440</option>
                    <option value="200000">Steel, Rockwell C 55-63</option>
                    <option value="30000">Cast Iron, grade AGMA 20</option>
                    <option value="50000">Cast Iron, grade AGMA 30</option>
                    <option value="65000">Cast Iron, grade AGMA 40</option>
                    <option value="30000">Bronze, AGMA 2c</option>
                    <option value="65000">Aluminum bronze, heat-treated</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Allowable stress</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Sac"
                    size="25"
                    placeholder="Needs material properties"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="dynamicdrop">Manufacturing Method</label>
                  <select
                    className="inputbox"
                    name="dynamicdrop"
                    id="dynamicdrop"
                    onChange={updateDynamic}
                  >
                    <option value="default">Method...</option>
                    <option value="curve1">
                      High-precision ground or shaved
                    </option>
                    <option value="curve3">Ground or shaved</option>
                    <option value="curve4">Hobbed or shaved</option>
                    <option value="curve5">Milling cutter or other</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Dynamic Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Cv"
                    size="25"
                    placeholder="Needs manufacturing method"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label for="input-face">
                    Face Width
                    <div className="calctooltip">
                      [?]
                      <span className="tooltiptext">
                        mm for Module, <br />
                        inch for DP
                      </span>
                    </div>
                  </label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-face"
                    size="25"
                    placeholder="Face Width (mm)"
                    onChange={updateDist}
                  />
                </div>
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="distdrop">Support conditions</label>
                  <select
                    className="inputbox"
                    name="distdrop"
                    id="distdrop"
                    onChange={updateDist}
                  >
                    <option value="default">Conditions...</option>
                    <option value="rigid">
                      Accurate mountings, low bearing clearances
                    </option>
                    <option value="lessrigid">
                      Less rigid mountings, contact across full face
                    </option>
                    <option value="notfull">Less than full-face contact</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Load-Distribution Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Cm"
                    size="25"
                    placeholder="Needs face width &amp; support conditions"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label for="powersource">Power Source</label>
                  <select
                    className="inputbox"
                    name="powersource"
                    id="powersource"
                    onChange={updateOverload}
                  >
                    <option value="default">Power Source...</option>
                    <option value="uniform">Uniform</option>
                    <option value="light">Light shock</option>
                    <option value="medium">Medium shock</option>
                  </select>
                </div>
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="loadshock">Character of Load</label>
                  <select
                    className="inputbox"
                    name="loadshock"
                    id="loadshock"
                    onChange={updateOverload}
                  >
                    <option value="default">Load...</option>
                    <option value="uniform">Uniform</option>
                    <option value="moderate">Moderate Shock</option>
                    <option value="heavy">Heavy Shock</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Overload Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Co"
                    size="25"
                    placeholder="Needs power source and load"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label for="pmatdrop">Pinion Material</label>
                  <select
                    className="inputbox"
                    name="pmatdrop"
                    id="pmatdrop"
                    onChange={updateElastic}
                  >
                    <option value="default">Pinion Material...</option>
                    <option value="steel">Steel</option>
                    <option value="castiron">Cast Iron</option>
                    <option value="albronze">Aluminum bronze</option>
                    <option value="snbronze">Tin bronze</option>
                  </select>
                </div>
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="gmatdrop">Gear Material</label>
                  <select
                    className="inputbox"
                    name="gmatdrop"
                    id="gmatdrop"
                    onChange={updateElastic}
                  >
                    <option value="default">Gear Material...</option>
                    <option value="steel">Steel</option>
                    <option value="castiron">Cast Iron</option>
                    <option value="albronze">Aluminum bronze</option>
                    <option value="snbronze">Tin bronze</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Elastic Coefficient</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-Cp"
                    size="25"
                    placeholder="Needs gear &amp; pinion material"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="lifecycles">Total Life Cycles Required</label>
                  <select
                    className="inputbox"
                    name="lifecycles"
                    id="lifecycles"
                    onChange={updateLife}
                  >
                    <option value="default">Life...</option>
                    <option value="1.5">10,000</option>
                    <option value="1.3">100,000</option>
                    <option value="1.1">1,000,000</option>
                    <option value="1">10,000,000+</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Life Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-CL"
                    size="25"
                    placeholder="Needs required cycles"
                  />
                </div>
              </div>

              <div className="bluebox">
                <div style={{ marginBottom: 5 + "px" }}>
                  <label for="safetydrop">Application Requirements</label>
                  <select
                    className="inputbox"
                    name="safetydrop"
                    id="safetydrop"
                    onChange={updateSafety}
                  >
                    <option value="default">Requirements...</option>
                    <option value="1.25">High reliability</option>
                    <option value="1.0">Fewer than 1 failure in 100</option>
                    <option value="0.8">Fewer than 1 failure in 3</option>
                  </select>
                </div>
              </div>

              <div className="dependentfactor">
                <div style={{ marginBottom: 10 + "px" }}>
                  <label>Safety Factor</label>
                  <input
                    className="inputbox"
                    type="text"
                    id="input-CR"
                    size="25"
                    placeholder="Needs application requirements"
                  />
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Size Factor
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      use 1.25 for case-carburized gears coarser than 20DP
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-Cs"
                  size="25"
                  placeholder="1.0"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Surface Condition Factor
                  <div className="calctooltip">
                    [?]<span className="tooltiptext">Use 1.0</span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-Cf"
                  size="25"
                  placeholder="1.0"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Hardness Factor
                  <div className="calctooltip">
                    [?]<span className="tooltiptext">Use 1.0</span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-CH"
                  size="25"
                  placeholder="1.0"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>
                  Temperature Factor
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Use 1.0 unless temperature exceeds 250&#176;F
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-CT"
                  size="25"
                  placeholder="1.0"
                />
              </div>

              <DecimalPlacesSelect defaultValue={2} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/dudley-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <div>
          A gear's strength or power rating is calculated from the:
          <ul>
            <li>speed of the pinion</li>
            <li>
              module, pressure angle, and tooth count of the gear and pinion
            </li>
            <li>pinion material (and its hardness)</li>
            <li>method of manufacturing</li>
            <li>face width and support conditions</li>
            <li>power source and load conditions</li>
            <li>material interaction between gear and pinion</li>
            <li>total lifecycles</li>
            <li>application requirements</li>
            <li>
              and factors for temperature, hardness, surface condition, and size
            </li>
          </ul>
        </div>
        <h4>Gear Strength Formula</h4>
        <p>
          It takes a lot of parameters to calculate the strength of gears. This
          equation combines the geometry, use case, and historical experience
          (from Dudley's 1962 Gear Handbook) to approximate the total allowable
          power:
        </p>
        <Image src="/calc-images/dudley-2.png" style={{ maxWidth: "400px" }} />{" "}
        <br />
        where <br />
        <br />
        <Image
          src="/calc-images/dudley-3.png"
          style={{ maxWidth: "300px" }}
        />{" "}
        <br />
        <br />
        <p>
          This calculator conforms to the standards in D.W. Dudley's{" "}
          <i>Gear Handbook</i> (1962), an essential reference in gear design.
          The Gear Handbook is a hefty tome at over 800 pages of gear knowledge
          and information. What sets it apart is Dudley's emphasis on being
          practical, not just academic. By using empirical data, field
          observations, and real-world experience, Dudley effectively
          communicates useful information to those who need it.
        </p>
        <h4>Strength Calculator for Max Horsepower Through a Gear</h4>
        <p>
          The calculator will tell you the maximum power that you can transmit
          through a gear, in horsepower. This should be taken only as an
          approximate guide, and not a design parameter. While we've taken care
          to ensure numerical accuracy and agreement with the established
          methods, these general methods can't fully consider the specifics of
          your situation. If you need a more refined analysis, contact us and
          our engineers can work with you and your application.
        </p>
      </Card>
    </>
  );
}
