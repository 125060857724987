const ROOT_URL = ""; // TODO: proxy settings allow redirect to localhost:4242

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  // Accelerated test and return
  //     let userInfo = {"username":event.target.username.value,"password":event.target.password.value};

  const userInfo = {
    username: loginPayload.email,
    password: loginPayload.password,
  };
  const response = await fetch("/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userInfo),
    //body: userInfo,
  });
  let data = await response.json();
  localStorage.setItem("currentUser", JSON.stringify(data));
  return data;
  // End test and return

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await fetch(`${ROOT_URL}/login`, requestOptions);
    let data = await response.json();

    if (data.user) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("currentUser", JSON.stringify(data));
      return data;
    }

    dispatch({ type: "LOGIN_ERROR", error: data.errors[0] });
    //return;
    return "Error1";
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    // had no return
    return "Error2";
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
}
