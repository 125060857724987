// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purchase-sub_list__Laxlx {
  margin: 20px 0 40px;
  max-width: 400px;
}
.purchase-sub_listItem__KM3QX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.purchase-sub_listItem__KM3QX h3 {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/routes/purchase-sub.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".list {\n  margin: 20px 0 40px;\n  max-width: 400px;\n}\n.listItem {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 40px;\n}\n.listItem h3 {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `purchase-sub_list__Laxlx`,
	"listItem": `purchase-sub_listItem__KM3QX`
};
export default ___CSS_LOADER_EXPORT___;
