import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "./calculators/helpers";

import { Card, Col, Row, Container } from "react-bootstrap";

import ChangePassword from "./changepwd";

const AccountInfo = (params) => {
  var subscription = params.subscription;
  try {
    var customer = subscription.customer;
    if (!customer.deleted) {
      return (
        <Card className="project-card">
          <div className="project-name gray">Contact & Billing Info</div>
          <table className="account-table">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{customer.name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{customer.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{customer.phone}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>
                  <div>{customer.address.line1}</div>
                  <div>{customer.address.line2}</div>
                  <div>
                    {customer.address.city}, {customer.address.state}
                  </div>
                  <div>{customer.address.postal_code}</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <Link
              className="btn link-button"
              to={
                "https://billing.stripe.com/p/login/cN29DR0TO1WPdoIbII?prefilled_email=" +
                encodeURIComponent(customer.email)
              }
              style={{ marginRight: "20px" }}
            >
              Manage Account
            </Link>
            <br />
            <br />
            If you need assistance, please email{" "}
            <a href="mailto:contact@evolventdesign.com">
              contact@evolventdesign.com
            </a>
          </div>
        </Card>
      );
    } else {
      return "Customer was deleted.";
    }
  } catch {
    return "Loading customer information...";
  }
};

const AccountSubscription = ({ subscription, invoices, userinfo }) => {
  function formatSubName(lookup_key) {
    // Turns "lite_monthly" into something prettier: Lite (monthly)
    let subName = lookup_key.split("_");
    let subNameCap = subName[0][0].toUpperCase() + subName[0].slice(1);
    return subNameCap;
  }

  function isCancelable(status) {
    // determines whether a subscription can be cancelled (to show a cancel button)
    // Possible values are: incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid
    // Reference: https://stripe.com/docs/api/subscriptions/object
    if (
      status === "active" ||
      status === "trialing" ||
      status === "past_due" ||
      status === "unpaid"
    ) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <Card className="project-card">
      <div className="project-name">Subscription</div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "120px" }}>
        <div>
          {subscription.status === "active" && (
            <>
              <h4 className="card-heading" style={{ marginBottom: "20px" }}>
                Current Subscription
              </h4>
              <table className="account-table">
                <tbody>
                  <tr>
                    <td>Subscription Tier</td>
                    <td>
                      {formatSubName(
                        subscription.items.data[0].price.lookup_key
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>
                      ${subscription.items.data[0].price.unit_amount / 100}/
                      {subscription.items.data[0].price.recurring.interval}
                    </td>
                  </tr>
                  <tr>
                    <td>Signup Date</td>
                    <td>{formatDate(new Date(subscription.created * 1000))}</td>
                  </tr>
                  <tr>
                    <td>Next Payment</td>
                    <td>
                      {formatDate(
                        new Date(subscription.current_period_end * 1000)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {isCancelable(subscription.status) && (
                <div>
                  <Link
                    className="btn link-button"
                    to="/change-plan"
                    style={{ marginRight: "20px" }}
                  >
                    Change Plan
                  </Link>
                  <Link
                    className="inside-btn-delete btn"
                    to="/cancel"
                    state={{
                      subscription: subscription.id,
                      userinfo: userinfo,
                    }}
                  >
                    Cancel
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <h4 className="card-heading" style={{ marginBottom: "20px" }}>
            Invoices
          </h4>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, i) => (
                <tr key={`invoice-${i}`}>
                  <td>{formatDate(new Date(invoice.period.start * 1000))}</td>
                  <td>${invoice.amount / 100}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default function AccountPage() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [userInfo, setUserInfo] = useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  // get the accessToken to authenticate the user get stripeID, and request subscriptions from stripe
  var tempUserInfo, tempUsername, idToken, refreshToken, accessToken;
  try {
    // Check if there is an existing record, and if so, pull in its data
    tempUserInfo = localStorage.getItem("currentUser");
    tempUserInfo = JSON.parse(tempUserInfo);
    tempUsername = tempUserInfo.username;
    idToken = tempUserInfo.idToken;
    refreshToken = tempUserInfo.refreshToken;
    accessToken = tempUserInfo.accessToken;
  } catch {
    tempUsername = "suspectuser";
    idToken = "";
    refreshToken = "";
    accessToken = "";
  }
  useEffect(() => {
    const fetchData = async () => {
      const { subscriptions, invoices } = await fetch("/get-subscriptions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshtoken: refreshToken,
          accesstoken: accessToken,
        }),
      }).then((r) => r.json());
      setSubscriptions(subscriptions.data);
      setInvoices(invoices);
      setUserInfo(tempUserInfo);
    };
    fetchData();
  }, []);

  if (!subscriptions) {
    return "";
  }

  return (
    <Container className="inside-container">
      <Row>
        <Col>
          <div>
            <a
              id="calc"
              ref={scrollanchor}
              style={{ scrollMarginTop: 100 + "px" }}
            />
            <div>
              {subscriptions.length > 0 &&
                subscriptions.map((s) => {
                  return (
                    <AccountSubscription
                      key={s.id}
                      subscription={s}
                      invoices={invoices}
                      userinfo={userInfo}
                    />
                  );
                })}
            </div>

            {/* This might only show up if the user is/has subscribed.*/}
            {subscriptions.length > 0 && (
              <AccountInfo subscription={subscriptions[0]} />
            )}

            <ChangePassword />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
