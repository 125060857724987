import React, { useState, useEffect } from "react";

import { useAuthState } from "../../context";
import styles from "../login.module.css";

// special import to redirect after login
import { useNavigate } from "react-router-dom";

// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { useOutletContext } from "react-router-dom"; // for setWeakUsername

// As an "outside" page
import Footer from "./footer";
import PublicHeader from "./header";

//  This is a combination of "register.js" and "purchase-sub.js"

function OutsideJoinForm() {
  // Grab current all projects (and set) from Outlet (in root.js)
  const [
    activeProject,
    setActiveProject,
    allProjects,
    setAllProjects,
    weakUsername,
    setWeakUsername,
  ] = useOutletContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordconf, setPasswordconf] = useState("");
  const [message, setMessage] = useState("");
  const [prices, setPrices] = useState([]);
  const [priceID, setPriceID] = useState("");
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [priceSelected, setPriceSelected] = useState(false);
  const [termsApproved, setTermsApproved] = useState(false);

  // user biographic information
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [company, setCompany] = useState('');
  // const [address1, setAddress1] = useState('');
  // const [address2, setAddress2] = useState('');
  // const [city, setCity] = useState('');
  // const [state, setState] = useState('');
  // const [zipcode, setZipcode] = useState('');
  // const [phone, setPhone] = useState('');
  // const [passwordConf, setPasswordConf] = useState('');

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    // wait and see if it's time to redirect
    if (subscriptionData) {
      // Subscription was created! Now we can redirect the user to have them pay for it
      // a  la confirm

      // Decide where to redirect based on the selected option (free -> /home, paid ->/payment)
      // Front-end decides, but that's okay because the user won't have group membership if they don't go through /payment
      // IF the price_id selected is for free (either development or production)
      var freePriceIds = [
        "price_1O9roeGkD1fEWiYArdaXPLDZ",
        "price_1O9xhXGkD1fEWiYAgszu5ioG",
      ];
      if (freePriceIds.includes(priceID)) {
        // User "bought" a free product, and can go straight to home
        setTimeout(function () {
          navigate("/login", { state: subscriptionData });
        }, 100);
      } else {
        setTimeout(function () {
          navigate("/payment", { state: subscriptionData });
        }, 100);
      }
    }
  }, [subscriptionData]);

  function clearFormError(event) {
    // get the name, then change the state accordingly
    var formBox = event.target.id;
    var newFormErrors = { ...formErrors };
    delete newFormErrors[formBox];
    setFormErrors(newFormErrors);
  }

  function validateInputs() {
    var allInputsValid = true;
    var requiredInputs = [
      "email",
      "firstname",
      "lastname",
      "address",
      "city",
      "addressstate",
      "zipcode",
      "phone",
      "password",
      "passwordconf",
    ];

    let newFormErrors = { ...formErrors };

    requiredInputs.forEach((entry) => {
      if (!document.getElementById(entry).value) {
        allInputsValid = false;
        newFormErrors[entry] = "This field is required";
      }
    });
    if (document.getElementById("password").value.length < 8) {
      allInputsValid = false;
      newFormErrors["password"] = "Must be at least 8 characters";
    }

    if (
      document.getElementById("password").value !==
      document.getElementById("passwordconf").value
    ) {
      allInputsValid = false;
      newFormErrors["passwordconf"] = "Passwords must match";
    }
    setFormErrors(newFormErrors);
    return allInputsValid;
  }

  const { loading, errorMessage } = useAuthState();

  useEffect(() => {
    const fetchPrices = async () => {
      const { prices } = await fetch("/getprices").then((r) => r.json());
      setPrices(prices);
    };
    fetchPrices();
  }, []);

  let navigate = useNavigate();

  function selectPlan() {
    // user selected a different plan (onChange triggers)
    var radios = document.getElementsByName("plan-radios");

    for (var i = 0, length = radios.length; i < length; i++) {
      if (radios[i].checked) {
        setPriceID(radios[i].value);
        setPriceSelected(true);
        break;
      }
    }
    // validate inputs
    validateInputs();
  }

  function termsCheck() {
    var termsCheckbox = document.getElementById("terms-checkbox").checked;
    setTermsApproved(termsCheckbox);
    validateInputs();
  }

  const createSubscription = async (e) => {
    e.preventDefault();

    // Validate inputs!
    var validInputs = validateInputs();
    // once selected, send the priceId of the selection, and user information to
    // a) create a customer in Stripe and b) create the subscription for the priceId
    if (validInputs) {
      // NEW: /combined-create-subscription
      const {
        newUserInfo,
        newUserUsername,
        newUserAccessToken,
        newUserIdToken,
        newUserRefreshToken,
        subscriptionId,
        clientSecret,
        totalAmount,
        totalFrequency,
        errorBody,
        promoCode,
      } = await fetch("/combined-create-subscription", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId: priceID,
          username: email,
          password: password,
          firstname: document.getElementById("firstname").value,
          lastname: document.getElementById("lastname").value,
          company: document.getElementById("company").value,
          address: document.getElementById("address").value,
          address2: document.getElementById("address2").value,
          city: document.getElementById("city").value,
          addressstate: document.getElementById("addressstate").value,
          zipcode: document.getElementById("zipcode").value,
          phone: document.getElementById("phone").value,
          promo: document.getElementById("promo").value,
        }),
      }).then((r) => r.json());

      try {
        if (errorBody.code === "UsernameExistsException") {
          // Catch an already existing account
          setMessage(
            "An account already exists for this username. Please login instead."
          );
        }
        if (errorBody.code === "UsernameExistsException") {
          // Catch an already existing account
          setMessage(
            "An account already exists for this username. Please login instead."
          );
        }
        if (errorBody.code === "PromoNotFound") {
          // Catch an already existing account
          setMessage("Invalid discount/promo code");
        }
        if (errorBody.code === "PromoNotActive") {
          // Catch an already existing account
          setMessage("Discount/promo code is not active");
        }
      } catch {
        // We can save the user information now
        setWeakUsername(newUserUsername);
        localStorage.setItem("currentUser", JSON.stringify(newUserInfo));

        try {
          setSubscriptionData({
            subscriptionId,
            clientSecret,
            newUserUsername,
            totalAmount,
            totalFrequency,
            promoCode,
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setMessage("Please fix inputs");
    }

    if (subscriptionData) {
      // Subscription was created! Now we can redirect the user to have them pay for it
      // a  la confirm
      var freePriceIds = [
        "price_1O9roeGkD1fEWiYArdaXPLDZ",
        "price_1O9xhXGkD1fEWiYAgszu5ioG",
      ];
      if (freePriceIds.includes(priceID)) {
        // User "bought" a free product, and can go straight to home
        setTimeout(function () {
          navigate("/login", { state: subscriptionData });
        }, 100);
      } else {
        setTimeout(function () {
          navigate("/payment", { state: subscriptionData });
        }, 100);
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader noHeaderImg />

          <Row>
            <Card className="project-card">
              <div className="project-name">
                <h2
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  Sign Up
                </h2>
              </div>
              <p style={{ marginTop: "20px" }}>
                Register below for immediate access to our suite of calculators,
                generators, and gear design tools!
              </p>
              <p>
                If you already have an account, please login{" "}
                <a className="announcement-link" href="/login">
                  here
                </a>
              </p>
              <hr style={{ margin: "20px 0 40px" }} />

              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                  <div className={styles.container}>
                    <div className={{ width: 200 }}>
                      {errorMessage ? <p>TODO: insert error message</p> : null}
                      <form>
                        <h2 className="card-heading">billing info</h2>
                        <div className={styles.loginForm}>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              id="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                clearFormError(e);
                              }}
                              disabled={loading}
                            />
                            <div className={styles.error}>
                              {formErrors.email}
                            </div>
                          </div>

                          <div className={styles.loginFormItem}>
                            <label htmlFor="firstname">First Name</label>
                            <input
                              type="text"
                              id="firstname"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-firstname" className={styles.error}>
                              {formErrors.firstname}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="lastname">Last Name</label>
                            <input
                              type="text"
                              id="lastname"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-lastname" className={styles.error}>
                              {formErrors.lastname}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="company">Company</label>
                            <input
                              type="text"
                              id="company"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-company" className={styles.error}>
                              {formErrors.company}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="address">Address Line 1</label>
                            <input
                              type="text"
                              id="address"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-address" className={styles.error}>
                              {formErrors.address}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="address2">Address Line 2</label>
                            <input
                              type="text"
                              id="address2"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-address2" className={styles.error}>
                              {formErrors.address2}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="city">City</label>
                            <input
                              type="text"
                              id="city"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-city" className={styles.error}>
                              {formErrors.city}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="addressstate">State</label>
                            <input
                              type="text"
                              id="addressstate"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div
                              id="error-addressstate"
                              className={styles.error}
                            >
                              {formErrors.addressstate}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="zipcode">Zipcode</label>
                            <input
                              type="text"
                              id="zipcode"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-zipcode" className={styles.error}>
                              {formErrors.zipcode}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="phone">Phone Number</label>
                            <input
                              type="tel"
                              id="phone"
                              disabled={loading}
                              onChange={(e) => clearFormError(e)}
                            />
                            <div id="error-phone" className={styles.error}>
                              {formErrors.phone}
                            </div>
                          </div>
                          <div
                            className={styles.loginFormItem}
                            style={{ marginTop: "20px" }}
                          >
                            <label htmlFor="password">Password</label>
                            <input
                              type="password"
                              id="password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                clearFormError(e);
                              }}
                              disabled={loading}
                            />
                            <div id="error-password" className={styles.error}>
                              {formErrors.password}
                            </div>
                          </div>
                          <div className={styles.loginFormItem}>
                            <label htmlFor="passwordconf">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              id="passwordconf"
                              value={passwordconf}
                              onChange={(e) => {
                                setPasswordconf(e.target.value);
                                clearFormError(e);
                              }}
                              disabled={loading}
                            />
                            <div
                              id="error-passwordconf"
                              className={styles.error}
                            >
                              {formErrors.passwordconf}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} xl={5}>
                  <div className="price-list">
                    <h2 className="card-heading">subscription info</h2>

                    <div style={{ marginBottom: 10 + "px" }}>
                      {prices.map((price, index) => {
                        return (
                          <div
                            key={`plan-${index}`}
                            style={{ marginBottom: "10px" }}
                          >
                            <input
                              className="radioButton"
                              type="radio"
                              id={"input-plan-" + price.id}
                              name="plan-radios"
                              value={price.id}
                              onChange={(e) => selectPlan(e)}
                            />
                            <label
                              className="radioLabel"
                              id={"label-" + price.id}
                              htmlFor="in"
                            >
                              <b
                                style={{
                                  display: "inline-block",
                                  margin: "0 20px 0 5px",
                                }}
                              >
                                {price.product.name}
                              </b>
                              <span>
                                ${price.unit_amount / 100}{" "}
                                {price.type === "recurring"
                                  ? " / " + price.recurring.interval
                                  : "fixed"}
                              </span>
                            </label>
                          </div>
                        );
                      })}
                      <p style={{ fontSize: ".9em" }}>
                        <a className="announcement-link" href="/pricing">
                          Compare plans
                        </a>
                      </p>
                      <hr />
                      <div className={styles.loginFormItem}>
                        <label htmlFor="promo">Promo / Discount</label>
                        <input
                          type="text"
                          id="promo"
                          disabled={loading}
                          onChange={(e) => clearFormError(e)}
                        />
                        <div id="error-promo" className={styles.error}>
                          {formErrors.promo}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: ".9em",
                          marginBottom: "40px",
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <input
                          className="radioButton"
                          type="checkbox"
                          id="terms-checkbox"
                          onChange={() => termsCheck()}
                          style={{ marginRight: "10px", display: "inline" }}
                        />
                        I agree to the
                        <a
                          className="announcement-link"
                          href="/terms"
                          style={{ margin: "0 5px", display: "inline" }}
                        >
                          Terms and Conditions
                        </a>
                        and
                        <a
                          className="announcement-link"
                          href="/privacy"
                          style={{ margin: "0 5px" }}
                        >
                          Privacy Policy
                        </a>
                      </div>
                      <button
                        disabled={!(priceSelected && termsApproved)}
                        className="btn-primary"
                        onClick={(e) => createSubscription(e)}
                        style={{ marginBottom: "20px" }}
                      >
                        Review
                      </button>

                      <div className={styles.error}>
                        {message ? message : ""}
                      </div>
                      <Image
                        src="./stripe.png"
                        height={"40px"}
                        style={{ marginTop: "40px" }}
                      ></Image>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Row>

          <Footer></Footer>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}

export default OutsideJoinForm;
