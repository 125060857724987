import React from "react";

// import Router
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import { BrowserRouter } from "react-router-dom";
import { RootInside, RootOutside } from "./routes/root";
import ErrorPage from "./routes/error-page";
import DefaultPage from "./routes/default";

// import Calculators
import CalculatorDimensions from "./routes/calculators/calc-dimensions";
import CalculatorGear3 from "./routes/calculators/calc-gear3";
import CalculatorMOP from "./routes/calculators/calc-mop";
import CalculatorCenterDistance from "./routes/calculators/calc-centerdistance";
import CalculatorWormMOP from "./routes/calculators/calc-wormmop";
import CalculatorGearStrength from "./routes/calculators/calc-strength";
import CalculatorRack from "./routes/calculators/calc-rack";
import CalculatorRatio from "./routes/calculators/calc-ratio";
import CalculatorLewis from "./routes/calculators/calc-lewis";
import CalculatorPitchPlate from "./routes/calculators/calc-pitchplate";
import CalculatorVariRoll from "./routes/calculators/calc-variroll";
import CalculatorLeadWorm from "./routes/calculators/calc-leadworm";
import CalculatorDP from "./routes/calculators/calc-dp";
import CalculatorLeadThreads from "./routes/calculators/calc-leadthread";
import CalculatorDividingHead from "./routes/calculators/calc-dividinghead";
import CalculatorURatio from "./routes/calculators/calc-uratio";
import CalculatorWormCarbide from "./routes/calculators/calc-wormcarbide";
import CalculatorFandS from "./routes/calculators/calc-fands";
import CalculatorFandSPfauter from "./routes/calculators/calc-fands2";
import CalculatorHardness from "./routes/calculators/calc-hardness";
import CalculatorFreeHobbing from "./routes/calculators/calc-freehob";
import CalculatorDimSpur from "./routes/calculators/calc-dim-spur";
import CalculatorDimHelix from "./routes/calculators/calc-dim-helix";
import CalculatorDimHelixGeo from "./routes/calculators/calc-dim-helix-geo";

// Calculator Embeds
import CalculatorCGDB from "./routes/calculators/calc-embed-cgdb";
import CalculatorDXF from "./routes/calculators/calc-embed-dxf";
import CalculatorSTL from "./routes/calculators/calc-embed-stl";

// import Context
import { AuthProvider } from "./context";
import OutsideLoginPage from "./routes/outside/login";
import { AuthRoute } from "./context/authroute";
import Logout from "./routes/logout";

// import Payment
import CalculatorSpan from "./routes/calculators/calc-span";
// and now for subscription
import SubscribePage from "./routes/purchase-sub";
import PaymentPage from "./routes/payment";
import AccountPage from "./routes/account";
import CancelPage from "./routes/cancel";
// new subscription
import OutsideJoinPage from "./routes/outside/join";

// import Projects
import ProjectsPage from "./routes/projects";
import UtilityReference from "./routes/calculators/utility-reference";

// import Public-Facing pages
import PublicHomePage from "./routes/outside/home";
import PublicFAQPage from "./routes/outside/faq";
import PublicTOSPage from "./routes/outside/tos";
import PublicPrivacyPage from "./routes/outside/privacy";
import PublicNewsPage from "./routes/outside/news";
import PublicContactPage from "./routes/outside/contact";
import PublicPricingPage from "./routes/outside/pricing";
import PublicForgotPasswordPage from "./routes/outside/forgotpassword";

// import DB-entry pages
import CalcEntryMachine from "./routes/calculators/db-machine";

// import TEST pages (to replace existing)
import TemporaryCalculatorCGbook from "./routes/calculators/temp-uratio2";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootInside />,
    errorElement: <ErrorPage />,
    children: [
      {
        children: [
          {
            index: false,
            element: <DefaultPage />,
          } /*Turn this off when ready to switch to RootOutside main! */,
          { path: "home", element: <DefaultPage /> },
          { path: "logout", element: <Logout /> },
          {
            path: "projects",
            element: (
              <AuthRoute level="0">
                <ProjectsPage />
              </AuthRoute>
            ),
          },
          {
            path: "change-plan",
            element: (
              <AuthRoute level="0">
                <SubscribePage />
              </AuthRoute>
            ),
          },
          { path: "payment", element: <PaymentPage /> },
          {
            path: "account",
            element: (
              <AuthRoute level="0">
                <AccountPage />
              </AuthRoute>
            ),
          },
          {
            path: "cancel",
            element: (
              <AuthRoute level="0">
                <CancelPage />
              </AuthRoute>
            ),
          },
          {
            path: "calc/mop",
            element: (
              <AuthRoute level="0">
                <CalculatorMOP />
              </AuthRoute>
            ),
          },
          {
            path: "calc/dimensions",
            element: (
              <AuthRoute level="0">
                <CalculatorDimensions />
              </AuthRoute>
            ),
          },
          {
            path: "calc/worm-mop",
            element: (
              <AuthRoute level="19">
                <CalculatorWormMOP />
              </AuthRoute>
            ),
          },
          {
            path: "calc/center-distance",
            element: (
              <AuthRoute level="0">
                <CalculatorCenterDistance />
              </AuthRoute>
            ),
          },
          {
            path: "calc/strength",
            element: (
              <AuthRoute level="0">
                <CalculatorGearStrength />
              </AuthRoute>
            ),
          },
          {
            path: "calc/rack",
            element: (
              <AuthRoute level="0">
                <CalculatorRack />
              </AuthRoute>
            ),
          },
          {
            path: "calc/ratio",
            element: (
              <AuthRoute level="0">
                <CalculatorRatio />
              </AuthRoute>
            ),
          },
          {
            path: "calc/span",
            element: (
              <AuthRoute level="0">
                <CalculatorSpan />
              </AuthRoute>
            ),
          },
          {
            path: "calc/lewis",
            element: (
              <AuthRoute level="0">
                <CalculatorLewis />
              </AuthRoute>
            ),
          },
          {
            path: "calc/cgdb",
            element: (
              <AuthRoute level="0">
                <CalculatorCGDB />
              </AuthRoute>
            ),
          },
          {
            path: "calc/pitch-plate",
            element: (
              <AuthRoute level="0">
                <CalculatorPitchPlate />
              </AuthRoute>
            ),
          },
          {
            path: "calc/vari-roll",
            element: (
              <AuthRoute level="0">
                <CalculatorVariRoll />
              </AuthRoute>
            ),
          },
          {
            path: "calc/lead-angle-worms-hobs",
            element: (
              <AuthRoute level="0">
                <CalculatorLeadWorm />
              </AuthRoute>
            ),
          },
          {
            path: "calc/pitch-conversion",
            element: (
              <AuthRoute level="0">
                <CalculatorDP />
              </AuthRoute>
            ),
          },
          {
            path: "calc/lead-angle-threads",
            element: (
              <AuthRoute level="0">
                <CalculatorLeadThreads />
              </AuthRoute>
            ),
          },
          {
            path: "calc/dividing-head",
            element: (
              <AuthRoute level="0">
                <CalculatorDividingHead />
              </AuthRoute>
            ),
          },
          {
            path: "calc/dxf",
            element: (
              <AuthRoute level="10">
                <CalculatorDXF />
              </AuthRoute>
            ),
          },
          {
            path: "calc/stl",
            element: (
              <AuthRoute level="10">
                <CalculatorSTL />
              </AuthRoute>
            ),
          },
          {
            path: "calc/gear3",
            element: (
              <AuthRoute level="10">
                <CalculatorGear3 />
              </AuthRoute>
            ),
          },
          {
            path: "calc/uratio",
            element: (
              <AuthRoute level="19">
                <CalculatorURatio />
              </AuthRoute>
            ),
          },
          {
            path: "calc/worm-carbide",
            element: (
              <AuthRoute level="19">
                <CalculatorWormCarbide />
              </AuthRoute>
            ),
          },

          {
            path: "calc/premium",
            element: (
              <AuthRoute level="100">
                <CalculatorDimensions />
              </AuthRoute>
            ),
          },
          {
            path: "calc/utility",
            element: (
              <AuthRoute level="10">
                <UtilityReference />
              </AuthRoute>
            ),
          },
          {
            path: "calc/fands",
            element: (
              <AuthRoute level="19">
                <CalculatorFandS />
              </AuthRoute>
            ),
          },
          {
            path: "calc/fands2",
            element: (
              <AuthRoute level="19">
                <CalculatorFandSPfauter />
              </AuthRoute>
            ),
          },
          {
            path: "calc/hardness",
            element: (
              <AuthRoute level="0">
                <CalculatorHardness />
              </AuthRoute>
            ),
          },
          {
            path: "calc/free-hobbing",
            element: (
              <AuthRoute level="10">
                <CalculatorFreeHobbing />
              </AuthRoute>
            ),
          },
          {
            path: "calc/dim-spur",
            element: (
              <AuthRoute level="10">
                <CalculatorDimSpur />
              </AuthRoute>
            ),
          },
          {
            path: "calc/dim-helix",
            element: (
              <AuthRoute level="20">
                <CalculatorDimHelix />
              </AuthRoute>
            ),
          },
          {
            path: "calc/dim-helix-geo",
            element: (
              <AuthRoute level="20">
                <CalculatorDimHelixGeo />
              </AuthRoute>
            ),
          },
          {
            path: "calc/machine",
            element: (
              <AuthRoute level="10">
                <CalcEntryMachine />
              </AuthRoute>
            ),
          },
          { path: "temporary", element: <TemporaryCalculatorCGbook /> },
        ],
      },
    ],
  },
  {
    path: "/" /* These are the public-facing pages. Like a Homepage, Attract, FAQ, etc. */,
    element: <RootOutside />,
    errorElement: <ErrorPage />,
    children: [
      {
        children: [
          {
            index: true,
            element: <PublicHomePage />,
          },

          { path: "test", element: <PublicHomePage /> },
          { path: "faq", element: <PublicFAQPage /> },
          { path: "terms", element: <PublicTOSPage /> },
          { path: "privacy", element: <PublicPrivacyPage /> },
          { path: "news", element: <PublicNewsPage /> },
          { path: "contact", element: <PublicContactPage /> },
          { path: "pricing", element: <PublicPricingPage /> },
          { path: "join", element: <OutsideJoinPage /> },
          { path: "login", element: <OutsideLoginPage /> },
          { path: "forgotpassword", element: <PublicForgotPasswordPage /> },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
