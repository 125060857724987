import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorDividingHead({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-helical").value = "";
    document.getElementById("input-shift").value = "";
    document.getElementById("input-pindia").value = "";
    setTextAreaText("");

    event.preventDefault();
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);

    var precision = document.getElementById("precision").value;

    logQA({ page: "GCSellis", numteeth: numTeeth, param5: precision });

    var errorMessage = "Error";
    var okToCalc = true;

    if (!(numTeeth >= 2 && numTeeth <= 100)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "\nNumber of divisions must be between 2 and 100";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage = errorMessage + "\nNumber of divisions must be an integer";
    }

    if (okToCalc) {
      calculateCombo(numTeeth, precision);
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }

    return false;
  }
  function calculateCombo(divisions, ratio) {
    divisions = parseInt(divisions);
    ratio = parseInt(ratio);
    let combo40 = [
      [2, "Any", "20", "0"],
      [3, "15", "13", "5"],
      [4, "Any", "10", "0"],
      [5, "Any", "8", "0"],
      [6, "15", "6", "10"],
      [7, "21", "5", "15"],
      [8, "Any", "5", "0"],
      [9, "18", "4", "8"],
      [10, "Any", "4", "0"],
      [11, "33", "3", "21"],
      [12, "15", "3", "5"],
      [13, "39", "3", "3"],
      [14, "21", "2", "18"],
      [15, "15", "2", "10"],
      [16, "16", "2", "8"],
      [17, "17", "2", "6"],
      [18, "18", "2", "4"],
      [19, "19", "2", "2"],
      [20, "Any", "2", "0"],
      [21, "21", "1", "19"],
      [22, "33", "1", "27"],
      [23, "23", "1", "17"],
      [24, "18", "1", "12"],
      [25, "15", "1", "9"],
      [26, "39", "1", "21"],
      [27, "27", "1", "13"],
      [28, "21", "1", "9"],
      [29, "29", "1", "11"],
      [30, "15", "1", "5"],
      [31, "31", "1", "9"],
      [32, "16", "1", "4"],
      [33, "33", "1", "7"],
      [34, "17", "1", "3"],
      [35, "21", "1", "3"],
      [36, "18", "1", "2"],
      [37, "37", "1", "3"],
      [38, "19", "1", "1"],
      [39, "39", "1", "1"],
      [40, "Any", "1", "0"],
      [41, "41", "0", "40"],
      [42, "21", "0", "20"],
      [43, "43", "0", "40"],
      [44, "33", "0", "30"],
      [45, "18", "0", "16"],
      [46, "23", "0", "20"],
      [47, "47", "0", "40"],
      [48, "18", "0", "15"],
      [49, "49", "0", "40"],
      [50, "15", "0", "12"],
      [51, "51", "0", "40", "NS"],
      [52, "39", "0", "30"],
      [53, "53", "0", "40", "NS"],
      [54, "27", "0", "20"],
      [55, "33", "0", "24"],
      [56, "21", "0", "15"],
      [57, "57", "0", "40", "NS"],
      [58, "58", "0", "20"],
      [59, "59", "0", "40", "NS"],
      [60, "15", "0", "10"],
      [61, "61", "0", "40", "NS"],
      [62, "31", "0", "20"],
      [63, "63", "0", "40", "NS"],
      [64, "16", "0", "10"],
      [65, "39", "0", "24"],
      [66, "33", "0", "20"],
      [67, "67", "0", "40", "NS"],
      [68, "17", "0", "10"],
      [69, "69", "0", "40", "NS"],
      [70, "21", "0", "12"],
      [71, "71", "0", "40", "NS"],
      [72, "18", "0", "10"],
      [73, "73", "0", "40", "NS"],
      [74, "37", "0", "20"],
      [75, "15", "0", "8"],
      [76, "19", "0", "10"],
      [77, "77", "0", "40", "NS"],
      [78, "39", "0", "20"],
      [79, "79", "0", "40", "NS"],
      [80, "16", "0", "8"],
      [81, "81", "0", "40", "NS"],
      [82, "41", "0", "20"],
      [83, "83", "0", "40", "NS"],
      [84, "21", "0", "10"],
      [85, "17", "0", "8"],
      [86, "43", "0", "20"],
      [87, "87", "0", "40", "NS"],
      [88, "33", "0", "15"],
      [89, "89", "0", "40", "NS"],
      [90, "18", "0", "8"],
      [91, "91", "0", "40", "NS"],
      [92, "23", "0", "10"],
      [93, "96", "0", "40", "NS"],
      [94, "47", "0", "20"],
      [95, "19", "0", "8"],
      [96, "96", "0", "40", "NS"],
      [97, "97", "0", "40", "NS"],
      [98, "49", "0", "20"],
      [99, "99", "0", "40", "NS"],
      [100, "15", "0", "6"],
    ];
    let combo90 = [
      [2, "Any", "45", "0"],
      [3, "Any", "30", "0"],
      [4, "20", "22", "10"],
      [5, "Any", "18", "0"],
      [6, "Any", "15", "0"],
      [7, "21", "12", "18"],
      [8, "20", "11", "5"],
      [9, "Any", "10", "0"],
      [10, "Any", "9", "0"],
      [11, "33", "8", "6"],
      [12, "20", "7", "10"],
      [13, "39", "6", "36"],
      [14, "21", "6", "9"],
      [15, "Any", "6", "0"],
      [16, "16", "5", "10"],
      [17, "17", "5", "5"],
      [18, "Any", "5", "0"],
      [19, "19", "4", "14"],
      [20, "20", "4", "10"],
      [21, "21", "4", "6"],
      [22, "33", "4", "3"],
      [23, "23", "3", "21"],
      [24, "20", "3", "15"],
      [25, "20", "3", "12"],
      [26, "39", "3", "18"],
      [27, "18", "3", "6"],
      [28, "28", "3", "6", "NS"],
      [29, "29", "3", "3"],
      [30, "Any", "3", "0"],
      [31, "31", "2", "28"],
      [32, "16", "2", "13"],
      [33, "33", "2", "24"],
      [34, "17", "2", "11"],
      [35, "21", "2", "12"],
      [36, "20", "2", "10"],
      [37, "37", "2", "16"],
      [38, "19", "2", "7"],
      [39, "39", "2", "12"],
      [40, "20", "2", "5"],
      [41, "41", "2", "8"],
      [42, "21", "2", "3"],
      [43, "43", "2", "4"],
      [44, "44", "2", "2", "NS"],
      [45, "Any", "1", "0"],
      [46, "23", "1", "22"],
      [47, "47", "1", "43"],
      [48, "16", "1", "14"],
      [49, "49", "1", "41"],
      [50, "20", "1", "16"],
      [51, "51", "1", "39", "NS"],
      [52, "52", "1", "38", "NS"],
      [53, "53", "1", "37", "NS"],
      [54, "18", "1", "12"],
      [55, "33", "1", "21"],
      [56, "56", "1", "34", "NS"],
      [57, "19", "1", "11"],
      [58, "29", "1", "16"],
      [59, "59", "1", "31", "NS"],
      [60, "20", "1", "10"],
      [61, "61", "1", "29", "NS"],
      [62, "31", "1", "14"],
      [63, "21", "1", "9"],
      [64, "64", "1", "26", "NS"],
      [65, "39", "1", "15"],
      [66, "33", "1", "12"],
      [67, "67", "1", "23", "NS"],
      [68, "68", "1", "22", "NS"],
      [69, "23", "1", "7"],
      [70, "21", "1", "6"],
      [71, "71", "1", "19", "NS"],
      [72, "20", "1", "5"],
      [73, "73", "1", "17", "NS"],
      [74, "37", "1", "8"],
      [75, "20", "1", "4"],
      [76, "76", "1", "14", "NS"],
      [77, "77", "1", "13", "NS"],
      [78, "39", "1", "6"],
      [79, "79", "1", "11", "NS"],
      [80, "16", "1", "2"],
      [81, "18", "1", "2"],
      [82, "41", "1", "4"],
      [83, "83", "1", "7", "NS"],
      [84, "84", "1", "6", "NS"],
      [85, "85", "1", "5", "NS"],
      [86, "43", "1", "2"],
      [87, "29", "1", "1"],
      [88, "88", "1", "2", "NS"],
      [89, "89", "1", "1", "NS"],
      [90, "Any", "1", "0"],
      [91, "91", "0", "90", "NS"],
      [92, "92", "0", "90", "NS"],
      [93, "31", "0", "30"],
      [94, "47", "0", "45"],
      [95, "19", "0", "18"],
      [96, "16", "0", "15"],
      [97, "97", "0", "90", "NS"],
      [98, "49", "0", "45"],
      [99, "33", "0", "30"],
      [100, "20", "0", "18"],
    ];

    var comboResponse;

    if (ratio === 40) {
      //fixed offset of 2, table starts at 2, but Javascript starts indexing at 0
      comboResponse = combo40[divisions - 2];
    } else if (ratio === 90) {
      //fixed offset of 2, table starts at 2, but Javascript starts indexing at 0
      comboResponse = combo90[divisions - 2];
    } else {
      comboResponse = "Error, could not find entry";
    }

    var textToWrite;

    if (!(comboResponse[1] === "---")) {
      textToWrite =
        "Inputs\nNumber of Divisions:\t" +
        divisions +
        "\nDividing Head Ratio:\t" +
        ratio +
        ":1\n\nOutputs\nIndex Circle:\t" +
        comboResponse[1] +
        "\nFull Turns:\t" +
        comboResponse[2] +
        "\nHoles:\t" +
        comboResponse[3];

      if (comboResponse[4] === "NS") {
        textToWrite = textToWrite + "\n*Index Plate is not standard*";
      }

      textToWrite =
        textToWrite +
        "\n\n\u00A9 " +
        new Date().getFullYear() +
        " Evolvent Design,\t" +
        formatDate(new Date()) +
        "GCS0";
    } else {
      textToWrite =
        "Inputs\nNumber of Divisions:\t" +
        divisions +
        "\nDividing Head Ratio:\t" +
        ratio +
        ":1\n\nOutputs\nNo standard plate found. Please check back later";
    }
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Gear Milling with a Dividing Head</div>

        <p>
          This calculator outputs the number of full and partial turns required
          to use a dividing head with either a 40:1 or 90:1 internal ratio.
        </p>
        <p>
          Index plates have equally placed holes, and allow the device to
          register in the holes for precise fractional turns. For example, Index
          Circle 15 has 15 holes spaced 24 degrees apart.
        </p>
        <p>
          A combination of full turns (complete revolutions) and partial (e.g.
          advancing 5 holes on Index Circle 15 is one-third of a revolution),
          will precisely divide a circle into a number of segments.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Divisions<span className="required">*</span>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  size="35"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="precision">Dividing Head Ratio</label>
                <select className="inputbox" name="precision" id="precision">
                  <option value="40">40:1</option>
                  <option value="90">90:1</option>
                </select>
              </div>

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/dividinghead-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <h4>Dividing Head Formula</h4>
        <p>
          With a 40:1 gear ratio, you would need to turn the crank on the
          dividing head 40 full turns to rotate the workpiece once. To get 6
          equal divisions, you would need 40/6 = 6+2/3 = 6.66666 turns. The 6
          turns are easy, but the remaining 0.66666 needs the holes on the
          plate.
        </p>
        <p>
          If we multiply the partial turn by the number of holes on the plate,
          we will know exactly how far to advance the diving head: 0.66666*15 =
          10 holes. Putting these together, to divide the piece into 6 segments
          you would rotate the dividing head 6 turns and 10 holes.
        </p>
        <p>
          To get 75 equal segments we will follow the same process: 40/75 =
          0.53333 turns. Now, there is no full rotation, but 0.53333*15 = 8
          holes. So if we rotate the dividing head forward by 8 holes each time,
          it will divide the workpiece into 75 equal segments.{" "}
        </p>
      </Card>
    </>
  );
}
