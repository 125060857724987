import ProjectDropdown from "../calculator-ui/project-dropdown";
import { copyResults, resizeTextAnswer } from "./helpers";

export const UnitsRadio = ({ updateAll }) => (
  <div style={{ marginBottom: 10 + "px" }}>
    <label htmlFor="input-units" className="unitslabel">
      Units
    </label>
    <div>
      <input
        className="radioButton"
        type="radio"
        id="input-units-mm"
        value="mm"
        name="units"
        defaultChecked
        onChange={updateAll}
      />
      <label className="radioLabel" htmlFor="input-units-mm">
        mm (Module)
      </label>
    </div>
    <div>
      <input
        className="radioButton"
        type="radio"
        id="input-units-in"
        value="in"
        name="units"
        onChange={updateAll}
      />
      <label className="radioLabel" htmlFor="input-units-in">
        1/inch (Diametral Pitch)
      </label>
    </div>
  </div>
);

export const DPModuleInput = ({ onChange }) => (
  <div style={{ marginBottom: 10 + "px" }}>
    <label htmlFor="input-module">
      DP/Module<span className="required">*</span>
      <div className="calctooltip">
        [?]
        <span className="tooltiptext">
          Module is in millimeters,
          <br /> DP is in 1/inch
        </span>
      </div>
    </label>
    <input
      className="inputbox"
      type="number"
      id="input-module"
      onChange={onChange}
    />
  </div>
);

export const DecimalPlacesSelect = ({ defaultValue }) => {
  const decimalPlaces = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div style={{ marginBottom: 10 + "px" }}>
      <label htmlFor="precision">Number of Decimal Places</label>
      <select
        className="inputbox"
        name="precision"
        id="precision"
        defaultValue={defaultValue}
      >
        {decimalPlaces.map((i) => (
          <option key={`decimal-${i}`} value={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  );
};

export const ButtonBlock = ({ calculate, clearInputs }) => (
  <div className="cButtonBlock">
    <div style={{ flex: 1 }}>
      <button id="calculate" className="calcButton" onClick={calculate}>
        Calculate
      </button>
    </div>
    <button className="normButton" onClick={copyResults}>
      Copy
    </button>
    <button className="normButton" onClick={clearInputs}>
      Clear
    </button>
  </div>
);

export const ButtonBlockTextArea = ({
  calculate,
  clearInputs,
  textAreaText,
  textAreaOnChange,
}) => (
  <>
    <ButtonBlock calculate={calculate} clearInputs={clearInputs} />
    <textarea
      className="textareaAnswer"
      rows="4"
      id="areaResults"
      value={textAreaText}
      onChange={(e) => {
        textAreaOnChange(e.target.value);
        resizeTextAnswer(e.target.value);
      }}
    />
  </>
);

export const SaveButtonBlock = ({
  project,
  setProject,
  saveInputsHandler,
  saveMessage,
  buttonText,
  inputText,
  inputId,
}) => (
  <>
    {/* Only load the header for the default "save gear" case */}
    {!buttonText && (
      <>
        <hr />
        <div className="card-heading">Save Design</div>
      </>
    )}

    <div className="saveButtonBlock">
      <ProjectDropdown project={project} setProject={setProject} />
      <div style={{ display: "flex" }}>
        <input
          type="text"
          id={inputId ? inputId : "input-gearname"}
          size="24"
          placeholder={inputText ? inputText : "Gear Name"}
        />
        <button className="saveButton" onClick={saveInputsHandler}>
          {buttonText ? buttonText : "Save"}
        </button>
      </div>
    </div>
    {saveMessage && <div>{saveMessage}</div>}
  </>
);
