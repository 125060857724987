export function resizeTextAnswer(textToWrite) {
  // Resizes the output answer box based on the number of newline (\n) characters present
  // TODO: if the row is too long (and would wrap/spill over) it should count as 2 rows

  var textarea = document.getElementById("areaResults");

  // count number of rows
  const count = textToWrite ? (textToWrite.match(/\n/g) || []).length : 0;
  textarea.rows = Math.max(count + 2, 4);
}

export function copyResults(event) {
  var copyText = document.getElementById("areaResults");
  copyText.select();
  navigator.clipboard.writeText(copyText.value);
  event.preventDefault();
  return false;
}

export function logQA(message) {
  //send the message. the message is an object, i.e.:
  //const obj = {name: "John", age: 30, city: "New York"};
  var client = new XMLHttpRequest();
  client.open("POST", "https://oilviscositychart.com/logs/log.php");
  client.setRequestHeader("Content-Type", "application/json");
  client.send(JSON.stringify(message));
}

export function getProjectNameFromID(projectID) {
  // returns the name of a project corresponding to a given project ID

  // used for: take the projectID for the active project (from state)
  // compare this through the list of projects in localStorage and return its name
  var allProjectsStorage;
  try {
    allProjectsStorage = localStorage.getItem("projects"); // get all local storage
    allProjectsStorage = JSON.parse(allProjectsStorage);
  } catch {
    return "";
  }

  for (let i = 0; i < allProjectsStorage["projects"].length; i++) {
    if (allProjectsStorage["projects"][i].projectid == projectID) {
      return allProjectsStorage["projects"][i]["projectname"];
    }
  }

  // If no match
  return "";
}
export function formatDate(d) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    d.getDate().toString().padStart(2, "0") +
    "-" +
    months[d.getMonth()] +
    "-" +
    d.getFullYear()
  );
}
