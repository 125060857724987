import React from "react";
import { useEffect } from "react";
// router
import { Outlet } from "react-router-dom";

// Now import my components
import NavBar from "../navbar";
import SideBar from "../sidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./inside.css";
import "./outside/outside.css";
import "./calculators/calc-styles.css";

// Split into RootInside (for calculators, pages inside the GCS "app") and
// RootOutside (pages accessible outside the app) (no NavBar or sidebar, can have full-width control)

export function RootInside() {
  // Initiate the state with an existing value, if it exists
  const emptyActive = { project: "" };
  var starterValue;
  try {
    starterValue = JSON.parse(localStorage.getItem("activeproject"))
      ? JSON.parse(localStorage.getItem("activeproject"))
      : emptyActive;
  } catch {
    starterValue = emptyActive;
  }
  // Define state using initial value
  // The value and functions get passed down to the NavBar and Outlet
  const [project, setProject] = React.useState(starterValue);

  useEffect(() => {
    // Update the localStorage whenever the active project is updated
    //localStorage.setItem('projects', JSON.stringify(count))
    localStorage.setItem("activeproject", JSON.stringify(project));
  }, [project]);

  // Repeat the same for list of all projects (pulling allProjects into state)

  const emptyAllProjects = { projects: [] };
  var starterAllProjects;
  try {
    starterAllProjects = JSON.parse(localStorage.getItem("projects"))
      ? JSON.parse(localStorage.getItem("projects"))
      : emptyAllProjects;
  } catch {
    starterAllProjects = emptyAllProjects;
  }
  const [allProjects, setAllProjects] = React.useState(starterAllProjects);
  // object allProjects has parameter 'projects' with array of all project names

  useEffect(() => {
    // Update the localStorage whenever the list of all projects is updated
    //localStorage.setItem('projects', JSON.stringify(count))
    localStorage.setItem("projects", JSON.stringify(allProjects));
  }, [allProjects, localStorage.getItem("projects")]);

  // Establish an insecure username for display purposes (not authentication)
  // Use a value if it already exists (to persist over refresh)
  var starterUsername;
  try {
    starterUsername = JSON.parse(localStorage.getItem("currentUser"))
      ? JSON.parse(localStorage.getItem("currentUser")).username
      : "";
  } catch {
    starterUsername = "";
  }
  const [weakUsername, setWeakUsername] = React.useState(starterUsername);
  // NEW: refresh if the JSON element (for username) has changed

  // useEffect(()=>{
  //   // Update the localStorage whenever the current user information is updated
  //   //localStorage.setItem('projects', JSON.stringify(count))
  //   //localStorage.setItem('projects', JSON.stringify(allProjects))
  // },[weakUsername,localStorage.getItem('currentUser')]);

  return (
    <Container fluid className="inside-container">
      <Row>
        <Col>
          <NavBar weakUsername={weakUsername} />
        </Col>
      </Row>
      <Row style={{ paddingTop: 50, margin: "20px 0" }}>
        <Col xs={12} md={3} sm={5}>
          <SideBar weakUsername={weakUsername} />
        </Col>
        <Col xs={12} md={9} sm={7}>
          <Outlet
            context={[
              project,
              setProject,
              allProjects,
              setAllProjects,
              weakUsername,
              setWeakUsername,
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
}

export function RootOutside() {
  // Initiate the state with an existing value, if it exists
  const emptyActive = { project: "" };
  var starterValue;
  try {
    starterValue = JSON.parse(localStorage.getItem("activeproject"))
      ? JSON.parse(localStorage.getItem("activeproject"))
      : emptyActive;
  } catch {
    starterValue = emptyActive;
  }
  // Define state using initial value
  // The value and functions get passed down to the NavBar and Outlet
  const [project, setProject] = React.useState(starterValue);

  useEffect(() => {
    // Update the localStorage whenever the active project is updated
    //localStorage.setItem('projects', JSON.stringify(count))
    localStorage.setItem("activeproject", JSON.stringify(project));
  }, [project]);

  // Repeat the same for list of all projects (pulling allProjects into state)

  const emptyAllProjects = { projects: [] };
  var starterAllProjects;
  try {
    starterAllProjects = JSON.parse(localStorage.getItem("projects"))
      ? JSON.parse(localStorage.getItem("projects"))
      : emptyAllProjects;
  } catch {
    starterAllProjects = emptyAllProjects;
  }
  const [allProjects, setAllProjects] = React.useState(starterAllProjects);
  // object allProjects has parameter 'projects' with array of all project names

  useEffect(() => {
    // Update the localStorage whenever the list of all projects is updated
    //localStorage.setItem('projects', JSON.stringify(count))
    localStorage.setItem("projects", JSON.stringify(allProjects));
  }, [allProjects, localStorage.getItem("projects")]);

  // Establish an insecure username for display purposes (not authentication)
  // Use a value if it already exists (to persist over refresh)
  var starterUsername;
  try {
    starterUsername = JSON.parse(localStorage.getItem("currentUser"))
      ? JSON.parse(localStorage.getItem("currentUser")).username
      : "";
  } catch {
    starterUsername = "";
  }
  const [weakUsername, setWeakUsername] = React.useState(starterUsername);
  // NEW: refresh if the JSON element (for username) has changed

  // useEffect(()=>{
  //   // Update the localStorage whenever the current user information is updated
  //   //localStorage.setItem('projects', JSON.stringify(count))
  //   //localStorage.setItem('projects', JSON.stringify(allProjects))
  // },[weakUsername,localStorage.getItem('currentUser')]);

  return (
    <Container fluid>
      <Outlet
        context={[
          project,
          setProject,
          allProjects,
          setAllProjects,
          weakUsername,
          setWeakUsername,
        ]}
      />
    </Container>
  );
}
