export default function Footer() {
  return (
    <footer>
      <div className="text-body-secondary">
        &copy; 2023 Evolvent Design, LLC
      </div>
      <ul className="nav ">
        <li className="nav-item">
          <a href="/" className="nav-link text-body-secondary">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="/faq" className="nav-link text-body-secondary">
            FAQ
          </a>
        </li>
        <li className="nav-item">
          <a href="/pricing" className="nav-link text-body-secondary">
            Pricing
          </a>
        </li>
      </ul>
    </footer>
  );
}
