import React from "react";

import { saveInputs } from "../calculator-ui/gear-save";
import { LoadSavedGear } from "../calculator-ui/gear-load";
import { useOutletContext } from "react-router-dom";
import {
  getProjectNameFromID,
  resizeTextAnswer,
  formatDate,
  logQA,
} from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
  SaveButtonBlock,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorPitchPlate({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");
  const [project, setProject] = useOutletContext(); // Grab current active project from Outlet (in root.js)
  const [saveMessage, setSaveMessage] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-numteeth").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-module").value = "";
    document.getElementById("input-shift").value = "";
    document.getElementById("input-pindia").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  async function saveInputsHandler(event) {
    // Handle saving (with any calculator-specific details)
    // Set the record type to 'single' for one gear
    // Loading the dropdown should match this record type

    // Get project and save
    var saveInputResponse = await saveInputs(
      event,
      "single",
      project.project,
      false
    );
    setSaveMessage(saveInputResponse);
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }

    var numTeeth = parseFloat(document.getElementById("input-numteeth").value);
    var module = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    var pinDiameter = document.getElementById("input-pindia").value;
    var Xemax = document.getElementById("input-shift").value;

    if (isNaN(Xemax) || Xemax === "") {
      Xemax = 0;
    }

    logQA({
      page: "GCSpitchplate",
      numteeth: numTeeth,
      module: module,
      pangle: pressureAngle,
      pindia: pinDiameter,
      param5: Xemax,
      param7: radioValue,
    });

    var errorMessage = "Error\n";
    var okToCalc = true;
    if (!(Xemax >= -0.6 && Xemax <= 0.6)) {
      okToCalc = false;
      errorMessage = "Profile Shift must be between -0.6 and 0.6\n";
    }

    if (!(numTeeth >= 8 && numTeeth <= 386)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "Number of teeth must be between 8 and 386\n";
    }
    if (!Number.isInteger(numTeeth)) {
      okToCalc = false;
      errorMessage = "Number of teeth must be an integer\n";
    }
    if (!(module >= 0.1 && module <= 256)) {
      okToCalc = false;
      errorMessage = errorMessage + "Module/DP must be between 0.1 and 256\n";
    }
    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage =
        errorMessage + "Pressure Angle must be between 1 and 45 degrees\n";
    }

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }
  }

  function calculateMOW() {
    var radioValue, unitWord;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
      unitWord = "inches";
    } else {
      radioValue = "mm";
      unitWord = "millimeters";
    }
    var z = parseFloat(document.getElementById("input-numteeth").value);
    var m_n = parseFloat(document.getElementById("input-module").value);
    var alpha_n_Degree = parseFloat(
      document.getElementById("input-pangle").value
    );
    var D_d = parseFloat(document.getElementById("input-pindia").value);
    var alpha_n = (parseFloat(alpha_n_Degree) * Math.PI) / 180;
    var m_nInput = m_n; //preserve how m_n was input
    var modOrDP = "MOD";

    // Now for React
    var beta = 0;
    var Xemax = document.getElementById("input-shift").value;
    var Xemin = document.getElementById("input-shift").value;

    if (radioValue === "in") {
      m_n = 25.4 / m_n;
      D_d = D_d * 25.4;
      modOrDP = "DP";
    }
    ///BASIC GEAR DIMENSIONS
    //var P_nd = 25.4/m_n; // # normal diametral pitch (1/in)

    var beta_B = Math.asin(Math.sin(beta) * Math.cos(alpha_n)); //# helix angle (= 0 for spur gears)
    var alpha_t = Math.atan(Math.tan(alpha_n) / Math.cos(beta)); //# transverse pressure angle at reference diameter

    var d = (Math.abs(z) * m_n) / Math.cos(beta); //# reference diameter
    var d_b = d * Math.cos(alpha_t); //# base diameter

    var S_nmax = m_n * (2 * Xemax * Math.tan(alpha_n) + Math.PI / 2); //# nominal normal circular tooth thickness at reference diameter, max
    var eta_bmin =
      (Math.PI - S_nmax / m_n - z * involute(alpha_t)) / Math.abs(z); //# base space width half angle, minimum
    /// END BASIC GEAR DIMENSIONS

    /// ASSUMPTIONS
    var d_La = (Math.abs(z) + 2) * m_n * 0.99; //#tip involute limit diameter # ASSUME 99% of the tooth length is involute profile
    var d_Lf = d_b * 1.01; //# root involute limit diameter # ASSUME the involute profile starts at 1% greater than base diameter
    var d_amax = (z + 2) * m_n; //# ASSUME tip diameter is (N+2)*Module # used in D_da
    var d_fmax = (z - 2) * m_n; //# ASSUME root diameter is (N-2)*Module # used in D_df
    ///  END ASSUMPTIONS
    var alpha_KTmax =
      Math.tan(Math.acos(d_b / d_La)) + (z / Math.abs(z)) * eta_bmin; //# transverse pressure angle to the center of the max diameter pin

    var D_dmax =
      ((z * m_n * Math.cos(alpha_n)) / Math.cos(beta_B) / Math.cos(beta_B)) *
      (Math.tan(alpha_KTmax) - Math.tan(Math.acos(d_b / d_La))); //# Max Pin Size (in mm)

    //# Minimum pin size

    //# Checking the minimum based on the root involute limit diameter
    var S_nmin = m_n * (2 * Xemin * Math.tan(alpha_n) + Math.PI / 2); //# nominal normal circular tooth thickness at reference diameter, minimum
    var eta_bmax =
      (Math.PI - S_nmin / m_n - z * involute(alpha_t)) / Math.abs(z); //# base space width half angle, max

    var alpha_KTLf =
      Math.tan(Math.acos(d_b / d_Lf)) + (z / Math.abs(z)) * eta_bmax;

    var DdLf =
      ((z * m_n * Math.cos(alpha_n)) / Math.cos(beta_B) / Math.cos(beta_B)) *
      (Math.tan(alpha_KTLf) - Math.tan(Math.acos(d_b / d_Lf)));
    //# Checking the minimum based on pin being tangent at the tip diameter (that the pin protrudes for measurement)
    //#for external gears
    var D_da = iterD_da(d_b, d_amax, z, m_n, alpha_n, eta_bmax); //#tip diameter of the gear, maximum

    //# Checking the minimum based on avoiding contact with the root
    var D_df = iterD_df(d_b, d_fmax, eta_bmax, z, m_n, alpha_n);

    //# Determining the actual minimum usable diameter
    var D_dmin = Math.max(DdLf, D_da, D_df);

    //# Make sure that the selected pin is in range!

    var validPin, d_k2max;

    if (D_d < D_dmax && D_d > D_dmin) {
      validPin = true;
      if (z % 2 === 0) {
        //# compensate for odd/even gears
        d_k2max =
          d_kmax(D_d, d_b, S_nmax, alpha_n, z, m_n, alpha_t) +
          (z / Math.abs(z)) * D_d;
      } else {
        d_k2max =
          d_kmax(D_d, d_b, S_nmax, alpha_n, z, m_n, alpha_t) *
            Math.cos(Math.PI / 2 / z) +
          (z / Math.abs(z)) * D_d;
      }
    } else {
      d_k2max = "Pin is out of range";
      validPin = false;
    }

    // CALCULATE DIA FOR PITCH INTERSECTION
    var d_y = z * m_n;
    var eta_bavg =
      (Math.PI - (S_nmax + S_nmin) / (2 * m_n) - z * involute(alpha_t)) /
      Math.abs(z);
    var alpha_ktdy =
      Math.tan(Math.acos(d_b / d_y)) + (z * eta_bavg) / Math.abs(z);

    var D_ddy =
      ((z * m_n * Math.cos(alpha_n)) / Math.cos(beta_B) / Math.cos(beta_B)) *
      (Math.tan(alpha_ktdy) - Math.tan(Math.acos(d_b / d_y)));

    // calculate diameter on which pins are placed to intersect on pitch line

    var d_yPin;
    if (z % 2 === 0) {
      //# compensate for odd/even gears
      d_yPin =
        d_kmax(D_ddy, d_b, S_nmax, alpha_n, z, m_n, alpha_t) +
        (z / Math.abs(z)) * D_ddy -
        D_ddy;
    } else {
      d_yPin =
        d_kmax(D_ddy, d_b, S_nmax, alpha_n, z, m_n, alpha_t) *
          Math.cos(Math.PI / 2 / z) +
        (z / Math.abs(z)) * D_ddy -
        D_ddy;
    }

    var whichPinText = "";
    if (validPin) {
      d_yPin = d_k2max - D_d; // use specified pin if valid
      if (radioValue === "in") {
        whichPinText =
          "\nSpecified pin is valid; using pin diameter " +
          (D_d / 25.4).toFixed(4) +
          " " +
          unitWord +
          " for positioning";
      } else {
        whichPinText =
          "\nSpecified pin is valid; using pin diameter " +
          D_d.toFixed(4) +
          " " +
          unitWord +
          " for positioning";
      }
    } else {
      if (D_d > D_dmax) {
        if (radioValue === "in") {
          whichPinText = "\nSpecified pin is too large"; // Acceptable range is from " + (D_dmin/25.4).toFixed(4) + " to " + (D_dmax/25.4).toFixed(4) + " " + unitWord;
        } else {
          whichPinText = "\nSpecified pin is too large"; // Acceptable range is from " + D_dmin.toFixed(4) + " to " + D_dmax.toFixed(4) + " " + unitWord;
        }
      }
      if (D_d < D_dmin) {
        if (radioValue === "in") {
          whichPinText = "\nSpecified pin is too small"; // Acceptable range is from " + (D_dmin/25.4).toFixed(4) + " to " + (D_dmax/25.4).toFixed(4) + " " + unitWord;
        } else {
          whichPinText = "\nSpecified pin is too small"; // Acceptable range is from " + D_dmin.toFixed(4) + " to " + D_dmax.toFixed(4) + " " + unitWord;
        }
      }
      if (radioValue === "in") {
        whichPinText =
          whichPinText +
          "\nAcceptable range is from " +
          (D_dmin / 25.4).toFixed(4) +
          " to " +
          (D_dmax / 25.4).toFixed(4) +
          " " +
          unitWord +
          "\nUsing pin diameter " +
          (D_ddy / 25.4).toFixed(4) +
          " " +
          unitWord +
          " for positioning";
      } else {
        whichPinText =
          whichPinText +
          "\nAcceptable range is from " +
          D_dmin.toFixed(4) +
          " to " +
          D_dmax.toFixed(4) +
          " " +
          unitWord +
          "\nUsing pin diameter " +
          D_ddy.toFixed(4) +
          " " +
          unitWord +
          " for positioning";
      }
    }

    //Position for each pitch point

    if (radioValue === "in") {
      D_ddy = D_ddy / 25.4;
      d_yPin = d_yPin / 25.4;
    }

    var x_0 = 0;
    var y_0 = d_yPin / 2;
    var theta_1 = (360 / z) * Math.round(z / 3);
    var x_1 = (d_yPin / 2) * Math.cos(theta_1);
    var y_1 = ((-1 * d_yPin) / 2) * Math.sin(theta_1);
    // mirrored for second point
    var x_2 = -1 * x_1;
    var y_2 = y_1;

    var displayProjectName = getProjectNameFromID(project.project);
    // if the user is on "Free" tier, the gearname box won't appear and it will crash
    // default to blank if the input is not shown
    var displayGearName;
    if(document.getElementById("input-gearname")){
      displayGearName = document.getElementById("input-gearname").value
      ? document.getElementById("input-gearname").value
      : "";
    }
    else {
      displayGearName = ""; 
    }

    //Formulate output
    var output = `Gear Specifications:\t${z}T ${m_nInput} ${modOrDP} ${alpha_n_Degree}PA S${Xemax}
Use pins of size ${D_ddy.toFixed(
      4
    )} ${unitWord} to measure on pitch line${whichPinText}

All units below are in ${unitWord}

Positions
X${x_0.toFixed(4)}\tY${y_0.toFixed(4)}
X${x_1.toFixed(4)}\tY${y_1.toFixed(4)}
X${x_2.toFixed(4)}\tY${y_2.toFixed(4)}

Positions (Excel)X\tY
${x_0.toFixed(4)}\t${y_0.toFixed(4)}
${x_1.toFixed(4)}\t${y_1.toFixed(4)}
${x_2.toFixed(4)}\t${y_2.toFixed(4)}

\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}

Project:\t${displayProjectName}
Gear:\t${displayGearName}
GCS0`;

    setTextAreaText(output);
    resizeTextAnswer(output);
  }

  function involute(x) {
    //expects x in radians
    return Math.tan(x) - x;
  }

  function inverseInvolute(x) {
    //returns x in radians
    var maxError = 0.0000000000001;
    //var maxError = 0.0001;
    var high = Math.PI / 2;
    var low = 0;
    var guess = 0.1;
    var i = 0;
    //var maxSteps = 100;

    while (Math.abs(involute(guess) - x) > maxError) {
      i = i + 1;
      if (involute(guess) > x) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }
    }
    return guess;
  }

  function getD_da(internalD_da, d_b, d_amax, z, m_n, alpha_n, eta_bmax) {
    //	# print "in GetDDA"
    //	# print d_b
    //	# print d_amax
    //	# print D_da
    if (d_b / (d_amax - internalD_da) > 1) {
      internalD_da = 0;
      console.log("GUESS OUT OF RANGE");
      //	#raise Exception("DDA failed, acos out of range")
    }
    return (
      (involute(Math.acos(d_b / (d_amax - internalD_da))) + eta_bmax) *
      Math.abs(z) *
      m_n *
      Math.cos(alpha_n)
    );
  }

  function iterD_da(d_b, d_amax, z, m_n, alpha_n, eta_bmax) {
    var guess = m_n * Math.PI;
    var low = eta_bmax * Math.abs(z) * m_n * Math.cos(alpha_n); //# lower bound for D_da
    var j = 0;
    var maxError = 0.0000000000001;
    var maxSteps = 100;
    var high;

    while (
      Math.abs(guess - getD_da(guess, d_b, d_amax, z, m_n, alpha_n, eta_bmax)) >
      maxError
    ) {
      j = j + 1;
      if (guess > getD_da(guess, d_b, d_amax, z, m_n, alpha_n, eta_bmax)) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }

      if (j >= maxSteps) {
        console.log("D_da failed to converge");
      }
    }
    return guess;
  }
  function getD_df(internalD_df, d_b, d_fmax, eta_bmax, z, m_n, alpha_n) {
    // Calculate D_df using D_df
    return (
      (involute(Math.acos(d_b / (d_fmax + internalD_df))) + eta_bmax) *
      Math.abs(z) *
      m_n *
      Math.cos(alpha_n)
    );
  }

  function iterD_df(d_b, d_fmax, eta_bmax, z, m_n, alpha_n) {
    //#print "DDF"
    var guess = m_n * Math.PI;
    var low = eta_bmax * Math.abs(z) * m_n * Math.cos(alpha_n);
    var maxError = 0.0000000000001;
    var high;
    while (
      Math.abs(guess - getD_df(guess, d_b, d_fmax, eta_bmax, z, m_n, alpha_n)) >
      maxError
    ) {
      if (guess > getD_df(guess, d_b, d_fmax, eta_bmax, z, m_n, alpha_n)) {
        high = guess;
        guess = (guess + low) / 2;
      } else {
        low = guess;
        guess = (guess + high) / 2;
      }
    }
    return guess;
  }

  function d_kmax(internalD_d, d_b, S_nmax, alpha_n, z, m_n, alpha_t) {
    return (
      d_b /
      Math.cos(
        inverseInvolute(
          (S_nmax + internalD_d / Math.cos(alpha_n)) / (z * m_n) +
            involute(alpha_t) -
            Math.PI / z
        )
      )
    );
  }
  // function d_kmin(internalD_d, d_b, S_nmin, alpha_n, z, m_n, alpha_t){
  // 	return d_b/(Math.cos(inverseInvolute((S_nmin+internalD_d/Math.cos(alpha_n))/(z*m_n)+involute(alpha_t)-Math.PI/z)))
  // }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Pitch Plate</div>
        <p>
          This calculator gives you the location of three equally-spaced pins
          around your gear, based on the same principles as Measurement over
          Pins.
        </p>
        <p>
          The pin locations can be used to create fixture plates, mounts, or
          even Go/No-Go gauges.
        </p>
        <p>
          Equations conform to ANSI/AGMA 2002-D19 published in December 2019
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col>
            <LoadSavedGear
              reqRecordType="single"
              project={project}
              setProject={setProject}
              notProjectSpecific={false}
              loadCallback={calculate}
              userLevel={userLevel}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth<span className="required">*</span>
                </label>
                <input className="inputbox" type="number" id="input-numteeth" />
              </div>

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pangle">
                  Pressure Angle (degrees)<span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-pangle" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-shift">
                  Manufacturing Profile Shift Coefficient
                  <div className="calctooltip">
                    [?]<span className="tooltiptext">Unitless</span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-shift" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pindia">
                  Pin Diameter
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm for Module, <br />
                      inch for DP
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-pindia" />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />

              {userLevel > 0 && (
                <SaveButtonBlock
                  project={project}
                  setProject={setProject}
                  saveInputsHandler={saveInputsHandler}
                  saveMessage={saveMessage}
                />
              )}
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/pitchplate-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          Outputs are given as the coordinates for the center of each of the
          pins, with X=0 Y=0 at the center of the gear. X is used for
          left/right, and Y for up/down.
        </p>
      </Card>
    </>
  );
}
