import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { DecimalPlacesSelect, ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorFreeHobbing({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  function clearInputs(event) {
    document.getElementById("input-method").value = "tpi";
    document.getElementById("input-dia").value = "";
    document.getElementById("input-pitch").value = "";
    document.getElementById("input-numteeth").value = "";
    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function methodChange() {
    if (document.getElementById("input-method").value === "tpi") {
      document.getElementById("input-dia").placeholder = "Diameter (in)";
      document.getElementById("input-pitch").placeholder = "Threads per inch";
    } else if (document.getElementById("input-method").value === "pitch") {
      document.getElementById("input-dia").placeholder = "Diameter (mm)";
      document.getElementById("input-pitch").placeholder = "Pitch (mm)";
    }
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var validTest = true;

    var precision = parseInt(document.getElementById("precision").value);
    var method = document.getElementById("input-method").value;
    var pitch = parseFloat(document.getElementById("input-pitch").value);
    var numteeth = parseFloat(document.getElementById("input-numteeth").value);
    var diameterInput = parseFloat(document.getElementById("input-dia").value);

    logQA({
      page: "GCSfreehob",
      param5: method,
      param6: pitch,
      param7: numteeth,
      param9: diameterInput,
    });

    var errorMessage;
    if (
      (method === "pitch" || method === "tpi") &&
      (pitch <= 0 || isNaN(pitch))
    ) {
      validTest = false;
      errorMessage = "Pitch and starts must be positive numbers";
    }

    if (!(method === "tpi" || method === "pitch")) {
      validTest = false;
      errorMessage = "Invalid method of calculation";
    }

    if (isNaN(precision)) {
      validTest = false;
      errorMessage = "Number of decimal places must be an integer";
    }

    if (diameterInput <= 0 || isNaN(diameterInput)) {
      validTest = false;
      errorMessage = "Diameter must be a positive value";
    }

    if (validTest) {
      if (method === "tpi") {
        let diameterCalc = diameterInput;
        let pitchCalc = pitch;
        writeAnswer(
          method,
          "in",
          pitch,
          diameterInput,
          numteeth,
          precision,
          diameterCalc,
          pitchCalc
        );
      }
      if (method === "pitch") {
        let diameterCalc = diameterInput / 25.4;
        let pitchCalc = 25.4 / pitch;
        writeAnswer(
          method,
          "mm",
          pitch,
          diameterInput,
          numteeth,
          precision,
          diameterCalc,
          pitchCalc
        );
      }
    } else {
      setTextAreaText("Error\n\n" + errorMessage);
      resizeTextAnswer("Error\n\n" + errorMessage);
    }
  }
  function writeAnswer(
    method,
    diameterUnit,
    pitch,
    diameterInput,
    numteeth,
    precision,
    diameterCalc,
    pitchCalc
  ) {
    // From "Evolvent Patreon Fine Pitch Worm.xlsx"
    let approxDP = pitchCalc * Math.PI;
    let axialCircPitch = 1 / pitchCalc;

    let addendum = 0.32476 * axialCircPitch;
    let effDiameterPL = diameterCalc - 2 * addendum;
    let wormgearODhob = (numteeth * axialCircPitch) / Math.PI;
    let wormgearODtap = ((numteeth - 2) * axialCircPitch) / Math.PI;
    let plungeDepth = 0.59539 * axialCircPitch;

    let tanA = 1 / (Math.PI * effDiameterPL * pitchCalc);
    let leadAngle = Math.atan(tanA);

    let leadAngleDeg = (leadAngle * 180) / Math.PI;
    let degrees = Math.floor(leadAngleDeg);
    let minutes = 60 * (leadAngleDeg - degrees);
    let minutesF = Math.floor(minutes);
    let seconds = Math.floor(60 * (minutes - minutesF));

    var textToWrite = `Inputs\n`;
    if (method === "tpi") {
      textToWrite = textToWrite + `Threads per Inch:\t${pitch}\ttpi`;
    }
    if (method === "pitch") {
      textToWrite = textToWrite + `Pitch:\t${pitch}\tmm`;
    }

    textToWrite =
      textToWrite + `\nDiameter:\t${diameterInput}\t${diameterUnit}`;
    textToWrite = textToWrite + `\nNumber of Teeth:\t${numteeth}`;

    textToWrite =
      textToWrite +
      `

Outputs:
Diametral Pitch:\t${approxDP.toFixed(precision)}\t1/inch
Module:\t${(25.4 / approxDP).toFixed(precision)}\tmm
Axial Circ. Worm Pitch:\t${axialCircPitch.toFixed(precision)}in\t${(
        axialCircPitch * 25.4
      ).toFixed(precision)}\tmm
Addendum:\t${addendum.toFixed(precision)}in\t${(addendum * 25.4).toFixed(
        precision
      )}\tmm
Effective Pitch Diameter:\t${effDiameterPL.toFixed(precision)}in\t${(
        effDiameterPL * 25.4
      ).toFixed(precision)}\tmm
OD Wormgear (Hob):\t${wormgearODhob.toFixed(precision)}in\t${(
        wormgearODhob * 25.4
      ).toFixed(precision)}\tmm
OD Wormgear (Tap):\t${wormgearODtap.toFixed(precision)}in\t${(
        wormgearODtap * 25.4
      ).toFixed(precision)}\tmm
Depth to Plunge:\t${plungeDepth.toFixed(precision)}in\t${(
        plungeDepth * 25.4
      ).toFixed(precision)}\tmm
Lead Angle:\t${leadAngleDeg.toFixed(
        precision
      )}\tdeg\nLead Angle:\t${degrees}\u00B0${minutesF}'${seconds}"`;

    textToWrite =
      textToWrite +
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <div className="project-name">Free-Hobbing</div>
        <p>
          <span className="term-emphasis">Free-Hobbing</span> is the process of
          cutting a wormgear without directly driving the rotation of the gear
          blank, often with a screw tap.
        </p>
        <p>
          The wormgear blank is free to spin, and the tap fed in radially to the
          plunge depth. The teeth on the tap engage on the gear blank, both
          cutting and advancing the blank in a form of "self-indexing"
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-method">Inch / Metric</label>
                <select
                  className="inputbox"
                  name="input-method"
                  id="input-method"
                  onChange={methodChange}
                >
                  <option value="tpi">Inch, TPI</option>
                  <option value="pitch">Metric, mm</option>
                </select>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-dia">Tap Major Diameter</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-dia"
                  placeholder="Tap Diameter (in)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pitch">
                  Tap Thread Pitch
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      TPI for inch; mm for metric
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pitch"
                  placeholder="Threads per inch"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-numteeth">
                  Number of Teeth (Wormgear)
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-numteeth"
                  placeholder="Number of Teeth"
                />
              </div>

              <DecimalPlacesSelect defaultValue={3} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/leadthread-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          The minimum tooth count for the wormgear is:{" "}
          <ul>
            <li>10, for 30º taps (standard)</li>
            <li>40, for 14.5º taps</li>
          </ul>
        </p>
      </Card>
    </>
  );
}
