// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Footer from "./footer";
import PublicHeader from "./header";

export default function PublicFAQPage() {
  const faqData = [
    [
      "Why should I get GearCalculators.com Software?",
      "You could spend a lot of time looking around the internet, plus a lot of money joining a standards organization like AGMA, ISO, BSI or DIN, plus get a math and CS degree and still not get the powerful and useful calculators. When designing gears there are many things to consider like ratios, sizes, strength, lubrication, cutting tools, machine setup, inspection, and more. No one piece of software brings it all together like GearCalculators.com. \n\nDid you go to college and wonder why you only had 1 lecture on gears? GearCalculators are what the tools, knowledge and information would look like if it was a 2 year class on only gears.",
    ],

    [
      "Does GearCalculators.com show English and Metric Units?",
      "Yes, all the calculators have dual output. The generators are written in Module. But you can easily convert to Pitch and the numbers are correct.  Remember the conversion is simple:\n\nModule = 25.4/Pitch\n\nAnd\n\nPitch = 25.4/Module",
    ],

    [
      "What are other professions that could benefit from GCS?",
      "If you want to win F-1, your transmission needs to hit all the shift points-just right.  Horologists and Space Navigators could use a little help with the ratio calculations – quickly please.  Gear manufacturers could probably get their equipment to make higher quality gears with better finish -and- in less time.  STEM teachers can build better higher quality students, they are curious-give them the knowledge.  Inventors can design something extraordinary that is enabled by the combination of tools inside GCS.  Solar Energy installations track the sun, most use a geared system to accomplish the movement.  And there are many more.  There is a person in a garage somewhere that is going to want this.",
    ],

    [
      "How did GearCalculators.com get started?",
      "Our business was started on an optimistic fall day in October 2019, over 4 years ago.  We have seen COVID, upturns, downturns, failures and successes.  We started what has turned into GearCalculators.com out of necessity. \n\nWe started from scratch, knowing nearly nothing about gears. Every time we hit a hurdle we would make an Excel Spreadsheet, then a calculator, and finally software. Then we would read, research, check standards, find other gear manufacturers and tune the calculators so that they were as useful as possible. But that was only part of the journey, we made gears with every tool we had and did a proper inspection.  And these inexpensive and powerful inspection calculators are in our software.  This allowed us to close the loop back to design.  And then we repeated this loop every time we made gears and improved on the system.\n\nIt got started because we needed to produce quality gears to make our living.  Every tool is built to make our day easier at our desks and on the shop floor.  The numbers are tested by mathematicians and engineers, the gears don't lie and are tested on the granite between NIST calibrated micrometers.  The bigger test is done by our customers, who have all turned into repeat customers.  There is creative mental effort and real physical sweat involved in our product development.  ",
    ],

    [
      "It looks like all of your Free online calculators are in this software, so why should I buy it?",
      "There are also many of our best design and manufacturing calculators for solving difficult problems included that are not on our website. Plus, there are many additional features to the existing calculators inside the software, and you can save your gear designs and machine setups for future reference. One of the more advanced features is Profile Shifting which opens up a new dimension to gear design.  Stronger Pinions without undercut, zero-in on fixed center distances with your new ratio design and more...  There are also inspection methods. They are also optimized to give you the best answers possible in a way that is useful and in a format that will import easily into EXCEL. Feeds and speeds, tooling geometry, shafting and keyways and with your feedback an ongoing and improving platform.",
    ],

    [
      " Who and what helped you the most?",
      "Many very nice and intelligent people are in the gear business, the list is very long.  Their advice and pointers have led the way.  Our fantastic customers are also a huge driving force.  They want high quality gears, they don't care how.  The reality of due-dates, quality and quantity of product, payroll, rent, electricity and more force us to work efficiently.  Gearcalculators is behind our ongoing success.  We can't make every gear, but we can certainly help many others start making gears more confidently.",
    ],

    [
      " What are the new calculators we have not seen before on your website?",
      "Feeds and Speeds for Hobbing: There is very little information on this important topic, not to mention a way to keep and record all of your recipes. There are recommended starting points for full manufacturing speeds, Scallop feed limits, hob material information, and a large selection of gear materials to choose from.\n\nRatio Calculators: Given any ratio, determine the four change gears a,b,c,d (2-Stage). Limit these answers to the change gears available with your machine. Tolerance inputs guide the way to practical solutions. We did not solve NP-Complete, but we sure did optimize it to save you time. Go ahead and buy that old machine that makes the part you need and not worry about change gear calculation again.\n\nProfile Shift allows the designer to make a standard gear into a slightly larger or smaller gear.  It is found on many of the calculators but is readily understood if you play with any one of our gear generators (.DXF, .STL, .SVG) that will draw the involute outline.  If you can design it, you can now draw it,  mill-hob-shape it, 3D print it, AND measure it.\n\nCarbide Worm Cutting Tool: Making a worm can be done on a lathe. The shape of the carbide depends on the pressure angle, pitch, module, circular pitch or TPI. With these inputs the carbide geometry such as tip flat, depth of cut and flank angles can be determined. Use your machine shop carbide grinder to make the cutter and you are set. The method works for both Manual and CNC lathes, Module, Pitch and Circular Pitch.\n\nUndercut Flag: This alerts the user to the small tooth pinion problem of undercut gears.\n\nPrime Flag: This alerts the user for every gear design if it is prime or not.\n\nUtility Calculators: Hardness converter, Shaft and Keyway standards (inch and metric), links to gear related NASA information and more.",
    ],

    [
      "Why so much focus on older manual gear manufacturing equipment?",
      "The first CNC hobbing machine was shown at EMO in 1975, showing that CNC was slow to occur. This means that every gear made prior to 1975 was made on a manual machine with change gears. These machines are usually built to last, and can be rebuilt if required. Many of our calculator users have manual machines.\n\nMany of our new calculators like Feeds and Speeds, Profile Shift, Worm Carbide and others are helpful regardless of the type of machine you have Manual or CNC.\n\nThink about it, we went to the Moon in 1969 and the gears needed were built on manual hobbing and shaping machines.",
    ],

    [
      "Who is your most famous user?",
      "You are. \n\nBut if Archimedes was still alive, he would probably have a copy of GearCalculators.com  So would the person that made the Antikythera Mechanism.",
    ],

    [
      "How are Pitch and Module related to each other, and what are their common units?",
      "Yes.  Module = 25.4/Pitch  \n\nModule is in Millimeters (mm) \n\nPitch is in Inches (in) but the variable is inverse of inches so the units are 1/inch",
    ],

    [
      "How are Pitch and Circular Pitch related to each other?",
      "Circular Pitch = π/Pitch\n\nWhile the pitch can be easily converted, the tooth proportions, and other parameters do not convert normal machine hardware bolts to involute gear profiles.",
    ],

    [
      "Why don't gears follow thread nomenclature?",
      "Good question and it has to do with history and math.  But they can be converted to each other in a simple way, Threads per Inch = 1/Circular Pitch ",
    ],

    [
      "Most of your calculators allow any number to be used for Pressure Angle, but there are really only 14.5°, 20°, 22.5° and a few others.  Why?",
      "True - those are some of the standards. But designers can design with non-standard custom parameter if it means extra performance, in the form of less weight, or increased strength. These designs are not standard in that they will require custom cutting tools, but they still are involute gears and all the good properties of gears still applies. The auto industry is consistently designing custom gears, usually to increase mileage. Here custom means they are still standard gears, but in a size that is not commonly made and stocked.  So if you are willing to get custom hobs made and pay/wait for them, you will be rewarded.\n\nWhat parameters can be altered to ANY PRACTICAL NUMBER?  Pressure Angle and Pitch/Module in particular will get you very specific design results.",
    ],
  ];

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />

          <Row style={{ alignItems: "center" }}>
            <Card className="project-card">
              <div className="project-name">
                <h2
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  Frequently Asked Questions
                </h2>
              </div>
              {
                /* Output each question with link to answer*/
                faqData.map((qAndA, index) => {
                  return (
                    <p key={`faq${index}`}>
                      <a className="faq-question-link" href={`#faq-${index}`}>
                        {qAndA[0]}
                      </a>
                    </p>
                  );
                })
              }
            </Card>

            <Card className="project-card">
              {
                /* Output a row for each question and answer, with anchor link*/
                faqData.map((qAndA, index) => {
                  return (
                    <Row
                      className="row-faq"
                      id={"faq-" + index}
                      key={`faq-answer-${index}`}
                    >
                      <Col
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        className="faq-question"
                      >
                        <p style={{ fontWeight: "600" }}>{qAndA[0]}</p>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className="faq-answer"
                      >
                        <p style={{ whiteSpace: "pre-wrap", fontSize: ".9em" }}>
                          {qAndA[1]}
                        </p>
                      </Col>
                    </Row>
                  );
                })
              }
            </Card>
          </Row>
          <Footer></Footer>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}
