import React from "react";

import { ButtonBlock } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorURatio({ pageLevel, userLevel }) {
  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  //	DOM I/O
  var _ratio, _tol, _stages, _pawl, _adddiv, _tally, _minw, _maxw, _stat;
  var _result,
    _calculate,
    _busy,
    _dev,
    _result_text,
    _select1,
    _select2,
    _e1,
    _e2,
    _field,
    _stopa;

  var init_err = 10000000,
    goal_time = 300; //	these should be 'const' but that's not always supported
  var mint = 3,
    maxt = 999;
  var a,
    minc,
    maxc,
    sum,
    savea,
    ers,
    olderr,
    mode = 1; //	0 =running, 1=stopped, -1=interrupted
  var wheels, save_sum, interval, start, time, old_time;
  var ratio, tol, stages, stagesp, minw, maxw, progress_range, decdig;
  var timerID, cc, la, fmin, fmax, save_wheels, fr, rc, rata;

  function clearInputs(event) {
    document.getElementById("ratio").value = "";
    document.getElementById("stages").value = "2";
    document.getElementById("minw").value = "20";
    document.getElementById("maxw").value = "80";

    event.preventDefault();
    return false;
  }

  /* Iterate through multiple configurations
Request is: "Output by Accuracy and then to calculator limits on tooth count (400), but the calculator iterates from 2-12 on accuracy and then changes user tooth-counts to 20-400 for the last reading."
*/
  function iterateResults() {
    // Set inputs, run the calculator, get the results, parse results, change inputs, repeat
    var eachResult = [];
    var allTolerances = [
      0,
      "5e-3",
      "5e-4",
      "5e-5",
      "5e-6",
      "5e-7",
      "5e-8",
      "5e-9",
      "5e-10",
      "5e-11",
      "5e-12",
      "5e-13",
      "5e-14",
      "5e-15",
      "5e-16",
    ];

    // Loop through tolerances
    for (let i = 0; i < allTolerances.length; i++) {
      // Set input
      document.getElementById("tol").value = allTolerances[i];
      calc();
      var getResult = document.getElementById("areaResults").value;
      var parseResult = getResult.split("Gear Train:\t\n")[1];
      eachResult.push(parseResult);
    }
  }

  function sd() {
    //	trigger (select 'change', text 'keyup'), caller (calcr ↓, sr ↓)
    //	show deviation
    //var units_name = ["second", "minute", "hour", "day", "week", "month", "year"]
    var units_val = [1, 60, 3600, 86400, 604800, 2629800, 31557600];
    var factor, e1, select1, e2, select2, a;

    _select1 = document.getElementById("select1"); //TAF-R
    _select2 = document.getElementById("select2");
    _e1 = document.getElementById("e1");
    _e2 = document.getElementById("e2");
    _result = document.getElementById("areaResults");

    a = _e1.value.replace(/,/g, "");
    e1 = +(!isNaN(a) && a);
    a = _e2.value.replace(/,/g, "");
    e2 = +(!isNaN(a) && a);

    select1 = units_val[_select1.value];
    select2 = units_val[_select2.value];

    if (!e1) factor = (e2 * select2) / select1;
    else {
      _e2.value = "";
      factor = select2 / (e1 * select1);
    }

    _dev.innerHTML = "";
    if (!olderr || olderr === init_err) return;

    // a = olderr * 100 / ratio
    // _result.value += "\n\nDeviation: " + a.toFixed(6) + "%" // Modify TAF

    // a = e1 ? e1 : Math.round(olderr / ratio * factor)

    // _result.value += "\n" + a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Modify TAF

    //   a = Math.round(ratio / olderr / factor)
    //   _result.value += " in\t" + a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Modify TAF
  }

  //********************************************************************************************************************************************

  function fac(n) {
    //	caller (calcc ↓)
    //	find largest prime factor
    if (n < 2) return 0;

    while ((n & 1) === 0) n /= 2;

    if (n === 1) return 2;

    var oldn = n;
    var maxd = Math.floor(Math.sqrt(n));
    for (var d = 3; d <= maxd; d += 2) {
      while (0 === n % d) n /= d;
      if (n !== oldn) {
        if (n === 1) return d;
        maxd = Math.floor(Math.sqrt(n));
        oldn = n;
      }
    }
    return n;
  }

  //********************************************************************************************************************************************

  function pk(n, s, su, norm) {
    //	caller (pk =, sg ↓)
    //	pack
    //	bin packing optimum / balanced - find gears with smallest wheels
    if (s === 1) {
      if (su + n < sum) {
        sum = su + n;
        wheels[0] = n;
        save_wheels = wheels.slice(0);
      }
      return;
    }
    var l =
      s === 2
        ? Math.ceil(Math.sqrt(n))
        : Math.max(Math.ceil(n / fmax[s]), minw);
    for (var t = Math.min(Math.floor(n / fmin[s]), maxw); t >= l; t--) {
      if (!(n % t)) {
        wheels[s - 1] = t;
        pk(n / t, s - 1, su + (norm ? t : 0), 1);
      }
    }
  }

  //********************************************************************************************************************************************

  function sg() {
    //	caller (sr ↓)
    //	show gears
    wheels = [];
    sum = maxw * stages + 1;
    pk(savea, stagesp, 0, 1);
    var save_wheelsa = save_wheels.slice(0);
    save_sum = sum;

    wheels = [];
    sum = maxw * stages + 1;
    pk(Math.round(savea * ratio), stages, 0, !_pawl.checked || _tally.checked);
    save_sum += sum;

    var res = _pawl.checked
      ? "R" + save_wheels[save_wheels.length - 1] + ","
      : "";

    function wp(number) {
      var wspaces;
      if (number > 999) wspaces = "";
      if (number < 1000 && number > 99) wspaces = " ";
      if (number < 100 && number > 9) wspaces = "  ";
      if (number < 10) wspaces = "   ";
      return wspaces + number;
    }

    // OUTPUT OF GEARS
    // output is inverted if ratio >1, so we need to correct for that.
    var inputRatio = parseFloat(document.getElementById("ratio").value);
    if (inputRatio < 1) {
      if (stagesp === 2) {
        // Two stages, most common

        let calcRatio =
          (save_wheelsa[0] * save_wheelsa[1]) / save_wheels[0] / save_wheels[1];
        let distance = Math.abs(
          inputRatio -
            (save_wheelsa[0] * save_wheelsa[1]) /
              save_wheels[0] /
              save_wheels[1]
        ).toFixed(12);
        let flagA = distance < 10 ** -12 ? "\tA" : "\t ";
        let flagB =
          save_wheels[0] >= save_wheelsa[1] + save_wheels[1] ? "\tB" : "\t ";
        let flagC =
          save_wheelsa[1] >= save_wheelsa[0] + save_wheels[0] ? "\tC" : "\t ";
        res += `
\t   a\t   b\t   c\t   d\t         ratio\t      distance\tA\tB\tC
\t${wp(save_wheelsa[0])}\t${wp(save_wheels[0])}\t${wp(save_wheelsa[1])}\t${wp(
          save_wheels[1]
        )}\t${calcRatio.toFixed(12)}\t${distance}`;
        res += flagA;
        res += flagB;
        res += flagC;
      } else {
        for (var i = stagesp - 1; i >= 0; i-- && (res += ","))
          res += rc
            ? save_wheelsa[i] + ":" + save_wheels[i]
            : save_wheels[i] + ":" + save_wheelsa[i];
      }
    } else {
      //ratio is greater than 1, so a/b and c/d are flipped
      if (stagesp === 2) {
        // Two stages, most common
        let calcRatio =
          (save_wheels[0] * save_wheels[1]) / save_wheelsa[0] / save_wheelsa[1];
        let distance = Math.abs(
          inputRatio -
            (save_wheels[0] * save_wheels[1]) /
              save_wheelsa[0] /
              save_wheelsa[1]
        ).toFixed(12);
        let flagA = distance < 10 ** -12 ? "\tA" : "\t ";
        let flagB =
          save_wheels[0] >= save_wheelsa[1] + save_wheels[1] ? "\tB" : "\t ";
        let flagC =
          save_wheelsa[1] >= save_wheelsa[0] + save_wheels[0] ? "\tC" : "\t ";

        res += `
\t   a\t   b\t   c\t   d\t         ratio\t      distance\tA\tB\tC
\t${wp(save_wheels[0])}\t${wp(save_wheelsa[0])}\t${wp(save_wheels[1])}\t${wp(
          save_wheelsa[1]
        )}\t${calcRatio.toFixed(12)}\t${distance}`;
        res += flagA;
        res += flagB;
        res += flagC;
      } else {
        for (let i = stagesp - 1; i >= 0; i-- && (res += ","))
          res += rc
            ? save_wheelsa[i] + ":" + save_wheels[i]
            : save_wheels[i] + ":" + save_wheelsa[i];
      }
    }
    return res;
  }

  //********************************************************************************************************************************************

  function tm(m) {
    //	caller (calcc ↓, dp ↓, stop ↓)
    _calculate = document.getElementById("calculate");
    _result = document.getElementById("areaResults");
    _busy = document.getElementById("busy");
    _field = document.getElementById("field");
    _stopa = document.getElementById("stopa");
    _result_text = document.getElementById("result_text");
    //	task mode set 0=running, 1=stopped, -1=interrupted
    var b = (mode = m) & 1;
    if (b) clearInterval(timerID);
    _calculate.disabled = _field.disabled = !b;
    _calculate.title = b ? "begin calculation" : "\u2026 busy right now";
    if (0 > m) {
      //TODO FIX
      _result_text.innerHTML =
        '<span style="color:red">Calculation was INTERRUPTED!</span>';
      if (olderr === init_err) _result.value = ""; //	clear out "please wait"
    } else m && (_result_text.innerHTML = "Compound Result"); // TAF fix, add underscore
    _busy.style.visibility = _stopa.style.visibility = b ? "hidden" : "visible";
  }

  //********************************************************************************************************************************************

  function se() {
    //	caller (calcr ↓, sr ↓)
    // DISABLE ALL ERROR OUTPUT
    /*
    //	show error
    _result.value += "\nTotal Teeth: " + save_sum
    if (stages && _pawl.checked)
      _result.value += _tally.checked ? " total" : " excluding ratchet"
    if (!olderr)
      return
    var oerr = Math.abs(ers - rata)
    _result.value += "\nError: " + (ers > rata ? "+" : "-") + oerr.toFixed(decdig) + "\n"
    _result.value += rata.toFixed(decdig) + " \u2190target\n" + ers.toFixed(decdig) + " \u2190gears\n"
    _result.value += "Accurate to " + Math.floor(Math.log(oerr * 2) / - Math.LN10) + " decimal digits."
    */
    _result.value += "\nRatio:\t" + ers.toFixed(decdig);
  }

  //********************************************************************************************************************************************

  function sr() {
    //	caller (dp ↓, srt ↓)
    _result = document.getElementById("areaResults");
    _stat = document.getElementById("stat");
    _ratio = document.getElementById("ratio");
    //	show result
    if (olderr === init_err) {
      _result.value = "NOT POSSIBLE";
      return;
    }
    // MODIFY - TAF (display inputs)
    _result.value = `Input
Target Ratio:\t${_ratio.value}
Number of Stages:\t${stages}
Min Toothcount:\t${minw}
Max Toothcount:\t${maxw}

Output
`;
    _result.value += "Gear Train:\t" + sg() + "\n";
    ers = Math.round(savea * ratio) / savea;
    if (rc) ers = 1 / ers;
    se();
    sd();
    _stat.title = "Calculation time: " + (time / 1000).toFixed(2);
  }

  //********************************************************************************************************************************************

  function srt() {
    //	trigger (checkbox 'change')
    //	show result (tally)
    stages !== stagesp && sr();
  }

  //********************************************************************************************************************************************

  function stop() {
    //	trigger (span 'click')
    //	user pressed "STOP"
    if (mode) {
      //alert("Nothing running.")
      return;
    }
    if (!window.confirm("Taking too long! STOP program.")) return;
    var now_time = new Date().getTime();
    time = now_time - start;
    _stat.title = "Calculation time: " + (time / 1000).toFixed(2);
    tm(-1);
  }

  //********************************************************************************************************************************************

  function up() {
    //	caller (dp ↓)
    //	update progress bar
    if (++cc & 3) return;
    _busy.innerHTML = "&#" + (((cc >> 2) % 12) + 128336) + ";";
    var t = Math.round(((a - minc) / progress_range) * 100);
    if (la === t) return;
    la = t;
    _result_text.innerHTML = "Intermediate Result &nbsp; " + t + "&#37;"; // TAF
  }

  //********************************************************************************************************************************************

  function pkbo(n, s) {
    //	caller (pkb ↓, pkbo =)
    //	packable check
    //	recursive off-line bin packing - check if there are ANY ways to pack
    if (1 === s) return n <= maxw && n >= minw;
    var l = Math.max(Math.floor(Math.pow(n, 1 / s)), minw); //	should be ceil but roundoff error may miss perfect roots
    var t = Math.min(maxw, Math.floor(n / fmin[s]));
    for (t & 1 || t--; t >= l; t -= 2) {
      if (!(n % t) && pkbo(n / t, s - 1)) return 1;
    }
    return 0;
  }

  //********************************************************************************************************************************************

  function pkb(n, s) {
    //	caller (dp ↓, pkb =)
    //	packable check
    //	recursive off-line bin packing - check if there are ANY ways to pack
    if (n & 1) return pkbo(n, s);
    if (1 === s) return n <= maxw && n >= minw;
    var l = Math.max(Math.floor(Math.pow(n, 1 / s)), minw); //	should be ceil but roundoff error may miss perfect roots
    var t = Math.min(maxw, Math.floor(n / fmin[s])) + 1;
    while (--t >= l) {
      if (!(n % t) && pkb(n / t, s - 1)) return 1;
    }
    return 0;
  }

  //********************************************************************************************************************************************

  function dp() {
    //	callback (setInterval)
    //	do part
    var b,
      err,
      now_time,
      factor,
      savea1 = savea,
      olderr1 = olderr,
      a1 = a,
      abs = Math.abs;
    var stages1 = stages,
      stagesp1 = stagesp,
      tol1 = tol;
    var chunk = Math.min(a1-- + interval, maxc);
    var round =
      fr > chunk
        ? function (a) {
            return (a + 0.5) >> 0;
          }
        : Math.round;
    while (++a1 < chunk) {
      b = round(a1 * ratio);
      if (olderr1 > (err = abs(ratio - b / a1))) {
        if (!pkb(b, stages1)) continue;
        if (!pkb(a1, stagesp1)) continue;
        savea1 = a1;
        if (tol1 >= (olderr1 = err)) break;
      }
    }
    a = a1;
    olderr = olderr1;

    now_time = new Date().getTime();
    if (olderr1 > tol && chunk < maxc) {
      //	more to do so let interval timer trigger next chunk
      factor = goal_time / (now_time - old_time);
      if (factor < 0.7 || factor > 1.4) {
        factor = Math.min(Math.max(factor, 0.5), 2.0);
        interval = Math.max(Math.round(interval * factor), 1000);
      }
      old_time = now_time;
      up();
      if (savea !== savea1) {
        savea = savea1;
        time = now_time - start;
        sr();
      }
      return;
    }

    time = now_time - start;
    tm(1);
    savea = savea1;
    sr();
  }

  //********************************************************************************************************************************************

  function gsf() {
    //	caller (calcc ↓)
    //	generate stage factors
    fmin = [1, 1]; //	preload with extra element so that later indexing does need an offset
    fmax = [1, 1];
    for (var i = 1, k = 1, h = 1; i < stages; i++) {
      fmin.push((k *= minw));
      fmax.push((h *= maxw));
    }
  }

  //********************************************************************************************************************************************

  function calcc() {
    //	caller (calc ↓)
    // TAF-R
    _tol = document.getElementById("tol");
    _pawl = document.getElementById("pawl");
    _result = document.getElementById("areaResults");
    _dev = document.getElementById("dev");

    //	setup
    tol = +_tol.value;
    stages += _pawl.checked;
    if ((rc = ratio < 1)) ratio = 1 / ratio;

    if ((ratio | 0) === ratio && maxw < fac(ratio)) {
      _result.value = "Ratio is not divisible<br>by any gear size.";
      return;
    }

    maxc = Math.round(Math.pow(maxw, stages) / ratio) + 1;
    var maxc2 = Math.floor(Math.pow(maxw, stagesp)) + 1;

    if (maxc2 < maxc) maxc = maxc2;

    olderr = init_err;
    savea = 0;

    a = minc = Math.round(Math.pow(minw, stagesp));

    interval = 1000;

    gsf();

    progress_range = Math.max(maxc - minc, 1);

    _result.value = "please wait\u2026"; //	clear out any previous stuff
    _dev.innerHTML = "";
    tm(0);
    cc = la = -1;
    timerID = setInterval(dp, 0);
    fr = (2147483647 / ratio) >> 0;
    old_time = start = new Date().getTime();
  }

  //********************************************************************************************************************************************

  function calcr() {
    //	caller (calc ↓)
    //	Reverted gear train has its first and third wheel share a common axle, although rotating independently
    //	while the second compound gear idler is on a separate axis.
    //	Calculate wheel sizes in a 2-stage train a, b, c, d for a given reduction ratio R such that
    //	(b / a) * (d / c) = R and collinear axle is maintained by a + b = c + d

    // TAF-R
    _result = document.getElementById("areaResults");
    _dev = document.getElementById("dev");
    var a, b, c, d, err, tot;
    var savea = 0,
      saveb = 0,
      savec = 0,
      saved = 0;
    save_sum = maxt * 2;
    olderr = init_err;

    //	search all gear sizes for a, b
    for (a = minw; a <= maxw; a++) {
      if (minw > Math.round((a + maxw) / ((ratio * a) / maxw + 1))) break; //	early out gear b can never be big enough
      for (b = minw; b <= maxw; b++) {
        c = Math.round((a + b) / ((ratio * a) / b + 1)); //	infer gear c
        if (c > maxw) break; //	early out gear c is already too big
        if (c < minw) continue;
        d = a + b - c; //	infer gear d
        if (d < minw) break; //	early out gear d is already too small
        if (d > maxw) continue;
        err = Math.abs((b * d) / (a * c) - ratio); //	check accuracy
        if (err > olderr) continue;
        tot = a + b;
        if (err < olderr || tot < save_sum) {
          olderr = err;
          save_sum = tot;
          savea = a;
          saveb = b;
          savec = c;
          saved = d;
        }
      }
    }
    _result.value = "Reverted Result\n"; // TAF
    //_result.value = "" // TAF
    _dev.innerHTML = "";

    if (olderr === init_err) {
      _result.value += "\nNot Possible";
      return;
    }

    _result.value += saveb + ":" + savea + "," + saved + ":" + savec;
    // if (Math.max(saveb, savec) < Math.max(savea, saved))	//	ensure idler has smaller wheel
    //   _result.value = "<b>" + savea + ":" + saveb + "," + savec + ":" + saved + "</b>"
    // else
    //   _result.value = "<b>" + savec + ":" + saved + "," + savea + ":" + saveb + "</b>"
    ers = (saveb * saved) / (savea * savec);
    save_sum <<= 1;
    se();
    sd();
  }

  //********************************************************************************************************************************************

  function ta() {
    //	trigger (checkbox 'change')
    //	toggle add checkbox
    _adddiv.style.visibility = _pawl.checked ? "visible" : "hidden";
  }

  //********************************************************************************************************************************************

  //	we are going to allow user to enter an expression using more conventional math functions rather than JavaScript
  //	convert to JavaScript safely

  function func() {
    //	caller (calc ↓)
    var _ratio = document.getElementById("ratio"); // TAF-R

    var from = [
      "acos",
      "asin",
      "atan",
      "cos",
      "exp",
      "log",
      "pi",
      "pow",
      "sin",
      "sqrt",
      "tan",
    ];
    var ratio;

    //	extract all potential math function names and compare with above list of allowable fns
    var rat = _ratio.value.toLowerCase();
    var words = rat.split(/[^a-zA-Z]+/);
    for (var i = 0; i < words.length; i++) {
      if ("" === words[i]) continue; //	splitter puts trailing empty string
      if (from.indexOf(words[i]) < 0) {
        _result_text.innerHTML = 'unrecognized "' + words[i] + '"';
        return -1;
      }
    }
    //	convert math functions to JavaScript syntax
    for (i = 0; i < from.length; i++)
      rat = rat.replace(
        new RegExp(from[i], "g"),
        "Math." + (from[i] === "pi" ? "PI" : from[i])
      );
    rat = rat.replace(new RegExp("Math.aMath.", "g"), "Math.a"); //	fix any doubly converted functions e.g. acos -> Math.aMath.cos
    rat = rat.replace(/:/g, "/"); //	allow ":" (ratio) like divide
    try {
      ratio = eval(rat);
    } catch (err) {
      //	syntax problem
      return -1;
    }
    if (isNaN(ratio))
      //	legal JavasScript but doesn't evaluate to simple number
      return -1;
    ratio = Number(ratio); //	in case it's a boolean type convert to 0 or 1
    if (ratio < 0 || !isFinite(ratio)) ratio = 0; //	ratio must be positive, not be infinity (from division by zero)
    return ratio;
  }

  //********************************************************************************************************************************************

  function calc() {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    //	trigger (button 'click')
    // TODO: Fix (_stat is the image)
    _stat = document.getElementById("stat"); //TAF-R
    _minw = document.getElementById("minw");
    _maxw = document.getElementById("maxw");
    _stages = document.getElementById("stages");
    _result = document.getElementById("areaResults"); // TAF-R

    _stat.title = "";
    rata = ratio = func();
    if (0 > ratio) {
      _result.value = "Error\nRatio must be positive"; // Modify TAF
      //_dev.innerHTML = _result.value = "" // Modify
      return;
    }
    decdig = Math.max(16 - Math.floor(ratio).toString().length, 0);
    _minw.value = minw = Math.min(
      Math.max(+(!isNaN(_minw.value) && _minw.value), mint),
      maxt - 1
    );
    _maxw.value = maxw = Math.min(
      Math.max(+(!isNaN(_maxw.value) && _maxw.value), minw + 1),
      maxt
    );
    (stagesp = stages = +_stages.value) ? calcc() : calcr();
  }

  //********************************************************************************************************************************************

  function typ() {
    //	trigger (select 'change')
    /* // Style changes based on ratchet and reverted
    var b = +_stages.value
    _backg.style.backgroundColor = b ? "#e0e0ff" : "#ffe0e0"
    _title.innerHTML = b ? "Compound" : "Reverted"
    _rat.style.visibility = _acc1.style.visibility = _acc2.style.visibility = b ? "visible" : "hidden"
    _adddiv.style.visibility = b && _pawl.checked ? "visible" : "hidden"
    */
  }

  //********************************************************************************************************************************************

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">
          Change Gears Optimized (unique solution)
        </div>
        <p>
          This calculator will output one possible change gear combination,
          within a specified tolerance, using only the range of change gears
          provided (default: 20-80).
        </p>
        <p>
          For most machines, the target ratio will be (Machine Constant / #
          Teeth), but this calculator will accept any arbitrary ratio.
          Tolerances can be given as an exact match, or the required number of
          digits of precision.
        </p>
        <p>
          Varying the tolerance can give different results; the calculator
          returns the "lowest total tooth count" solution that meets the
          required tolerance.
        </p>
        <p>
          Increasing the number of stages gives multi-stage gearboxes, but
          increases calculator runtime.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <span id="title"></span>
            <form className="calculator" id="field">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="ratio">Ratio</label>
                <input className="inputbox" type="number" id="ratio" />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="stages">Stages</label>
                <select
                  className="inputbox"
                  id="stages"
                  onChange={typ}
                  defaultValue="2"
                >
                  <option value="0">Reverted </option>
                  <option>1</option>
                  <option value="2">2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                </select>
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="minw">Minimum Toothcount</label>
                <input
                  className="inputbox"
                  type="number"
                  id="minw"
                  pattern="\d*"
                  defaultValue="20"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="maxw">Maximum Toothcount</label>
                <input
                  className="inputbox"
                  type="number"
                  id="maxw"
                  pattern="\d*"
                  defaultValue="80"
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <span id="acc1" style={{ visibility: "visible" }}>
                  <label htmlFor="tol">Accuracy:</label>
                </span>
                <span id="acc2" style={{ visibility: "visible" }}>
                  <select className="inputbox" id="tol" defaultValue="0">
                    <option value="0">=</option>
                    <option value="5e-3">2</option>
                    <option value="5e-4">3</option>
                    <option value="5e-5">4</option>
                    <option value="5e-6">5</option>
                    <option value="5e-7">6</option>
                    <option value="5e-8">7</option>
                    <option value="5e-9">8</option>
                    <option value="5e-10">9</option>
                    <option value="5e-11">10</option>
                    <option value="5e-12">11</option>
                    <option value="5e-13">12</option>
                    <option value="5e-14">13</option>
                    <option value="5e-15">14</option>
                    <option value="5e-16">15</option>
                    <option value="0">=</option>
                  </select>{" "}
                  digits
                </span>
              </div>

              <ButtonBlock calculate={calc} clearInputs={clearInputs} />
            </form>

            <div style={{ display: "none" }}>
              <table width="25%" style={{ margin: 0, fontSize: 0 + "px" }}>
                <tbody>
                  <tr>
                    <td
                      width="33%"
                      style={{ textAlign: "center", verticalAlign: "bottom" }}
                    >
                      <span
                        id="busy"
                        style={{ fontSize: 36 + "px", visibility: "hidden" }}
                      >
                        🕐
                      </span>
                    </td>
                    <td
                      width="33%"
                      style={{ textAlign: "center", verticalAlign: "bottom" }}
                    >
                      <img
                        id="stat"
                        width="48"
                        height="16"
                        title="Calculation time: 0.01"
                        alt="missing"
                        src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                        border="0"
                      />
                      <br />
                    </td>
                    <td
                      width="33%"
                      style={{ textAlign: "center", verticalAlign: "bottom" }}
                    >
                      <span
                        id="stopa"
                        title="Click To Interrupt"
                        style={{ visibility: "hidden" }}
                        onClick={stop}
                      >
                        <svg width="32" height="32">
                          <polygon
                            points="32 22.63 22.63 32 9.37 32 0 22.63 0 9.37 9.37 0 22.63 0 32 9.37"
                            fill="red"
                          ></polygon>
                          <text
                            x="50%"
                            y="50%"
                            fontSize="11"
                            fontWeight="bold"
                            fill="white"
                            dominantBaseline="central"
                            textAnchor="middle"
                          >
                            STOP
                          </text>
                        </svg>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span id="result_text"></span>
            <textarea
              className="textareaAnswer"
              rows="20"
              id="areaResults"
            ></textarea>

            <span style={{ display: "none" }}>
              <span id="dev"></span>
              Show deviation in terms of:
              <br />
              Percentage — digits:
              <select id="select3" onChange={sd} defaultValue="1">
                <option>0</option>
                <option value="1">1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
              <br />
            </span>

            <span style={{ display: "none" }}>
              Scale proportions:
              <input id="e1" size="5" pattern="\d*" alue="1" onKeyUp={sd} />
              <select id="select1" onChange={sd} defaultValue="0">
                <option value="0">second</option>
                <option value="1">minute</option>
                <option value="2">hour</option>
                <option value="3">day</option>
                <option value="4">week</option>
                <option value="5">month</option>
                <option value="6">year</option>
              </select>
              &nbsp;in &nbsp;
              <input
                id="e2"
                size="5"
                pattern="\d*"
                defaultValue=""
                onKeyUp={sd}
              />
              <select id="select2" onChange={sd} defaultValue="0">
                <option value="0">second</option>
                <option value="1">minute</option>
                <option value="2">hour</option>
                <option value="3">day</option>
                <option value="4">week</option>
                <option value="5">month</option>
                <option value="6">year</option>
              </select>
            </span>

            <span style={{ display: "none" }}>
              <span id="rat" style={{ visibility: "hidden" }}>
                &nbsp;
                <label>
                  Ratchet:
                  <input
                    type="checkbox"
                    id="pawl"
                    title="1st stage is pawl &amp; ratchet"
                    onChange={ta}
                  />
                </label>
              </span>
              &nbsp;
              <span id="adddiv" style={{ visibility: "hidden" }}>
                <label>
                  Tally:
                  <input
                    type="checkbox"
                    id="tally"
                    title="ratchet&#39;s teeth are added"
                    onChange={srt}
                    checked=""
                  />
                </label>
              </span>
            </span>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/cgdb-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        Flags are:
        <ul>
          <li>A: Exact match, distance &lt; 10^-12</li>
          <li>B: b &gt;= c+d, collision risk with gear B</li>
          <li>C: c &gt;= a+b, collision risk with gear C</li>
        </ul>
        <p>
          This calculator is highly optimized to reduce computation time and
          will speed up your work time. When using two stages, this calculator
          typically returns a solution (if one exists) in less than one second -
          the equivalent of the brute force calculator make take up 60 seconds
          or more.
        </p>
      </Card>
    </>
  );
}
