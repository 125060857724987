import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorRatio({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-speed").value = "";
    document.getElementById("input-1").value = "";
    document.getElementById("input-2").value = "";
    document.getElementById("input-3").value = "";
    document.getElementById("input-4").value = "";
    document.getElementById("input-5").value = "";
    document.getElementById("input-6").value = "";
    document.getElementById("input-7").value = "";
    document.getElementById("input-8").value = "";

    document.getElementById("shaft2").checked = false;
    document.getElementById("shaft3").checked = false;
    document.getElementById("shaft4").checked = false;
    document.getElementById("shaft5").checked = false;
    document.getElementById("shaft6").checked = false;
    document.getElementById("shaft7").checked = false;
    document.getElementById("shaft8").checked = false;

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    //var precision = document.getElementsByName('contact[note][Number of Decimal Places]')[0].value;
    var precision = 4;
    var speed1 = parseFloat(document.getElementById("input-speed").value);
    var teeth1 = parseFloat(document.getElementById("input-1").value);
    var teeth2 = parseFloat(document.getElementById("input-2").value);
    var teeth3 = parseFloat(document.getElementById("input-3").value);
    var teeth4 = parseFloat(document.getElementById("input-4").value);
    var teeth5 = parseFloat(document.getElementById("input-5").value);
    var teeth6 = parseFloat(document.getElementById("input-6").value);
    var teeth7 = parseFloat(document.getElementById("input-7").value);
    var teeth8 = parseFloat(document.getElementById("input-8").value);

    logQA({
      page: "GCSratio",
      numteeth: teeth1,
      module: teeth2,
      pangle: teeth3,
      pindia: teeth4,
      param5: teeth5,
      param6: teeth6,
      param7: teeth7,
      param8: teeth8,
      param9: speed1,
    });

    var shaft2 = document.getElementById("shaft2").checked;
    var shaft3 = document.getElementById("shaft3").checked;
    var shaft4 = document.getElementById("shaft4").checked;
    var shaft5 = document.getElementById("shaft5").checked;
    var shaft6 = document.getElementById("shaft6").checked;
    var shaft7 = document.getElementById("shaft7").checked;
    var shaft8 = document.getElementById("shaft8").checked;

    var ratio1 = 1;
    var ratio2;
    var ratio3;
    var ratio4;
    var ratio5;
    var ratio6;
    var ratio7;
    var ratio8;

    var finalRatio = 1;
    var keepGoing = true;

    if (shaft2) {
      ratio2 = ratio1;
    } else {
      ratio2 = teeth1 / teeth2;
    }

    if (shaft3) {
      ratio3 = ratio2;
      if (isNaN(ratio3) || !isFinite(ratio3)) {
        finalRatio = ratio2;
        keepGoing = false;
      }
    } else {
      ratio3 = (ratio2 * teeth2) / teeth3;
      if (isNaN(ratio3) || !isFinite(ratio3)) {
        finalRatio = ratio2;
        keepGoing = false;
      }
    }
    if (shaft4 && keepGoing) {
      ratio4 = ratio3;
      if (isNaN(ratio4) || !isFinite(ratio4)) {
        finalRatio = ratio3;
        keepGoing = false;
      }
    } else if (keepGoing) {
      ratio4 = (ratio3 * teeth3) / teeth4;
      if (isNaN(ratio4) || !isFinite(ratio4)) {
        finalRatio = ratio3;
        keepGoing = false;
      }
    }
    if (shaft5 && keepGoing) {
      ratio5 = ratio4;
      if (isNaN(ratio5) || !isFinite(ratio5)) {
        finalRatio = ratio4;
        keepGoing = false;
      }
    } else if (keepGoing) {
      ratio5 = (ratio4 * teeth4) / teeth5;
      if (isNaN(ratio5) || !isFinite(ratio5)) {
        finalRatio = ratio4;
        keepGoing = false;
      }
    }
    if (shaft6 && keepGoing) {
      ratio6 = ratio5;
      if (isNaN(ratio6) || !isFinite(ratio6)) {
        finalRatio = ratio5;
        keepGoing = false;
      }
    } else if (keepGoing) {
      ratio6 = (ratio5 * teeth5) / teeth6;
      if (isNaN(ratio6) || !isFinite(ratio6)) {
        finalRatio = ratio5;
        keepGoing = false;
      }
    }
    if (shaft7 && keepGoing) {
      ratio7 = ratio6;
      if (isNaN(ratio7) || !isFinite(ratio7)) {
        finalRatio = ratio6;
        keepGoing = false;
      }
    } else if (keepGoing) {
      ratio7 = (ratio6 * teeth6) / teeth7;
      if (isNaN(ratio7) || !isFinite(ratio7)) {
        finalRatio = ratio6;
        keepGoing = false;
      }
    }
    if (shaft8 && keepGoing) {
      ratio8 = ratio7;
      if (isNaN(ratio8) || !isFinite(ratio8)) {
        finalRatio = ratio7;
        keepGoing = false;
      } else if (keepGoing) {
        finalRatio = ratio8;
        keepGoing = false;
      }
    } else if (keepGoing) {
      ratio8 = (ratio7 * teeth7) / teeth8;
      if (isNaN(ratio8) || !isFinite(ratio8)) {
        finalRatio = ratio7;
        keepGoing = false;
      } else {
        finalRatio = ratio8;
        keepGoing = false;
      }
    }

    if (isNaN(speed1)) {
      speed1 = "Undefined";
    }
    var outputspeed = (finalRatio * speed1).toFixed(precision);
    if (isNaN(outputspeed)) {
      outputspeed = "Undefined";
    }

    var textToWrite = "Gear Ratio:\t" + finalRatio.toFixed(precision) + "\n\n";

    textToWrite = textToWrite + "Input Speed:\t" + speed1;
    textToWrite =
      textToWrite +
      "\nOutput Speed:\t" +
      outputspeed +
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design, " +
      formatDate(new Date()) +
      "\nGCS0";
    //  textToWrite = textToWrite + "\n\n" + ratio1 + ratio2 + ratio3 + ratio4 + ratio5 + ratio6 + ratio7 + ratio8;
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Ratio</div>
        <p>
          Calculate the speed and mechanical advantage of a gear system of
          cylindrical (spur, helix, etc.) gears.
        </p>
        <p>
          {" "}
          Gear ratio is determined solely by the number of teeth on each gear.
          Check the box if the gear is on the same shaft as the gear above it,
          leave it unchecked if the gear meshes with the gear above it.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form>
              <div style={{ marginBottom: 10 + "px" }}>
                <label>Input Gear Speed (rpm)</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-speed"
                  placeholder="(optional)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Input Gear</label>
                <input className="inputbox" type="number" id="input-1" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 2</label>
                <input className="inputbox" type="number" id="input-2" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft2"
                    name="shaft2"
                    value="Checked"
                  />
                  <label htmlFor="shaft2">On same shaft as Input?</label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 3</label>
                <input className="inputbox" type="number" id="input-3" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft3"
                    name="shaft3"
                    value="Checked"
                  />
                  <label htmlFor="shaft3">On same shaft as Gear 2?</label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 4</label>
                <input className="inputbox" type="number" id="input-4" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft4"
                    name="shaft4"
                    value="Checked"
                  />
                  <label htmlFor="shaft4">On same shaft as Gear 3?</label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 5</label>
                <input className="inputbox" type="number" id="input-5" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft5"
                    name="shaft5"
                    value="Checked"
                  />
                  <label htmlFor="shaft5">On same shaft as Gear 4?</label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 6</label>
                <input className="inputbox" type="number" id="input-6" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft6"
                    name="shaft6"
                    value="Checked"
                  />
                  <label htmlFor="shaft6">On same shaft as Gear 5?</label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 7</label>
                <input className="inputbox" type="number" id="input-7" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft7"
                    name="shaft7"
                    value="Checked"
                  />
                  <label htmlFor="shaft7">On same shaft as Gear 6?</label>
                </div>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label>Number of Teeth: Gear 8</label>
                <input className="inputbox" type="number" id="input-8" />
                <div className="checkbox-wrapper">
                  <input
                    className="inputbox"
                    type="checkbox"
                    id="shaft8"
                    name="shaft8"
                    value="Checked"
                  />
                  <label htmlFor="shaft8">On same shaft as Gear 7?</label>
                </div>
              </div>

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/ratio-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          An example gear-train is shown below: The Input Gear meshes with Gear
          2. Gear 3 is on the same shaft as Gear 2, and meshes with Gear 4. Gear
          5 is on the same shaft as Gear 4, and meshes with Gear 6. The "same
          shaft" boxes would be checked on Gears 3 and 5 only.
        </p>
        <p>
          <Image src="/calc-images/ratio-2.png" style={{ maxWidth: "500px" }} />
        </p>
      </Card>
    </>
  );
}
