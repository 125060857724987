import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link, useLocation } from "react-router-dom";

function SideBar({ weakUsername }) {
  // Specific Categories
  const [openCalculators1, setOpenCalculators1] = useState(false);
  const [openCalculators2, setOpenCalculators2] = useState(false);
  const [openCalculators3, setOpenCalculators3] = useState(false);
  const [openCalculators4, setOpenCalculators4] = useState(false);
  const [openCalculators5, setOpenCalculators5] = useState(false);
  const [openCalculators6, setOpenCalculators6] = useState(false);

  const [openWorkspaces, setOpenWorkspaces] = useState(false);

  // Get the active link
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div className="side-bar-column py-2 px-4">
      <div className="fs-5 fw-semibold pb-2 mb-3 mt-2 border-bottom">Menu</div>
      <ul className="list-unstyled ps-0">
        <li className="mb-1">
          <button
            onClick={() => setOpenCalculators1(!openCalculators1)}
            aria-controls="example-collapse-text"
            aria-expanded={openCalculators1}
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
            data-bs-toggle="collapse"
            data-bs-target="#account-collapse"
          >
            Gear Design
          </button>

          <Collapse in={openCalculators1}>
            <div>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "dimensions"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/dimensions/"
                  >
                    Dimensions
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "dim-spur"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/dim-spur/"
                  >
                    Dimensions (Spur)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "dim-helix"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/dim-helix/"
                  >
                    Dimensions (Helix)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "ratio"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/ratio/"
                  >
                    Ratio
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "center-distance"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/center-distance/"
                  >
                    Center Distance
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "pitch-conversion"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/pitch-conversion/"
                  >
                    Pitch Conversion
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "lewis"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/lewis/"
                  >
                    Strength (Lewis)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "strength"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/strength/"
                  >
                    Strength (Dudley)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "lead-angle-worms-hobs"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/lead-angle-worms-hobs/"
                  >
                    Lead Angle (Worms, Hobs)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "lead-angle-threads"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/lead-angle-threads/"
                  >
                    Lead Angle (Threads)
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li className="mb-1">
          <button
            onClick={() => setOpenCalculators2(!openCalculators2)}
            aria-controls="example-collapse-text"
            aria-expanded={openCalculators2}
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
            data-bs-toggle="collapse"
            data-bs-target="#account-collapse"
          >
            Gear Generators (CAD)
          </button>

          <Collapse in={openCalculators2}>
            <div>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" && splitLocation[2] === "dxf"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/dxf/"
                  >
                    DXF
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" && splitLocation[2] === "stl"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/stl/"
                  >
                    STL
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li className="mb-1">
          <button
            onClick={() => setOpenCalculators5(!openCalculators5)}
            aria-controls="example-collapse-text"
            aria-expanded={openCalculators5}
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
            data-bs-toggle="collapse"
            data-bs-target="#account-collapse"
          >
            Machine Setup
          </button>

          <Collapse in={openCalculators5}>
            <div>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" && splitLocation[2] === "cgdb"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/cgdb/"
                  >
                    Change Gears for Machinery
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "gear3"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/gear3/"
                  >
                    Change Gears Brute-Force (multi-solution)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "uratio"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/uratio/"
                  >
                    Change Gears Optimized (unique solution)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "machine"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/machine/"
                  >
                    Enter Machine Info
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        <li className="mb-1">
          <button
            onClick={() => setOpenCalculators6(!openCalculators6)}
            aria-controls="example-collapse-text"
            aria-expanded={openCalculators6}
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
            data-bs-toggle="collapse"
            data-bs-target="#account-collapse"
          >
            Gear Manufacturing
          </button>

          <Collapse in={openCalculators6}>
            <div>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "fands"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/fands/"
                  >
                    Hobbing Feeds & Speeds (Model&nbsp;I)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "fands2"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/fands2/"
                  >
                    Hobbing Feeds & Speeds (Model&nbsp;II)
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "worm-carbide"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/worm-carbide/"
                  >
                    Turning Worms on a Lathe
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "dividing-head"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/dividing-head/"
                  >
                    Gear Milling with a Dividing Head
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        <li className="mb-1">
          <button
            onClick={() => setOpenCalculators3(!openCalculators3)}
            aria-controls="example-collapse-text"
            aria-expanded={openCalculators3}
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
            data-bs-toggle="collapse"
            data-bs-target="#account-collapse"
          >
            Gear Inspection
          </button>

          <Collapse in={openCalculators3}>
            <div>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" && splitLocation[2] === "mop"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/mop/"
                  >
                    Measurement over Pins
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "worm-mop"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/worm-mop/"
                  >
                    Measurement over Pins for Worms
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" && splitLocation[2] === "rack"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/rack/"
                  >
                    Rack
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" && splitLocation[2] === "span"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/span/"
                  >
                    Span
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "vari-roll"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/vari-roll/"
                  >
                    Vari-Roll
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li className="mb-1">
          <button
            onClick={() => setOpenCalculators4(!openCalculators4)}
            aria-controls="example-collapse-text"
            aria-expanded={openCalculators4}
            className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth"
            data-bs-toggle="collapse"
            data-bs-target="#account-collapse"
          >
            Shop References
          </button>

          <Collapse in={openCalculators4}>
            <div>
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "utility"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/utility/"
                  >
                    Utility References
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "hardness"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/hardness/"
                  >
                    Hardness Conversion
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "pitch-plate"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/pitch-plate/"
                  >
                    Pitch Plate
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      splitLocation[1] === "calc" &&
                      splitLocation[2] === "free-hobbing"
                        ? "link-body-emphasis d-inline-flex text-decoration-none rounded activeSBlink"
                        : "link-body-emphasis d-inline-flex text-decoration-none rounded"
                    }
                    to="calc/free-hobbing/"
                  >
                    Free-Hobbing
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        {/*
      <li className="mb-1">
      <button 
        onClick={() => setOpenWorkspaces(!openWorkspaces)}
        aria-controls="example-collapse-text"
        aria-expanded={openWorkspaces}
        className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed btn-fullwidth" data-bs-toggle="collapse" data-bs-target="#account-collapse">
          Workspaces
        </button>

        <Collapse in={openWorkspaces}>
            <div>
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><a href="#test" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Test</a></li>
                <li><a href="#test" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Test</a></li>
                <li><a href="#test" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Test</a></li>
                <li><a href="#test" className="link-body-emphasis d-inline-flex text-decoration-none rounded">Test</a></li>
            </ul>
          </div>
        </Collapse>
        </li>
*/}
        <li className="border-top my-3"></li>
        {weakUsername.length ? (
          <>
            <li className="account-link">
              <Link
                className={
                  splitLocation[1] === "projects"
                    ? "link-body-emphasis activeSBlink"
                    : "link-body-emphasis"
                }
                to="/projects"
              >
                Projects
              </Link>
            </li>
            <li className="account-link">
              <Link
                className={
                  splitLocation[1] === "account"
                    ? "link-body-emphasis activeSBlink"
                    : "link-body-emphasis"
                }
                to="/account"
              >
                Account
              </Link>
            </li>
            <li className="account-link">
              <Link
                className={
                  splitLocation[1] === "change-plan"
                    ? "link-body-emphasis activeSBlink"
                    : "link-body-emphasis"
                }
                to="/change-plan"
              >
                Upgrade Plan
              </Link>
            </li>
          </>
        ) : (
          <>
            <li className="account-link">
              <Link
                className={
                  splitLocation[1] === "login"
                    ? "link-body-emphasis activeSBlink"
                    : "link-body-emphasis"
                }
                to="/login"
              >
                Login
              </Link>
            </li>
            <li className="account-link">
              <Link
                className={
                  splitLocation[1] === "join"
                    ? "link-body-emphasis activeSBlink"
                    : "link-body-emphasis"
                }
                to="/join"
              >
                Sign up
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default SideBar;
