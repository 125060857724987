// Lock out a route if the user is not authenticated
// This is important!
// Should route to either Login or Payment if not authenticated/authorized

// Retry for Promise
import React from "react";
import { Link } from "react-router-dom";

export async function getUserLevel(userDetailsParsed) {
  // Check (based on the username) what their access level is

  const response = await fetch("/userlevel2", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userDetailsParsed),
  });
  let data = await response.json();

  if (data.newIdToken) {
    // We've received a refreshed token from our call!
    // Update saved Token
    var tempUserInfo = localStorage.getItem("currentUser");
    tempUserInfo = JSON.parse(tempUserInfo);
    tempUserInfo["idToken"] = data.newIdToken;
    localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
  }

  localStorage.setItem("currentUserLevel", JSON.stringify(data.usermaxlevel));
  return data;
}

function nameLevel(levelNumber) {
  // Gives user the level name, based on the numeric value
  let levelName = "N/A";
  if (levelNumber >= 19) levelName = "Pro";
  else if (levelNumber >= 10) levelName = "Lite";
  else if (levelNumber >= 0) levelName = "Free";

  return levelName;
}

export function AuthRoute({ level, children }) {
  const [error, setError] = React.useState(false);
  const [state, setState] = React.useState("");
  const [userLevel, setUserLevel] = React.useState(0);

  /*
    const userDetails = useAuthState() //read user details from context
    console.log("In AuthRoute, asking useAuthState for user info");
    console.log("response was:", userDetails);
    let userDetailsParsed = {user:'not authenticated', token:"no token"};
    try {
      userDetailsParsed = JSON.parse(userDetails.userDetails);
    }
    catch{
      userDetailsParsed = {user:'', token:""};
    }
    console.log("authroute user details parsed", userDetailsParsed);
    */
  // Instead of useAuthState, let's pull in the login information from localStorage

  let loginInfoExists = false;
  let storedUserInfo;
  try {
    storedUserInfo = JSON.parse(localStorage.getItem("currentUser"));
    // Attempt to set the data
    if (storedUserInfo.accessToken) {
      loginInfoExists = true;
    }
  } catch {
    loginInfoExists = false;
  }

  React.useEffect(() => {
    setState("loading");
    if (loginInfoExists) {
      // check the user's access token (and level), if it exists
      getUserLevel(storedUserInfo)
        .then((res) => {
          setState("success");
          // Level is now calculated on the backend (from decoding the JWT)
          setUserLevel(res.usermaxlevel);
        })
        .catch((err) => {
          console.error("Error:", err);
          setState("error");
          setError(err);
        });
    } else {
      // User has no access token, cannot attempt to login to get a group
      setState("success");
      setUserLevel(-1);
    }
  }, []); // This dependency array cannot include loginInfoExists, storedUserInfo (otherwise it infinite loops)

  if (state === "error") return <h1>{error.toString()}</h1>;

  if (storedUserInfo) {
    // Authentication required
    return (
      <div className="auth-wrapper">
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            pageLevel: +level,
            userLevel,
          });
        })}
        {level > userLevel && (
          <div className="auth-modal">
            <div>
              <div>
                This page requires a <b>{nameLevel(level)}-tier</b> subscription
              </div>
              <div>
                Your account level is <b>{nameLevel(userLevel)}</b>
              </div>
              <Link className="btn link-button" to="/change-plan">
                Upgrade Plan
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div>
          {state === "loading" ? (
            <>
              <h1>Loading...</h1>
              <p>Loading...</p>
            </>
          ) : (
            <div className="project-card card">
              <div className="project-name">Please Log In</div>
              <p>
                This page requires a <b>{nameLevel(level)}-tier</b>{" "}
                subscription.
              </p>
              <p>
                Please <a href="/login">log in</a> here.
              </p>

              <p style={{ marginTop: "20px" }}>
                If you encounter any errors with the site, please email{" "}
                <a href="mailto:contact@evolventdesign.com">
                  contact@evolventdesign.com
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
