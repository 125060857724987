import React from "react";
import { Nav, Navbar, Image } from "react-bootstrap";

function NavBar({ weakUsername }) {
  return (
    <Navbar
      expand="lg"
      className="fixed-top bg-body-tertiary d-flex flex-wrap justify-content-between align-items-center"
      style={{ padding: "10px 20px" }}
    >
      <div>
        <Navbar.Brand href="/home">
          <Image src="/GCS-name2.svg" style={{ width: "200px" }} />
        </Navbar.Brand>
      </div>
      <div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {weakUsername ? (
            <>
              <Navbar.Collapse className="justify-content-end">
                Welcome&nbsp;{weakUsername}&nbsp;
              </Navbar.Collapse>
              <a
                href="/logout"
                className="announcement-link"
                style={{ marginLeft: "20px" }}
              >
                Logout
              </a>
            </>
          ) : (
            <Nav className="me-auto">
              <Nav.Link href="/login">Login</Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavBar;
