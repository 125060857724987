// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container__TIRo3 {
  width: 100%;
  justify-content: center;
  align-items: center;
}
.login_formContainer__BIhpI {
  width: 200px;
}
.login_error__t-014 {
  font-size: 0.8rem;
  color: #bb0000;
}

.login_loginForm__aymCz {
  display: flex;
  flex-direction: column;
}

.login_loginFormItem__aJHjK {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.login_loginFormItem__aJHjK label {
  font-size: 0.9em;
}
.login_loginFormItem__aJHjK input {
  max-width: 70%;
  border: 1px solid lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/routes/login.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,2BAA2B;AAC7B","sourcesContent":[".container {\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n.formContainer {\n  width: 200px;\n}\n.error {\n  font-size: 0.8rem;\n  color: #bb0000;\n}\n\n.loginForm {\n  display: flex;\n  flex-direction: column;\n}\n\n.loginFormItem {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n}\n.loginFormItem label {\n  font-size: 0.9em;\n}\n.loginFormItem input {\n  max-width: 70%;\n  border: 1px solid lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `login_container__TIRo3`,
	"formContainer": `login_formContainer__BIhpI`,
	"error": `login_error__t-014`,
	"loginForm": `login_loginForm__aymCz`,
	"loginFormItem": `login_loginFormItem__aJHjK`
};
export default ___CSS_LOADER_EXPORT___;
