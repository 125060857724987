import React, { useState } from "react";

// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useAuthState } from "../../context";
import styles from "../login.module.css";

// get special state if we're coming from "confirm" page
import { useNavigate } from "react-router-dom";

// As an "outside" page
import Footer from "./footer";
import PublicHeader from "./header";

function PublicForgotPasswordPage(props) {
  const [email, setEmail] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [confirming, setConfirming] = useState(false);

  const { loading, errorMessage } = useAuthState();

  let navigate = useNavigate();

  const handleForgot = async (e) => {
    e.preventDefault();

    if (confirming) {
      // User has already submitted and should get a code
      const loginUserInfo = {
        username: email,
        code: code,
        newpassword: newpassword,
      };

      const response = await fetch("/forgotpassword2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginUserInfo),
      });
      const pwdResponse = await response.json();

      // if ok, or not, do things
      if (pwdResponse.pwdsuccess) {
        // Success!
        setMessage(pwdResponse.pwdresponse);
        setTimeout(function () {
          navigate("/login"); // password has been reset, let the user login
        }, 1500);
      } else {
        setMessage(pwdResponse.pwdresponse);
      }
    } else {
      // User has not already submitted, and request needs to be sent to get a code
      setConfirming(true);
      try {
        // Use location-state email if provided, otherwise take the page state (from input)

        const loginUserInfo = { username: email };

        const response = await fetch("/forgotpassword", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(loginUserInfo),
        });
        const loginResponse = await response.json();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />

          <Row>
            <Card className="project-card">
              <div className="project-name">Reset password</div>

              <p>Enter your email address below.</p>
              <p>
                If an account exists, a password reset link will be emailed to
                you.
              </p>

              <Row>
                <Col xs={12} sm={12} md={12} lg={6} xl={7}>
                  <div className={{ width: 200 }}>
                    {errorMessage ? <p>TODO: insert error message</p> : null}
                    <form>
                      <div className={styles.loginForm}>
                        <div className={styles.loginFormItem}>
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            id="email"
                            style={{ width: "300px" }}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading || confirming}
                          />
                        </div>
                        {confirming && (
                          <>
                            <div className={styles.loginFormItem}>
                              <label htmlFor="code">Code</label>
                              <input
                                type="text"
                                id="code"
                                onChange={(e) => setCode(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                            <div className={styles.loginFormItem}>
                              <label htmlFor="newpassword">New Password</label>
                              <input
                                type="password"
                                id="newpassword"
                                onChange={(e) => setNewpassword(e.target.value)}
                                disabled={loading}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <button
                        onClick={handleForgot}
                        disabled={loading}
                        className="btn-primary"
                      >
                        Submit
                      </button>
                      <div>{message ? message : ""}</div>
                    </form>
                  </div>
                </Col>
              </Row>
            </Card>
            <Footer />
          </Row>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}

export default PublicForgotPasswordPage;
