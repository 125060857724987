// Import Bootstrap style elements
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Footer from "./footer";
import PublicHeader from "./header";

export default function PublicTOSPage() {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
        <Col>
          <PublicHeader />
          <Row>
            <h2 className="card-heading">terms of service</h2>
            <p>Last updated: January 17, 2024</p>
          </Row>

          <Row style={{ alignItems: "center" }}>
            <Col>
              <Card className="border-0" style={{ background: "#f8f9fa" }}>
                <p>
                  Please read these Terms of Service ("Terms", "Terms of
                  Service") carefully before using the
                  http://www.GearCalculators.com website (the "Service")
                  operated by Evolvent Design, LLC ("us", "we", or "our").
                </p>
                <p>
                  Your access to and use of the Service is conditioned upon your
                  acceptance of and compliance with these Terms. These Terms
                  apply to all visitors, users and others who wish to access or
                  use the Service.
                </p>
                <p>
                  By accessing or using the Service you agree to be bound by
                  these Terms. If you disagree with any part of the terms then
                  you do not have permission to access the Service.
                </p>
                <h3>Purchases</h3>
                <p>
                  If you wish to purchase any product or service made available
                  through the Service ("Purchase"), you may be asked to supply
                  certain information relevant to your Purchase including,
                  without limitation, your credit card number, the expiration
                  date of your credit card, your billing address, and your
                  shipping information.
                </p>
                <p>
                  You represent and warrant that: (i) you have the legal right
                  to use any credit card(s) or other payment method(s) in
                  connection with any Purchase; and that (ii) the information
                  you supply to us is true, correct and complete.
                </p>
                <p>
                  The service may employ the use of third party services for the
                  purpose of facilitating payment and the completion of
                  Purchases. By submitting your information, you grant us the
                  right to provide the information to these third parties
                  subject to our Privacy Policy.
                </p>
                <h3>Availability, Errors and Inaccuracies</h3>
                <p>
                  We are constantly updating product and service offerings on
                  the Service. We may experience delays in updating information
                  on the Service and in our advertising on other web sites. The
                  information found on the Service may contain errors or
                  inaccuracies and may not be complete or current. Products or
                  services may be mispriced, described inaccurately, or
                  unavailable on the Service and we cannot guarantee the
                  accuracy or completeness of any information found on the
                  Service.
                </p>
                <p>
                  We therefore reserve the right to change or update information
                  and to correct errors, inaccuracies, or omissions at any time
                  without prior notice.
                </p>
                <h3>Accounts</h3>
                <p>
                  When you create an account with us, you guarantee that you are
                  above the age of 18, and that the information you provide us
                  is accurate, complete, and current at all times. Inaccurate,
                  incomplete, or obsolete information may result in the
                  immediate termination of your account on the Service.
                </p>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account and password, including but not limited to the
                  restriction of access to your computer and/or account. You
                  agree to accept responsibility for any and all activities or
                  actions that occur under your account and/or password, whether
                  your password is with our Service or a third-party service.
                  You must notify us immediately upon becoming aware of any
                  breach of security or unauthorized use of your account.
                </p>
                <p>
                  We reserve the right to refuse service, terminate accounts,
                  remove or edit content, or cancel orders in our sole
                  discretion.
                </p>
                <h3>Intellectual Property</h3>
                <p>
                  The Service and its original content, features and
                  functionality are and will remain the exclusive property of
                  Evolvent Design, LLC and its licensors. The Service is
                  protected by copyright, trademark, and other laws of both the
                  United States and foreign countries. Our trademarks and trade
                  dress may not be used in connection with any product or
                  service without the prior written consent of Evolvent Design,
                  LLC.
                </p>
                <h3>Links To Other Web Sites</h3>
                <p>
                  Our Service may contain links to third party web sites or
                  services that are not owned or controlled by Evolvent Design,
                  LLC
                </p>
                <p>
                  Evolvent Design, LLC has no control over, and assumes no
                  responsibility for the content, privacy policies, or practices
                  of any third party web sites or services. We do not warrant
                  the offerings of any of these entities/individuals or their
                  websites.
                </p>
                <p>
                  You acknowledge and agree that Evolvent Design, LLC shall not
                  be responsible or liable, directly or indirectly, for any
                  damage or loss caused or alleged to be caused by or in
                  connection with use of or reliance on any such content, goods
                  or services available on or through any such third party web
                  sites or services.
                </p>
                <p>
                  We strongly advise you to read the terms and conditions and
                  privacy policies of any third party web sites or services that
                  you visit.
                </p>
                <h3>Indemnification</h3>
                <p>
                  You agree to defend, indemnify and hold harmless Evolvent
                  Design, LLC and its licensee and licensors, and their
                  employees, contractors, agents, officers and directors, from
                  and against any and all claims, damages, obligations, losses,
                  liabilities, costs or debt, and expenses (including but not
                  limited to attorney's fees), resulting from or arising out of
                  a) your use and access of the Service, by you or any person
                  using your account and password, or b) a breach of these
                  Terms.
                </p>
                <h3>Limitation Of Liability</h3>
                <p>
                  In no event shall Evolvent Design, LLC, nor its directors,
                  employees, partners, agents, suppliers, or affiliates, be
                  liable for any indirect, incidental, special, consequential or
                  punitive damages, including without limitation, loss of
                  profits, data, use, goodwill, or other intangible losses,
                  resulting from (i) your access to or use of or inability to
                  access or use the Service; (ii) any conduct or content of any
                  third party on the Service; (iii) any content obtained from
                  the Service; and (iv) unauthorized access, use or alteration
                  of your transmissions or content, whether based on warranty,
                  contract, tort (including negligence) or any other legal
                  theory, whether or not we have been informed of the
                  possibility of such damage, and even if a remedy set forth
                  herein is found to have failed of its essential purpose.
                </p>
                <h3>Disclaimer</h3>
                <p>
                  Your use of the Service is at your sole risk. The Service is
                  provided on an "AS IS" and "AS AVAILABLE" basis. The Service
                  is provided without warranties of any kind, whether express or
                  implied, including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose,
                  non-infringement or course of performance.
                </p>
                <p>
                  Evolvent Design, LLC its subsidiaries, affiliates, and its
                  licensors do not warrant that a) the Service will function
                  uninterrupted, secure or available at any particular time or
                  location; b) any errors or defects will be corrected; c) the
                  Service is free of viruses or other harmful components; or d)
                  the results of using the Service will meet your requirements.
                </p>
                <h3>Disclaimer of Data Storage Responsibilities</h3>
                <p>
                  You acknowledge and agree that: (i) Evolvent Design and its
                  Affiliates will do our best to protect your personal
                  information, transmission of personal information; (ii) to the
                  extent that You wish to retain any Account Data or other
                  information relating to Your Plan, an Account, or the use
                  thereof, You shall ensure that such information is downloaded,
                  saved, and/or backed-up outside of Your Account, as necessary
                  or appropriate for Your and/or the End-Users’ purposes; (iii)
                  You shall not rely on Your Plan or Account as a repository for
                  or means by which to retain, store, or back-up Account Data or
                  any other data, information, or materials; (iv) Evolvent
                  Design may delete or purge any and all copies and versions of
                  any stored Client Personal Data and/or Account Data or other
                  data at any time, without notice, including without limitation
                  after you delete any such information from an Account or after
                  termination of this Agreement or closure of an Account; and
                  (v) Evolvent Design may, in its sole discretion and option and
                  without notice, implement reasonable limits as to the size or
                  duration of storage of Account Data. (vi) Please remember that
                  we cannot guarantee the internet itself is 100% secure.
                  Although we will do our best to protect your personal
                  information, transmission of personal information to and from
                  our Sites is at your own risk. You should only access the
                  services within a secure environment.
                </p>
                <h3>Exclusions</h3>
                <p>
                  Some jurisdictions do not allow the exclusion of certain
                  warranties or the exclusion or limitation of liability for
                  consequential or incidental damages, so the limitations above
                  may not apply to you.
                </p>
                <h3>Governing Law</h3>
                <p>
                  These Terms shall be governed and construed in accordance with
                  the laws of California, United States, without regard to its
                  conflict of law provisions.
                </p>
                <p>
                  Our failure to enforce any right or provision of these Terms
                  will not be considered a waiver of those rights. If any
                  provision of these Terms is held to be invalid or
                  unenforceable by a court, the remaining provisions of these
                  Terms will remain in effect. These Terms constitute the entire
                  agreement between us regarding our Service, and supersede and
                  replace any prior agreements we might have had between us
                  regarding the Service.
                </p>
                <h3>Changes</h3>
                <p>
                  We reserve the right, at our sole discretion, to modify or
                  replace these Terms at any time. If a revision is material we
                  will provide at least 30 days notice prior to any new terms
                  taking effect. What constitutes a material change will be
                  determined at our sole discretion.
                </p>
                <p>
                  By continuing to access or use our Service after any revisions
                  become effective, you agree to be bound by the revised terms.
                  If you do not agree to the new terms, you are no longer
                  authorized to use the Service.
                </p>
                <h3>Contact Us</h3>
                <p>
                  If you have any questions about these Terms, please contact
                  us.
                </p>
              </Card>
            </Col>
          </Row>

          <Footer></Footer>
        </Col>
        <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
      </Row>
    </Container>
  );
}
