import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { DecimalPlacesSelect, ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorLeadWorm({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-method").value = "tpi";
    document.getElementById("input-dia").value = "";
    document.getElementById("input-pitch").value = "";
    document.getElementById("input-starts").value = "1";
    //document.getElementById('input-lead').value = '';
    document.getElementById("input-pitch").disabled = false;
    document.getElementById("input-starts").disabled = false;
    document.getElementById("input-lead").disabled = true;

    setTextAreaText("");
    resizeTextAnswer();

    event.preventDefault();
    return false;
  }

  function methodChange() {
    if (document.getElementById("input-method").value === "tpi") {
      document.getElementById("input-dia").placeholder = "Diameter (in)";
      document.getElementById("input-pitch").placeholder =
        "Diametral Pitch (1/in)";
      document.getElementById("input-pitch").disabled = false;
      document.getElementById("input-starts").disabled = false;
    } else if (document.getElementById("input-method").value === "pitch") {
      document.getElementById("input-dia").placeholder = "Diameter (mm)";
      document.getElementById("input-pitch").placeholder = "Module (mm)";
      document.getElementById("input-pitch").disabled = false;
      document.getElementById("input-starts").disabled = false;
      //document.getElementById('input-lead').disabled = true;
    } else if (document.getElementById("input-method").value === "leadin") {
      document.getElementById("input-dia").placeholder = "Diameter (in)";
      document.getElementById("input-pitch").placeholder = "Pitch";
      // document.getElementById('input-lead').placeholder = 'Lead (in)';
      document.getElementById("input-pitch").disabled = true;
      document.getElementById("input-starts").disabled = true;
      // document.getElementById('input-lead').disabled = false;
    } else if (document.getElementById("input-method").value === "leadmm") {
      document.getElementById("input-dia").placeholder = "Diameter (mm)";
      document.getElementById("input-pitch").placeholder = "Pitch";
      // document.getElementById('input-lead').placeholder = 'Lead (mm)';
      document.getElementById("input-pitch").disabled = true;
      document.getElementById("input-starts").disabled = true;
      // document.getElementById('input-lead').disabled = false;
    }
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var validTest = true;

    var precision = parseInt(document.getElementById("precision").value);
    var method = document.getElementById("input-method").value;
    var pitch = parseFloat(document.getElementById("input-pitch").value);
    // var lead = parseFloat(document.getElementById('input-lead').value);
    var starts = parseFloat(document.getElementById("input-starts").value);
    var diameter = parseFloat(document.getElementById("input-dia").value);

    logQA({
      page: "GCSleadangleW",
      param5: method,
      param6: pitch,
      param8: starts,
      param9: diameter,
    });

    var errorMessage;
    if (
      (method === "pitch" || method === "tpi") &&
      (pitch <= 0 || isNaN(pitch) || starts <= 0 || isNaN(starts))
    ) {
      validTest = false;
      errorMessage = "Pitch and starts must be positive numbers";
    }

    if (isNaN(precision)) {
      validTest = false;
      errorMessage = "Number of decimal places must be an integer";
    }

    if (diameter <= 0 || isNaN(diameter)) {
      validTest = false;
      errorMessage = "Diameter must be a positive value";
    }

    var lead = null; // only for threads, not worms and hobs
    if (validTest) {
      if (method === "tpi") {
        let tanA = starts / (diameter * pitch);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "in",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
      if (method === "pitch") {
        let tanA = (starts * pitch) / diameter;
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "mm",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
      if (method === "leadin") {
        let tanA = lead / (Math.PI * diameter);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "in",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
      if (method === "leadmm") {
        let tanA = lead / (Math.PI * diameter);
        let leadAngle = Math.atan(tanA);
        writeAnswer(
          method,
          "mm",
          pitch,
          lead,
          starts,
          diameter,
          leadAngle,
          precision
        );
      }
    } else {
      setTextAreaText("Error\n\n" + errorMessage);
      resizeTextAnswer("Error\n\n" + errorMessage);
    }
  }
  function writeAnswer(
    method,
    diameterUnit,
    pitch,
    lead,
    starts,
    diameter,
    leadAngle,
    precision
  ) {
    let leadAngleDeg = (leadAngle * 180) / Math.PI;
    let degrees = Math.floor(leadAngleDeg);
    let minutes = 60 * (leadAngleDeg - degrees);
    let minutesF = Math.floor(minutes);
    let seconds = Math.floor(60 * (minutes - minutesF));

    var textToWrite = `Inputs\n`;
    if (method === "tpi") {
      textToWrite =
        textToWrite +
        `Diametral Pitch:\t${pitch}\t/in\nThread Starts:\t${starts}`;
    }
    if (method === "pitch") {
      textToWrite =
        textToWrite + `Module:\t${pitch}\tmm\nThread Starts:\t${starts}`;
    }
    if (method === "leadin") {
      textToWrite = textToWrite + `Lead:\t${lead}\tin`;
    }
    if (method === "leadmm") {
      textToWrite = textToWrite + `Lead:\t${lead}\tmm`;
    }
    textToWrite = textToWrite + `\nDiameter:\t${diameter}\t${diameterUnit}`;

    textToWrite =
      textToWrite +
      `\n\nOutputs\nWorm Lead Angle:\t${leadAngleDeg.toFixed(
        precision
      )}\tdeg\nWorm Lead Angle:\t${degrees}\u00B0${minutesF}'${seconds}"`;

    //textToWrite = "Inputs\n" + inputText + ":\t" + inputValue.toFixed(precision) + "\t" + inputUnits + "\n\nOutputs\n" + "Module:\t" + oModule.toFixed(precision) + "\tmm\nDiametral Pitch:\t" +oDP.toFixed(precision) + "\t1/in\nCircular Pitch: \t" + oCP.toFixed(precision) + "\tin" + "\nThreads per Inch: \t" + oTPI.toFixed(precision) + "\t1/in" ;
    textToWrite =
      textToWrite +
      "\n\n\u00A9 " +
      new Date().getFullYear() +
      " Evolvent Design,\t" +
      formatDate(new Date()) +
      "\nGCS0";
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Lead Angle (Worms, Hobs)</div>
        <p>
          The <span className="term-emphasis">lead angle</span> for hobs and
          worms is the angle between the helix and a plane of rotation at the
          reference diameter.
        </p>
        <p>
          For most finer pitch hobs the angle is small, on the order of less
          than 5 degrees. For large teeth on small diameter shafts, the lead
          angle becomes much larger.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-method">Method</label>
                <select
                  className="inputbox"
                  name="input-method"
                  id="input-method"
                  onChange={methodChange}
                >
                  <option value="tpi">Diametral Pitch</option>
                  <option value="pitch">Module (mm)</option>
                </select>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-dia">Effective Diameter (in)</label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-dia"
                  placeholder="Diameter (in)"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pitch">
                  Pitch
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Module is in millimeters, DP is in 1/inch
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-pitch"
                  placeholder="Diametral Pitch"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-starts"># of Starts</label>
                <select
                  className="inputbox"
                  name="input-starts"
                  id="input-starts"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
              </div>

              <DecimalPlacesSelect defaultValue={3} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/leadworm-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          The <span className="term-emphasis">lead angle</span> is the
          complement of the helix angle
        </p>
        <p>
          Worms can have pitch diameters that cover a large range unlike gears.
          So if you are not sure about a worm you are reverse engineering, or if
          you are designing a new one, this is a handy little check to
          understand the lead angle.
        </p>
      </Card>
    </>
  );
}
