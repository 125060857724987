import React from "react";

// get special state if we're coming from "confirm" page
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";

function clearLocalStorage() {
  const allLocalStorage = { ...localStorage }; // get all local storage

  for (var [key, value] of Object.entries(allLocalStorage)) {
    // Loop through all localStorage

    try {
      localStorage.removeItem(key);
    } catch {
      // Do not add gear. Either did not parse or not for this calculator
      console.log(
        "Could not delete localStorage entry! Key:",
        key,
        " Value:",
        value
      );
      break;
    }
  }
}

function Logout() {
  // Grab current all projects (and set) from Outlet (in root.js)
  const [
    activeProject,
    setActiveProject,
    allProjects,
    setAllProjects,
    weakUsername,
    setWeakUsername,
  ] = useOutletContext();

  let navigate = useNavigate();

  setWeakUsername(""); // Reset the display username
  try {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserLevel");
    clearLocalStorage();
    setTimeout(function () {
      navigate("/");
    }, 200);
  } catch (error) {
    console.log("Logout failed");
  }

  // TODO: just return user to the root after deleting entry
  return <></>;
}

export default Logout;
