import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import { ButtonBlockTextArea } from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorHardness({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-rocka").value = "";
    document.getElementById("input-rockb").value = "";
    document.getElementById("input-rockc").value = "";
    document.getElementById("input-brinell").value = "";
    document.getElementById("input-rocka").disabled = false;
    document.getElementById("input-rockb").disabled = false;
    document.getElementById("input-rockc").disabled = false;
    document.getElementById("input-brinell").disabled = false;
    setTextAreaText("");
    resizeTextAnswer();
    event.preventDefault();
    return false;
  }

  // Disable other boxes once you start typing in one
  function changeRockA() {
    if (document.getElementById("input-rocka").value !== "") {
      document.getElementById("input-rockb").disabled = true;
      document.getElementById("input-rockc").disabled = true;
      document.getElementById("input-brinell").disabled = true;
    } else {
      document.getElementById("input-rockb").disabled = false;
      document.getElementById("input-rockc").disabled = false;
      document.getElementById("input-brinell").disabled = false;
    }
    return false;
  }
  function changeRockB() {
    if (document.getElementById("input-rockb").value !== "") {
      document.getElementById("input-rocka").disabled = true;
      document.getElementById("input-rockc").disabled = true;
      document.getElementById("input-brinell").disabled = true;
    } else {
      document.getElementById("input-rocka").disabled = false;
      document.getElementById("input-rockc").disabled = false;
      document.getElementById("input-brinell").disabled = false;
    }
    return false;
  }
  function changeRockC() {
    if (document.getElementById("input-rockc").value !== "") {
      document.getElementById("input-rocka").disabled = true;
      document.getElementById("input-rockb").disabled = true;
      document.getElementById("input-brinell").disabled = true;
    } else {
      document.getElementById("input-rocka").disabled = false;
      document.getElementById("input-rockb").disabled = false;
      document.getElementById("input-brinell").disabled = false;
    }
    return false;
  }
  function changeBrinell() {
    if (document.getElementById("input-brinell").value !== "") {
      document.getElementById("input-rocka").disabled = true;
      document.getElementById("input-rockb").disabled = true;
      document.getElementById("input-rockc").disabled = true;
    } else {
      document.getElementById("input-rocka").disabled = false;
      document.getElementById("input-rockb").disabled = false;
      document.getElementById("input-rockc").disabled = false;
    }
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var validTest = true;

    var iRockA = parseFloat(document.getElementById("input-rocka").value);
    var iRockB = parseFloat(document.getElementById("input-rockb").value);
    var iRockC = parseFloat(document.getElementById("input-rockc").value);
    var iBrinell = parseFloat(document.getElementById("input-brinell").value);

    logQA({
      page: "GCShardness",
      param5: iRockA,
      param6: iRockB,
      param7: iRockC,
      param8: iBrinell,
    });

    var errorMessage;
    if (isNaN(iRockA) && isNaN(iRockB) && isNaN(iRockC) && isNaN(iBrinell)) {
      validTest = false;
      errorMessage = "One valid input is required";
    }

    if (iRockA < 0 || iRockB < 0 || iRockC < 0 || iBrinell < 0) {
      validTest = false;
      errorMessage = "Input must be a positive value";
    }

    var oRockA, oRockB, oRockC, oBrinell;
    if (validTest) {
      if (!isNaN(iRockA)) {
        oRockA = iRockA;
        oRockB = -4.8235e1 + 3.33354 * iRockA - 1.50107e-2 * iRockA ** 2;
        oRockC = -1.25501e2 + 2.76747 * iRockA - 5.94178e-3 * iRockA ** 2;

        // No direct path to Brinell; go to Rockwell B/C and then come back
        var useRockB, useRockC;
        useRockB = iRockA >= 39.5 && iRockA <= 61.5 ? true : false;
        useRockC = iRockA > 61.5 && iRockA <= 85.6 ? true : false;

        oBrinell = iterBrinFromA(useRockB, oRockB, useRockC, oRockC);

        // Clean (check bounds, and round)
        oRockA = oRockA.toFixed(0);
        oRockB = !(iRockA >= 39.5 && iRockA <= 61.5)
          ? "Out of bounds"
          : oRockB.toFixed(0);
        oRockC = !(iRockA >= 60.5 && iRockA <= 85.6)
          ? "Out of bounds"
          : oRockC.toFixed(0);
        oBrinell = oBrinell ? oBrinell.toFixed(0) + "*" : "Not found";

        writeAnswer("Rockwell A", iRockA, oRockA, oRockB, oRockC, oBrinell);
      }

      if (!isNaN(iRockB)) {
        oRockB = iRockB;
        oRockC = "Out of bounds";

        // No direct path to Rockwell A or Brinell

        oRockA = oRockB >= 60 && oRockB <= 100 ? iterRockAFromB(oRockB) : false;
        oBrinell =
          oRockB >= 60 && oRockB <= 100 ? iterBrinFromB(oRockB) : false;

        // Clean (check bounds, and round)
        oRockA = oRockA ? oRockA.toFixed(0) + "*" : "Out of bounds";
        oRockB = oRockB.toFixed(0);
        oBrinell = oBrinell ? oBrinell.toFixed(0) + "*" : "Out of bounds";

        writeAnswer("Rockwell B", oRockB, oRockA, oRockB, oRockC, oBrinell);
      }

      if (!isNaN(iRockC)) {
        oRockC = iRockC;
        oRockB = "Out of bounds";

        // No direct path to Rockwell A or Brinell

        oRockA = oRockC >= 20 && oRockC <= 68 ? iterRockAFromC(oRockC) : false;
        oBrinell = oRockC >= 20 && oRockC <= 65 ? iterBrinFromC(oRockC) : false;

        // Clean (check bounds, and round)
        oRockA = oRockA ? oRockA.toFixed(0) + "*" : "Out of bounds";
        oRockC = oRockC.toFixed(0);
        oBrinell = oBrinell ? oBrinell.toFixed(0) + "*" : "Out of bounds";

        writeAnswer("Rockwell C", oRockC, oRockA, oRockB, oRockC, oBrinell);
      }

      if (!isNaN(iBrinell)) {
        oBrinell = iBrinell;
        oRockB =
          114.665 +
          0.0882795 * oBrinell -
          0.000141855 * oBrinell ** 2 -
          0.669528 / oBrinell;
        oRockC =
          18.1673 +
          0.120388 * oBrinell -
          0.0000694388 * oBrinell ** 2 -
          4883.27 / oBrinell;

        // No direct path to Rockwell A; go to Rockwell B/C and then come back
        const useRockB = oBrinell >= 107 && oBrinell <= 240 ? true : false;
        const useRockC = oBrinell > 240 && oBrinell <= 739 ? true : false;

        oRockA = iterRockAFromBrinell(useRockB, oRockB, useRockC, oRockC);

        // Clean (check bounds, and round)
        oRockA = oRockA ? oRockA.toFixed(0) + "*" : "Not found";
        oRockB = !(oBrinell >= 107 && oBrinell <= 240)
          ? "Out of bounds"
          : oRockB.toFixed(0);
        oRockC = !(oBrinell >= 226 && oBrinell <= 739)
          ? "Out of bounds"
          : oRockC.toFixed(0);
        oBrinell = oBrinell.toFixed(0);

        writeAnswer(
          "Brinell Hardness",
          iBrinell,
          oRockA,
          oRockB,
          oRockC,
          oBrinell
        );
      }
    } else {
      setTextAreaText("Error\n\n" + errorMessage);
      resizeTextAnswer("Error\n\n" + errorMessage);
    }
  }
  function writeAnswer(
    inputText,
    inputValue,
    oRockA,
    oRockB,
    oRockC,
    oBrinell
  ) {
    var textToWrite;
    textToWrite = `Inputs
${inputText}:\t${inputValue}

Outputs
Rockwell A:\t${oRockA}
Rockwell B:\t${oRockB}
Rockwell C:\t${oRockC}
Brinell Hardness:\t${oBrinell}

\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}
GCS0`;
    setTextAreaText(textToWrite);
    resizeTextAnswer(textToWrite);
  }

  function iterBrinFromA(useRockB, rockBtarget, useRockC, rockCtarget) {
    // What Brinell hardness gets sufficiently close to the target Rockwell B or C
    var guessCount = 0;
    var maxGuesses = 100;
    var tolerance = 0.1;
    if (useRockB) {
      var guessLow = 107;
      var guessHigh = 240;
      var guess, guessValue;

      function getBfromBrinell(brinellGuess) {
        return (
          114.665 +
          0.0882795 * brinellGuess -
          0.000141855 * brinellGuess ** 2 -
          6695.28 / brinellGuess
        );
      }
      guess = 175;
      guessValue = getBfromBrinell(guess);
      while (
        Math.abs(guessValue - rockBtarget) > tolerance &&
        guessCount < maxGuesses
      ) {
        guessValue = getBfromBrinell(guess);
        if (guessValue > rockBtarget) {
          guessHigh = guess;
          guess = (guess + guessLow) / 2;
        } else {
          guessLow = guess;
          guess = (guess + guessHigh) / 2;
        }

        guessCount = guessCount + 1;
      }
      return guess;
    }

    if (useRockC) {
      let guessLow = 226; // Min Brinell
      let guessHigh = 739; // Max Brinell
      let guess, guessValue;

      function getCfromBrinell(brinellGuess) {
        return (
          18.1673 +
          0.120388 * brinellGuess -
          0.0000694388 * brinellGuess ** 2 -
          4883.27 / brinellGuess
        );
      }
      guess = 400;
      guessValue = getCfromBrinell(guess);
      while (
        Math.abs(guessValue - rockCtarget) > tolerance &&
        guessCount < maxGuesses
      ) {
        guessValue = getCfromBrinell(guess);
        if (guessValue > rockCtarget) {
          guessHigh = guess;
          guess = (guess + guessLow) / 2;
        } else {
          guessLow = guess;
          guess = (guess + guessHigh) / 2;
        }

        guessCount = guessCount + 1;
      }
      return guess;
    }
  }

  function iterRockAFromBrinell(useRockB, rockBtarget, useRockC, rockCtarget) {
    // What Brinell hardness gets sufficiently close to the target Rockwell B or C
    var guessCount = 0;
    var maxGuesses = 100;
    var tolerance = 0.1;
    if (useRockB) {
      let guessLow = 39.5;
      let guessHigh = 61.5;
      let guess, guessValue;

      function getBfromA(aGuess) {
        return -48.235 + 3.33345 * aGuess - 0.0150107 * aGuess ** 2;
      }
      guess = 50;
      guessValue = getBfromA(guess);
      while (
        Math.abs(guessValue - rockBtarget) > tolerance &&
        guessCount < maxGuesses
      ) {
        guessValue = getBfromA(guess);
        if (guessValue > rockBtarget) {
          guessHigh = guess;
          guess = (guess + guessLow) / 2;
        } else {
          guessLow = guess;
          guess = (guess + guessHigh) / 2;
        }

        guessCount = guessCount + 1;
      }
      return guess;
    }

    if (useRockC) {
      var guessLow = 60.5; // Min Brinell
      var guessHigh = 85.6; // Max Brinell
      var guess, guessValue;

      function getCfromA(aGuess) {
        return -125.501 + 2.76747 * aGuess - 0.00594178 * aGuess ** 2;
      }
      guess = 72;
      guessValue = getCfromA(guess);
      while (
        Math.abs(guessValue - rockCtarget) > tolerance &&
        guessCount < maxGuesses
      ) {
        guessValue = getCfromA(guess);
        if (guessValue > rockCtarget) {
          guessHigh = guess;
          guess = (guess + guessLow) / 2;
        } else {
          guessLow = guess;
          guess = (guess + guessHigh) / 2;
        }

        guessCount = guessCount + 1;
      }
      return guess;
    }
  }

  function iterBrinFromB(rockBtarget) {
    // What Brinell hardness gets sufficiently close to the target Rockwell B or C
    var guessCount = 0;
    var maxGuesses = 100;
    var tolerance = 0.1;
    var guessLow = 107;
    var guessHigh = 204;
    var guess, guessValue;

    function getBrinFromB(brinGuess) {
      return (
        114.665 +
        0.0882795 * brinGuess -
        0.000141855 * brinGuess ** 2 -
        6695.28 / brinGuess
      );
    }
    guess = 155;
    guessValue = getBrinFromB(guess);
    while (
      Math.abs(guessValue - rockBtarget) > tolerance &&
      guessCount < maxGuesses
    ) {
      guessValue = getBrinFromB(guess);
      if (guessValue > rockBtarget) {
        guessHigh = guess;
        guess = (guess + guessLow) / 2;
      } else {
        guessLow = guess;
        guess = (guess + guessHigh) / 2;
      }

      guessCount = guessCount + 1;
    }
    return guess;
  }

  function iterRockAFromB(rockBtarget) {
    // What Brinell hardness gets sufficiently close to the target Rockwell B or C
    var guessCount = 0;
    var maxGuesses = 100;
    var tolerance = 0.1;
    var guessLow = 39.5;
    var guessHigh = 61.5;
    var guess, guessValue;

    function getRockAfromB(bGuess) {
      return -48.235 + 3.33354 * bGuess - 0.0150107 * bGuess ** 2;
    }
    guess = 50;
    guessValue = getRockAfromB(guess);
    while (
      Math.abs(guessValue - rockBtarget) > tolerance &&
      guessCount < maxGuesses
    ) {
      guessValue = getRockAfromB(guess);
      if (guessValue > rockBtarget) {
        guessHigh = guess;
        guess = (guess + guessLow) / 2;
      } else {
        guessLow = guess;
        guess = (guess + guessHigh) / 2;
      }

      guessCount = guessCount + 1;
    }
    return guess;
  }

  function iterRockAFromC(rockCtarget) {
    // What Brinell hardness gets sufficiently close to the target Rockwell B or C
    var guessCount = 0;
    var maxGuesses = 100;
    var tolerance = 0.1;
    var guessLow = 60.5;
    var guessHigh = 85.6;
    var guess, guessValue;

    function getRockAfromC(aGuess) {
      return -125.501 + 2.76747 * aGuess - 0.00594178 * aGuess ** 2;
    }
    guess = 72;
    guessValue = getRockAfromC(guess);
    while (
      Math.abs(guessValue - rockCtarget) > tolerance &&
      guessCount < maxGuesses
    ) {
      guessValue = getRockAfromC(guess);
      if (guessValue > rockCtarget) {
        guessHigh = guess;
        guess = (guess + guessLow) / 2;
      } else {
        guessLow = guess;
        guess = (guess + guessHigh) / 2;
      }

      guessCount = guessCount + 1;
    }
    return guess;
  }

  function iterBrinFromC(rockCtarget) {
    // What Brinell hardness gets sufficiently close to the target Rockwell B or C
    var guessCount = 0;
    var maxGuesses = 100;
    var tolerance = 0.1;
    var guessLow = 226;
    var guessHigh = 739;
    var guess, guessValue;

    function getBrinFromC(brinGuess) {
      return (
        18.1673 +
        0.120388 * brinGuess -
        0.0000694388 * brinGuess ** 2 -
        4883.27 / brinGuess
      );
    }
    guess = 470;
    guessValue = getBrinFromC(guess);
    while (
      Math.abs(guessValue - rockCtarget) > tolerance &&
      guessCount < maxGuesses
    ) {
      guessValue = getBrinFromC(guess);
      if (guessValue > rockCtarget) {
        guessHigh = guess;
        guess = (guess + guessLow) / 2;
      } else {
        guessLow = guess;
        guess = (guess + guessHigh) / 2;
      }

      guessCount = guessCount + 1;
    }
    return guess;
  }
  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Hardness Conversion</div>
        <p>
          There are multiple systems that can be used to describe and measure
          the hardness of a material.
        </p>
        <p>
          <span className="term-emphasis">Rockwell A (HRA)</span>, uses a 60-kgf
          load
        </p>
        <p>
          <span className="term-emphasis">Rockwell B (HRB)</span>, uses a 60-kgf
          load
        </p>
        <p>
          <span className="term-emphasis">Rockwell C (HRC)</span>, uses a
          150-kgf load
        </p>
        <p>
          <span className="term-emphasis">Brinell Hardness (HBS/HBW/BHN)</span>,
          uses a 3000-kgf load
        </p>
        <p>
          The Brinell hardness test uses a steel ball (HBS) for softer
          materials, and a tungsten/carbide ball (HBW) for harder materials.
          Collectively, it can be referred to as the Brinell Hardness Number
          (BHN).
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-rocka">Rockwell A</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-rocka"
                  size="35"
                  placeholder="Rockwell A"
                  onChange={changeRockA}
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-rockb">Rockwell B</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-rockb"
                  size="35"
                  placeholder="Rockwell B"
                  onChange={changeRockB}
                />
              </div>
              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-rockc">Rockwell C</label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-rockc"
                  size="35"
                  placeholder="Rockwell C"
                  onChange={changeRockC}
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-brinell">
                  Brinell Hardness Number (BHN)
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-brinell"
                  size="35"
                  placeholder="Brinell (HBS/HBW)"
                  onChange={changeBrinell}
                />
              </div>

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/hardness-1.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          Per <i>ASTM E 140-07</i>, all converted hardnesses must be considered
          approximate and rounded to the nearest whole number.
        </p>
        <p>
          An * is added when there is no direct formula between the two units.
        </p>
      </Card>
    </>
  );
}
