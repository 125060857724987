import React from "react";

import { resizeTextAnswer, formatDate, logQA } from "./helpers";
import {
  UnitsRadio,
  DPModuleInput,
  DecimalPlacesSelect,
  ButtonBlockTextArea,
} from "./shared";

// Import Bootstrap style elements
import { Card, Col, Image, Row } from "react-bootstrap";

export default function CalculatorRack({ pageLevel, userLevel }) {
  const [textAreaText, setTextAreaText] = React.useState("");

  // Scroll to top on load (helpful on mobile)
  const scrollanchor = React.useRef("");
  React.useEffect(() => scrollanchor.current.scrollIntoView(), []);

  function clearInputs(event) {
    document.getElementById("input-module").value = "";
    document.getElementById("input-pangle").value = "";
    document.getElementById("input-testpindia").value = "";
    document.getElementById("input-back").value = "";

    setTextAreaText("");
    resizeTextAnswer();
    event.preventDefault();
    return false;
  }

  function calculate(event) {
    // user not authorized to use this calculator
    if (pageLevel > userLevel) return;

    event.preventDefault();
    var radioValue;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
    } else {
      radioValue = "mm";
    }
    var inputModule = document.getElementById("input-module").value;
    var pressureAngle = document.getElementById("input-pangle").value;
    var testPinDia = document.getElementById("input-testpindia").value;
    var measurement = document.getElementById("input-back").value;
    var rackform = document.getElementById("rackform").value;

    logQA({
      page: "GCSgearrack",
      module: inputModule,
      pangle: pressureAngle,
      pindia: testPinDia,
      param5: radioValue,
      param6: rackform,
    });

    var errorMessage = "";
    var okToCalc = true;

    // INPUT VALIDATION

    if (
      document.getElementsByName("units")[0].checked ===
      document.getElementsByName("units")[1].checked
    ) {
      //Both metric and inch are the same (checked or unchecked)
      okToCalc = false;
      errorMessage += "Gear must be either Metric or Inch\n";
    }

    if (!(inputModule > 0)) {
      okToCalc = false;
      errorMessage += "Module/DP must be positive\n";
    }

    if (!(pressureAngle >= 1 && pressureAngle <= 45)) {
      okToCalc = false;
      errorMessage += "Pressure Angle must be between 1 and 45 degrees\n";
    }

    if (!(testPinDia === "" || testPinDia > 0)) {
      okToCalc = false;
      errorMessage += "Test pin diameter must be positive if defined\n";
    }

    if (!(measurement === "" || measurement > 0)) {
      okToCalc = false;
      errorMessage += "Measurement must be positive if defined\n";
    }

    // End input validation

    if (okToCalc) {
      calculateMOW();
    } else {
      setTextAreaText(errorMessage);
      resizeTextAnswer(errorMessage);
    }
  }
  function calculateMOW() {
    var radioValue, modWord;
    if (document.getElementsByName("units")[1].checked) {
      //check if the gear is internal
      radioValue = "in";
      modWord = "Diametral Pitch";
    } else {
      radioValue = "mm";
      modWord = "Module";
    }
    var inputModule = parseFloat(document.getElementById("input-module").value);
    var pressureAngle = parseFloat(
      document.getElementById("input-pangle").value
    );
    var testPinDia = parseFloat(
      document.getElementById("input-testpindia").value
    );
    var measurement = parseFloat(document.getElementById("input-back").value);
    var rackform = document.getElementById("rackform").value;
    var precision = parseInt(document.getElementById("precision").value);

    let rackFormData = {
      A: { alphaP: 20, hap: 1, cp: 0.25, hfp: 1.25, rhofP: 0.38 },
      B: { alphaP: 20, hap: 1, cp: 0.25, hfp: 1.25, rhofP: 0.3 },
      C: { alphaP: 20, hap: 1, cp: 0.25, hfp: 1.25, rhofP: 0.25 },
      D: { alphaP: 20, hap: 1, cp: 0.4, hfp: 1.4, rhofP: 0.39 },
    };

    var pressureAngleRad = (pressureAngle * Math.PI) / 180;

    //if (isNaN(measurement)) { measurement = 0;}

    var unitWord = "mm";

    let testPinDiaInput = testPinDia;
    let measurementInput = measurement;

    if (radioValue === "in") {
      // Convert units to mm from inch, if inch is selected
      inputModule = 25.4 / inputModule;
      testPinDia = testPinDia * 25.4;
      measurement = measurement * 25.4;
      unitWord = "in";
    }

    // BEGIN CALCULATIONS

    let toothPitch = Math.PI * inputModule;
    let addendum = rackFormData[rackform].hap * inputModule;
    let dedendum = addendum;
    let clearance = rackFormData[rackform].cp * inputModule;
    let rootFillet = rackFormData[rackform].rhofP * inputModule;

    // AGMA 2002-D19
    // Maximum Ball size
    let y_La = addendum * 0.95; // ASSUME small rack tip break
    let Xe_max = 0; // set profile shift to 0
    let s_nfmax =
      inputModule * (2 * Xe_max * Math.tan(pressureAngleRad) + Math.PI / 2); // Based off nominal circular tooth thickness, with profile shift of 0
    let D_dmax =
      (Math.PI * inputModule -
        s_nfmax +
        2 * y_La * Math.tan(pressureAngleRad)) /
      Math.cos(pressureAngleRad);
    let y_fmin; // minimum distance between pitch line and rack root
    y_fmin = dedendum * rootFillet; // ASSUME minimum distance is dedendum + rootfillet
    let y_amax; // maximum distance between pitch line and rack tip plane
    y_amax = addendum; // ASSUME max distance from pitch line to rack tip plane

    // Get Minimum Ball size
    let y_Lf = dedendum * 0.95; // ASSUME small root form limit
    let Xe_min = 0; // set profile shift to 0
    let s_nfmin =
      inputModule * (2 * Xe_min * Math.tan(pressureAngleRad) + Math.PI / 2); // Based off nominal circular tooth thickness, with profile shift of 0

    // D_dLf contacts rack root limit
    let D_dLf =
      (Math.PI * inputModule -
        s_nfmin -
        2 * y_Lf * Math.tan(pressureAngleRad)) /
      Math.cos(pressureAngleRad);

    // D_df is tangent to rack root
    let D_df =
      ((Math.PI * inputModule -
        s_nfmin -
        2 * y_fmin * Math.tan(pressureAngleRad)) *
        Math.cos(pressureAngleRad)) /
      (1 - Math.sin(pressureAngleRad));

    //D_da is tangent to rack tip plane
    let D_da =
      ((Math.PI * inputModule -
        s_nfmin +
        2 * y_amax * Math.tan(pressureAngleRad)) *
        Math.cos(pressureAngleRad)) /
      (1 + Math.sin(pressureAngleRad));

    let D_dmin = Math.max(D_dLf, D_df, D_da);

    let okBalls = true;
    if (D_dmax < D_dmin) {
      okBalls = false;
    }

    // Calculate Min/Max Measurement limits
    let H_k1max; // measurement limit over one ball to rack datum, max (mm)
    // let H_k1min; // measurement limit over one ball to rack datum, min (mm)
    let y_D; // distance from the pitch line to rack datum, (mm)
    y_D = measurement;
    let D_d = testPinDia;
    if (okBalls) {
      H_k1max =
        y_D +
        (s_nfmax - Math.PI * inputModule) / (2 * Math.tan(pressureAngleRad)) +
        (D_d / 2) * (1 + 1 / Math.sin(pressureAngleRad));
      // H_k1min = y_D + (s_nfmin-Math.PI*inputModule)/(2*Math.tan(pressureAngleRad)) + (D_d/2)*(1+(1/Math.sin(pressureAngleRad)));
    } else {
      H_k1max = "Measurement over pins is not valid for this rack";
      // H_k1min = "Measurement over pins is not valid for this rack";
    }

    // Change outputs back to inches (if inputs are inches)
    if (radioValue === "in") {
      inputModule = 25.4 / inputModule;
      //addendum    = addendum / 25.4;
      //dedendum    = dedendum / 25.4;
      //clearance   = clearance / 25.4;
      //rootFillet  = rootFillet / 25.4;
      //toothPitch  = toothPitch / 25.4;

      //testPinDia  = testPinDia / 25.4;
      measurement = measurement / 25.4;
      //D_dmax      = D_dmax / 25.4;
      //D_dmin      = D_dmin / 25.4;
      //H_k1max     = H_k1max / 25.4;
      //H_k1min     = H_k1min / 25.4;
    }

    let textToWrite = `Inputs
${modWord}:\t${inputModule}
Pressure Angle:\t${pressureAngle}\tdegrees
Rack Form:\t${rackform}
Test Pin Diameter:\t${
      testPinDia ? testPinDiaInput + "\t" + unitWord : "Unspecified"
    }
Datum Length:\t${
      measurement ? measurementInput + "\t" + unitWord : "Unspecified"
    }

Outputs
Tooth Pitch:\t${toothPitch.toFixed(precision)}\tmm\t${(
      toothPitch / 25.4
    ).toFixed(precision)}\tin
Addendum:\t${addendum.toFixed(precision)}\tmm\t${(addendum / 25.4).toFixed(
      precision
    )}\tin
Form Dedendum:\t${dedendum.toFixed(precision)}\tmm\t${(dedendum / 25.4).toFixed(
      precision
    )}\tin
Dedendum:\t${(dedendum + clearance).toFixed(precision)}\tmm\t${(
      (dedendum + clearance) /
      25.4
    ).toFixed(precision)}\tin
Clearance:\t${clearance.toFixed(precision)}\tmm\t${(clearance / 25.4).toFixed(
      precision
    )}\tin
Working Depth:\t${(addendum + dedendum).toFixed(precision)}\tmm\t${(
      (addendum + dedendum) /
      25.4
    ).toFixed(precision)}\tin
Whole Depth:\t${(addendum + dedendum + clearance).toFixed(precision)}\tmm\t${(
      (addendum + dedendum + clearance) /
      25.4
    ).toFixed(precision)}\tin
Root Fillet Radius:\t${rootFillet.toFixed(precision)}\tmm\t${(
      rootFillet / 25.4
    ).toFixed(precision)}\tin

Max Ball Diameter:\t${D_dmax.toFixed(precision)}\tmm\t${(D_dmax / 25.4).toFixed(
      precision
    )}\tin
Min Ball Diameter:\t${D_dmin.toFixed(precision)}\tmm\t${(D_dmin / 25.4).toFixed(
      precision
    )}\tin
Actual Test Pin:\t${
      testPinDia
        ? testPinDia.toFixed(precision) +
          "\tmm\t" +
          (testPinDia / 25.4).toFixed(precision) +
          "\tin"
        : "Unspecified"
    }
Measurement over Ball:\t${
      H_k1max
        ? H_k1max.toFixed(precision) +
          "\tmm\t" +
          (H_k1max / 25.4).toFixed(precision) +
          "\tin"
        : "Unspecified"
    }

\u00A9 ${new Date().getFullYear()} Evolvent Design,\t${formatDate(new Date())}
GCS0`;

    setTextAreaText(textToWrite);

    resizeTextAnswer(textToWrite);
  }
  return (
    <>
      <Card className="project-card">
        <a
          id="calc"
          ref={scrollanchor}
          style={{ scrollMarginTop: 100 + "px" }}
        />
        <div className="project-name">Gear Rack Inspection</div>
        <p>
          Calculate the key dimensions and Measurement over Pins inspection for
          a gear rack.
        </p>
        <p>
          Once you select a pin size, add the distance from the pitch line to
          the back of the rack (datum) to calculate the appropriate Measurement
          over Pin value.
        </p>
        <p>
          The general form of the rack is the same for Forms A-D, with Form C
          being the most common. The only difference between the Forms are the
          clearance depth and root fillet.
        </p>
      </Card>

      <Card className="project-card">
        <Row>
          <Col xs={12} sm={12} md={12} lg={7} xl={7}>
            <form className="calculator">
              <UnitsRadio />

              <DPModuleInput />

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-pange">
                  Pressure Angle (degrees)
                  <span className="required">*</span>
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Common values: <br />
                      20, 14.5, 25
                    </span>
                  </div>
                </label>
                <input className="inputbox" type="number" id="input-pangle" />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="rackform">
                  ISO Rack Form
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Type C for most applications
                    </span>
                  </div>
                </label>
                <select className="inputbox" name="rackform" id="rackform">
                  <option value="C">C</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                </select>
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-testpindia">
                  Test Pin Diameter
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      mm for Module, <br />
                      inch for DP
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="number"
                  id="input-testpindia"
                />
              </div>

              <div style={{ marginBottom: 10 + "px" }}>
                <label htmlFor="input-back">
                  Pitchline to Datum
                  <div className="calctooltip">
                    [?]
                    <span className="tooltiptext">
                      Distance from Back of Rack to Pitch Line
                    </span>
                  </div>
                </label>
                <input
                  className="inputbox"
                  type="text"
                  id="input-back"
                  placeholder="Distance to Pitch Line"
                />
              </div>

              <DecimalPlacesSelect defaultValue={4} />

              <ButtonBlockTextArea
                calculate={calculate}
                clearInputs={clearInputs}
                textAreaText={textAreaText}
                textAreaOnChange={setTextAreaText}
              />
            </form>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5}>
            <Image src="/calc-images/rack-1.png" fluid />

            <Image src="/calc-images/rack-2.png" fluid />
          </Col>
        </Row>
      </Card>
      <Card className="project-card">
        <div className="project-name gray">Additional Notes</div>
        <p>
          Results conform to{" "}
          <i>ISO 53:1998 Standard basic rack tooth profile</i> for rack profile
          and{" "}
          <i>
            ANSI/AGMA 2002-D19 Tooth Thickness and Backlash Measurement of
            Cylindrical Involute Gearing
          </i>{" "}
          for measurements over pins. <i>DIN 867 Basic rack tooth profiles</i>{" "}
          concurs with ISO 53 allows for a broader range of clearance and root
          fillets.
        </p>
        <p>
          ISO 53 is a guiding document for standard rack gear forms. The
          differences between Form A and Form D are relatively minor, and relate
          mostly to the clearance and root fillet radius beneath the working
          depth of the rack. In general, Form A is recommended for transmitting
          high torques while Forms B and C are for general purpose, or normal
          service, applications. Form D has a full radius form for the root
          fillet and is recommended for high-precision gears transmitting high
          torques
        </p>

        <Image
          src="/calc-images/rack-3.png"
          fluid
          style={{ maxWidth: "700px" }}
        />
      </Card>
    </>
  );
}
