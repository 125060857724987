import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Card } from "react-bootstrap";

export default function CancelPage({ location }) {
  const [cancelled, setCancelled] = useState(false);
  let navigate = useNavigate();
  let { state } = useLocation();

  var tempUserInfo;
  try {
    // Check if there is an existing record, and if so, pull in its data
    tempUserInfo = localStorage.getItem("currentUser");
    tempUserInfo = JSON.parse(tempUserInfo);
  } catch {
    tempUserInfo = {};
  }

  const handleBackClick = (e) => {
    e.preventDefault();

    setTimeout(function () {
      navigate("/account");
    }, 100);
  };

  const handleClick = async (e) => {
    e.preventDefault();

    const response = await fetch("/cancel-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscriptionId: state.subscription,
        accesstoken: state.userinfo.accessToken,
        refreshtoken: state.userinfo.refreshToken,
      }),
    });
    const responseBody = await response.json();
    // Handle the response

    tempUserInfo["idToken"] = responseBody.newtoken.IdToken;
    tempUserInfo["accessToken"] = responseBody.newtoken.AccessToken;
    localStorage.setItem("currentUser", JSON.stringify(tempUserInfo));
    setCancelled(true);
  };

  if (cancelled) {
    setTimeout(function () {
      navigate("/account");
    }, 100);
  }

  return (
    <Card className="project-card">
      <div className="project-name">Cancel Subscription</div>
      <div>
        <button onClick={handleClick}>Confirm Cancellation</button>
        <br />
        <button onClick={handleBackClick}>Back</button>
      </div>
    </Card>
  );
}
